define('storeplay-admin/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
		currentUser: Ember.inject.service(),
		moment: Ember.inject.service(),
		session: Ember.inject.service(),

		init: function init() {
			this._super.apply(this, arguments);
		},
		beforeModel: function beforeModel() {
			this.get('moment').setTimeZone('Australia/Melbourne');
		},
		sessionAuthenticated: function sessionAuthenticated() {
			var _this = this;

			Ember.Logger.log('route/application.sessionAuthenticated - start');
			this.get('currentUser').load().then(function () {
				if (_this.get('currentUser.isAdminSuperUser')) {
					Ember.Logger.log('route/application.sessionAuthenticated - current user is super admin... checking for old client ID in token');
					if (_this.get('currentUser.clientID') > 0) {
						Ember.Logger.log('route/application.sessionAuthenticated - there\'s an old client ID in the token, going straight to dashboard');
						_this.transitionTo("authenticated.dashboard");
					} else if (_this.get('currentUser.continueAsAdmin') == true) {
						Ember.Logger.log('route/application.sessionAuthenticated - there\'s an old \'continueAsAdmin\', going straight to dashboard');
						_this.transitionTo("authenticated.dashboard");
					} else {
						Ember.Logger.log('route/application.sessionAuthenticated - no old client ID in the token, going to admin');
						_this.transitionTo("admin");
					}
				} else {
					Ember.Logger.log('route/application.sessionAuthenticated - current user is not super admin, transition to dashboard');
					_this.transitionTo("authenticated.dashboard");
				}
			}).catch(function () {
				Ember.Logger.log('route/application.sessionAuthenticated - could not load user, something\'s wrong. Just invalidate the session.');
				_this.get('session').invalidate();
			});
		},


		// This can be used to pass route transitions to components in the templates.
		// e.g., {{my-component submit=(route-action 'toRoute' 'my.awesome.route')}}
		// This handlebars helper is provided by the `ember-route-action-helper` plugin
		toRoute: function toRoute() {
			var _transitionTo;

			(_transitionTo = this.transitionTo).call.apply(_transitionTo, [this].concat(Array.prototype.slice.call(arguments)));
			return true;
		}
	});
});