define('storeplay-admin/initializers/application', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    if (_environment.default.environment != "development") {
      Ember.Logger.log = function () {};
      Ember.Logger.warn = function () {};
    }
  }

  exports.default = {
    initialize: initialize
  };
});