define("storeplay-admin/helpers/tables/sort-width", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sortWidth = sortWidth;
  function sortWidth(params) {
    var width = params[0];

    if (width) {
      return Ember.String.htmlSafe("width: " + width);
    } else {
      return "";
    }
  }

  exports.default = Ember.Helper.helper(sortWidth);
});