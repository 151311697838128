define('storeplay-admin/controllers/authenticated/clients/new-chain', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		currentUser: Ember.inject.service('current-user'),
		apiClient: Ember.inject.service("api-client"),
		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		actions: {
			addClient: function addClient(newClient, newClientUser) {
				var _this = this;

				var errors = this.performValidation(newClient, newClientUser);

				if (errors.length != 0) {
					var errorMessage = errors.join("<br />");
					this.get('applicationController').displayModal({ title: "Validation Error", message: errorMessage });
				} else {
					// There were no errors. We can add the new client.
					// Save to server
					var apiClient = this.get('apiClient');
					var currentUser = this.get('currentUser');

					apiClient.rpc('createChainClient', { client: newClient, clientUser: newClientUser }, currentUser.adminToken).then(function (response) {
						console.log(response);
						_this.get('applicationController').displayModal({
							title: 'Client Added',
							message: "The new chain client has been added.<br />You can now impersonate them using the menu in the top right corner to continue configuring the client's settings."
						});
					}).catch(function (errorObject) {
						console.log('errorObject : %o', errorObject);
						_this.get('applicationController').displayModal({
							title: 'Sorry, we could not save the new client.',
							message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
						});
					});
				}
			}
		},

		performValidation: function performValidation(newClient, newClientUser) {
			var errors = [];

			// Make sure the fields aren't empty.
			var isEmptyChecks = {
				"internalName": { value: newClient.internalName, message: "Your company name cannot be empty." },

				"givenName": { value: newClientUser.givenName, message: "Your given name cannot be empty." },
				"familyName": { value: newClientUser.familyName, message: "Your family name cannot be empty." },
				"emailAddress": { value: newClientUser.emailAddress, message: "Your email address cannot be empty" },
				"phone": { value: newClientUser.phone, message: "Your phone number cannot be empty." },
				"loginUsername": { value: newClientUser.username, message: "Your login username cannot be empty." },
				"loginPassword": { value: newClientUser.password, message: "Your login password cannot be empty." }
			};

			for (var check in isEmptyChecks) {
				if (this.isEmpty(isEmptyChecks[check].value)) {
					errors.push(isEmptyChecks[check].message);
				}
			}

			// If they have specified a password, make sure it is at least 8 characters long.
			// Anything less than 8 characters is not safe at all.
			if (newClientUser.password && newClientUser.password.length < 8) {
				errors.push("Your login password must be at least 8 characters long.");
			}

			return errors;
		},

		isEmpty: function isEmpty(value) {
			return value == null || value == "";
		}
	});
});