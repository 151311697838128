define('storeplay-admin/routes/authenticated/stores-devices/new-device', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		actions: {
			back: function back() {
				this.transitionTo('authenticated.stores-devices.index');
			}
		}
	});
});