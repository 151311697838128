define('storeplay-admin/controllers/authenticated/subscription/index', ['exports', 'storeplay-admin/models/client'], function (exports, _client) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		apiClient: Ember.inject.service(),
		currentUser: Ember.inject.service(),
		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		maximumLicensesOptions: [], // The options used for the maximum licenses dropdown.

		init: function init() {
			this._super.apply(this, arguments);
		},


		actions: {
			maximumLicensesChanged: function maximumLicensesChanged() {
				console.log("User changed max license.");
			},
			saveMaximumLicenses: function saveMaximumLicenses(model) {
				var newMaximumLicenseCount = $('#maximumLicensesInput').val();
				var currentMaximumLicenseCount = model.maximumLicenseCount;
				var currentlyClaimedLicenseCount = model.claimedLicenseCount;
				console.log("User clicked save for maximum licenses: " + newMaximumLicenseCount);

				this.validateMaximumLicenses(currentMaximumLicenseCount, newMaximumLicenseCount, currentlyClaimedLicenseCount);
			},
			existingPlanClicked: function existingPlanClicked() {
				this.get('applicationController').displayModal({
					title: 'You\'re already on this plan'
				});
			},
			choosePlanClicked: function choosePlanClicked(planID) {
				console.log('subscription.controller - choosePlanClicked, planID : %o', planID);
				var apiClient = this.get('apiClient');
				var applicationController = this.get('applicationController');
				var clientID = this.get('currentUser').get('clientID');
				var _this = this;

				apiClient.rpc('calculateSubscriptionPlanChangeCost', { clientID: clientID, newSubscriptionPlanID: planID }).then(function (response) {
					var message = response.minorUnitsAmount == 0 ? 'There\'s no charge to change to this plan.' : 'There will be an immediate charge of ' + response.displayValue + ' to change to this plan.';
					applicationController.displayModal({
						title: 'Are you sure?',
						message: message,
						primaryButtonLabel: 'Yes, I\'m sure',
						primaryButtonFunction: function primaryButtonFunction() {
							apiClient.rpc('changeSubscriptionPlan', { clientID: clientID, newSubscriptionPlanID: planID }).then(function (response) {
								applicationController.displayModal({
									title: 'Plan Changed',
									message: 'Done! You are now on the new plan.',
									primaryButtonFunction: function primaryButtonFunction() {
										_this.refreshCurrentRoute();
									}
								});
							}).catch(function (errorObject) {
								applicationController.displayModal({
									title: 'Sorry, we could not change plans',
									message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
								});
							});
						},
						secondaryButtonLabel: 'Cancel'
					});
				}).catch(function (error) {
					applicationController.displayModal({
						title: 'Sorry, you can\'t change to this plan',
						message: 'Check that you\'re not subscribed to more channels than are available on the selected plan.'
					});
				});
			},
			cancelSubscriptionClicked: function cancelSubscriptionClicked() {
				var _this2 = this;

				var _this = this;
				var applicationController = this.get('applicationController');
				applicationController.displayModal({
					title: 'Cancel Subscription',
					message: 'Are you sure you want to cancel your storePlay subscription?',
					primaryButtonLabel: 'Yes, I\'m sure',
					primaryButtonFunction: function primaryButtonFunction() {
						var apiClient = _this2.get('apiClient');
						var clientID = _this2.get('currentUser.clientID');
						apiClient.rpc('cancelSubscription', { clientID: clientID }).then(function (response) {
							// Once the subscription has been cancelled, need to go to the exit-survey route.
							_this.transitionToRoute('authenticated.subscription.exit-survey');
						}).catch(function (errorObject) {
							applicationController.displayModal({
								title: 'Sorry, the attempt to cancel your subscription failed.',
								message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
							});
						});
					},
					secondaryButtonLabel: 'Not yet'
				});
			}
		},

		// Changing Maximum Licenses Methods
		// #################################

		validateMaximumLicenses: function validateMaximumLicenses(currentMaximumLicenseCount, newMaximumLicenseCount, currentlyClaimedLicenseCount) {

			var client = this.get('currentUser').get('client');

			// Can't be empty.
			if (newMaximumLicenseCount == "" || newMaximumLicenseCount == null) {
				this.presentValidationAlert("Unable to Change Number of Licenses", 'You have not entered anything into the maximum licenses input field. Please fix this and try again.');
				return;
			}

			// The input from the user must be a number.
			if (isNaN(Number(newMaximumLicenseCount))) {
				this.presentValidationAlert("Unable to Change Number of Licenses", 'You have entered ' + newMaximumLicenseCount + ' which is not a valid number. Please fix this and try again.');
				return;
			}

			// At this point we have a number, ensure that we discard any fractional component.
			newMaximumLicenseCount = Math.floor(newMaximumLicenseCount);

			// Cannot have zero licenses.
			if (newMaximumLicenseCount <= 0) {
				this.presentValidationAlert("Unable to Change Number of Licenses", 'The number of licenses must be greater than zero. Please fix this and try again.');
				return;
			}

			// A user cannot drop their maximum below the number of current licenses are they are currently using.
			if (newMaximumLicenseCount < currentMaximumLicenseCount && newMaximumLicenseCount < currentlyClaimedLicenseCount) {
				this.presentValidationAlert("Unable to Change Number of Licenses", 'You\'re currently using ' + currentlyClaimedLicenseCount + ' licenses, so you cannot go down to ' + newMaximumLicenseCount + '. Delete licenses in the \'Stores & Devices\' section first.');
				return;
			}

			// User is lowering the maximum, but is still above their currently claimed license count so it can go through.
			if (newMaximumLicenseCount < currentMaximumLicenseCount && newMaximumLicenseCount >= currentlyClaimedLicenseCount) {
				this.changeMaximumLicenses(Math.floor(newMaximumLicenseCount), true);
				return;
			}

			// User wants to increase the maximum. Will require payment.
			if (newMaximumLicenseCount > currentMaximumLicenseCount) {

				// If the client is a chain, they pay nothing for switching licenses. Don't bother with 
				if (client.type == _client.ClientType.chain) {
					this.changeMaximumLicenses(newMaximumLicenseCount, true);
					return;
				}

				// Otherwise, show them how much it is going to cost to change licenses and get their confirmation.
				this.presentCostToChangeAlert(currentMaximumLicenseCount, newMaximumLicenseCount);
				return;
			}

			// User has attempted to change to their current maximum license amount.
			if (newMaximumLicenseCount == currentMaximumLicenseCount) {
				this.presentValidationAlert("No Changes", 'You have selected the same number of licenses. Nothing has changed.');
				return;
			}
		},

		changeMaximumLicenses: function changeMaximumLicenses(newMaximumLicenseCount, showConfirmation) {
			var _this = this;
			var applicationController = this.get('applicationController');

			if (showConfirmation) {
				applicationController.displayModal({
					title: "License Change Confirmation",
					message: 'You are about to change the number of maximum licenses to ' + newMaximumLicenseCount + ', are you sure you want to continue?',
					primaryButtonLabel: "Continue",
					secondaryButtonLabel: "Cancel",
					primaryButtonFunction: function primaryButtonFunction() {
						setTimeout(function () {
							_this.performChangeMaximumLicenses(newMaximumLicenseCount);
						}, 0);
					}
				});
			} else {
				_this.performChangeMaximumLicenses(newMaximumLicenseCount);
			}
		},

		performChangeMaximumLicenses: function performChangeMaximumLicenses(newMaximumLicenseCount) {
			var _this = this;
			var apiClient = this.get('apiClient');
			var client = this.get('currentUser');
			var applicationController = this.get('applicationController');

			applicationController.displayModal({
				title: 'Updating Licenses...',
				message: 'We are updating the your maximum number of licenses. This should only take a moment...'
			});

			apiClient.rpc('changeMaximumLicenses', { clientID: client.clientID, newMaximumLicenseCount: newMaximumLicenseCount }).then(function (response) {
				applicationController.displayModal({
					title: 'Licenses Changed!',
					message: 'Done! Your maximum licenses has been updated successfully!',
					primaryButtonFunction: function primaryButtonFunction() {
						_this.refreshCurrentRoute();
					}
				});
			}).catch(function (errorObject) {
				applicationController.displayModal({
					title: 'Sorry, we could not update the number of licenses.',
					message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
				});
			});
		},

		presentValidationAlert: function presentValidationAlert(title, message) {
			this.get('applicationController').displayModal({
				title: title,
				message: message,
				primaryButtonLabel: 'OK'
			});
		},

		presentCostToChangeAlert: function presentCostToChangeAlert(currentMaximumLicenseCount, newMaximumLicenseCount) {
			var _this = this;
			var apiClient = this.get('apiClient');
			var client = this.get('currentUser');
			var applicationController = this.get('applicationController');

			applicationController.displayModal({
				title: 'Calculating Cost to Change Licenses',
				message: 'We are currently calculating the costs to change licenses. This should only take a moment...'
			});

			apiClient.rpc('calculateMaximumLicensesChangeCost', { clientID: client.clientID, newMaximumLicenseCount: newMaximumLicenseCount }).then(function (response) {

				applicationController.displayModal({
					title: 'Cost to Change Licenses',
					message: 'It will cost ' + response.total.displayValue + ' to change from your current maximum of ' + currentMaximumLicenseCount + ' licenses to a new maximum of ' + newMaximumLicenseCount + ' licenses. This is your monthly fee per license x ' + (newMaximumLicenseCount - currentMaximumLicenseCount) + ' new license' + (newMaximumLicenseCount - currentMaximumLicenseCount > 1 ? "s" : "") + '.<br /><br />You will be charged immediately. Do you wish to continue?',
					primaryButtonLabel: 'Continue',
					secondaryButtonLabel: 'Cancel',
					primaryButtonFunction: function primaryButtonFunction() {
						setTimeout(function () {
							_this.changeMaximumLicenses(newMaximumLicenseCount, false);
						}, 0);
					}
				});
			});
		}
	});
});