define('storeplay-admin/models/player-configuration', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var PlayerConfiguration = Ember.Object.extend({

		clientID: null,
		maximumUserCredentialsCacheDays: null,
		createPlaylistsEnabled: null,
		importLibrarySongsEnabled: null,
		importLibraryPlaylistsEnabled: null,
		songFavouritingEnabled: null,
		songBlockingEnabled: null,
		editAccountEnabled: null,
		signOutEnabled: null,
		playbackRepeatEnabled: null,
		cellularDownloadsEnabled: null,
		volumeLevellingEnabled: null,
		locationLockoutEnabled: null,
		advertisementFrequency: null,

		toJSON: function toJSON() {
			var json = {};

			json["clientID"] = this.get("clientID");
			json["maximumUserCredentialsCacheDays"] = this.get("maximumUserCredentialsCacheDays");
			json["createPlaylistsEnabled"] = this.get("createPlaylistsEnabled");
			json["importLibrarySongsEnabled"] = this.get("importLibrarySongsEnabled");
			json["importLibraryPlaylistsEnabled"] = this.get("importLibraryPlaylistsEnabled");
			json["songFavouritingEnabled"] = this.get("songFavouritingEnabled");
			json["songBlockingEnabled"] = this.get("songBlockingEnabled");
			json["editAccountEnabled"] = this.get("editAccountEnabled");
			json["signOutEnabled"] = this.get("signOutEnabled");
			json["playbackRepeatEnabled"] = this.get("playbackRepeatEnabled");
			json["cellularDownloadsEnabled"] = this.get("cellularDownloadsEnabled");
			json["volumeLevellingEnabled"] = this.get("volumeLevellingEnabled");
			json["locationLockoutEnabled"] = this.get("locationLockoutEnabled");
			json["advertisementFrequency"] = this.get("advertisementFrequency");

			return json;
		}
	});

	PlayerConfiguration.reopenClass({
		fromJSON: function fromJSON(playerConfigurationJSONObject) {
			var playerConfiguration = this.create({
				clientID: playerConfigurationJSONObject["clientID"],
				maximumUserCredentialsCacheDays: playerConfigurationJSONObject["maximumUserCredentialsCacheDays"],
				createPlaylistsEnabled: playerConfigurationJSONObject["createPlaylistsEnabled"],
				importLibrarySongsEnabled: playerConfigurationJSONObject["importLibrarySongsEnabled"],
				importLibraryPlaylistsEnabled: playerConfigurationJSONObject["importLibraryPlaylistsEnabled"],
				songFavouritingEnabled: playerConfigurationJSONObject["songFavouritingEnabled"],
				songBlockingEnabled: playerConfigurationJSONObject["songBlockingEnabled"],
				editAccountEnabled: playerConfigurationJSONObject["editAccountEnabled"],
				signOutEnabled: playerConfigurationJSONObject["signOutEnabled"],
				playbackRepeatEnabled: playerConfigurationJSONObject["playbackRepeatEnabled"],
				cellularDownloadsEnabled: playerConfigurationJSONObject["cellularDownloadsEnabled"],
				volumeLevellingEnabled: playerConfigurationJSONObject["volumeLevellingEnabled"],
				locationLockoutEnabled: playerConfigurationJSONObject["locationLockoutEnabled"],
				advertisementFrequency: playerConfigurationJSONObject["advertisementFrequency"]
			});
			return playerConfiguration;
		}
	});

	exports.default = PlayerConfiguration;
});