define('storeplay-admin/models/advertisement-listing', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var AdvertisementListing = Ember.Object.extend({
		id: null,
		title: null,
		duration: null, // In seconds
		clientName: null,
		clientID: null,
		dateStart: null,
		dateEnd: null,
		extension: null,
		isChecked: false, // to use for multiple deletes/edit functonality.
		dateFormatted: Ember.computed('dateStart', 'dateEnd', function () {
			var dateStart = this.get('dateStart');
			var dateEnd = this.get('dateEnd');
			var dates = "Any";
			if (dateStart != null && dateEnd != null) {
				dates = dateStart.format('D MMM YYYY') + " to " + dateEnd.format('D MMM YYYY');
			} else if (dateStart != null) {
				dates = "From " + dateStart.format('D MMM YYYY');
			} else if (dateEnd != null) {
				dates = "To " + dateEnd.format('D MMM YYYY');
			}
			return dates;
		})
	});

	AdvertisementListing.reopenClass({
		// Static Methods
	});

	exports.default = AdvertisementListing;
});