define('storeplay-admin/components/forms/credit-card-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('apiClient'),
		currentUser: Ember.inject.service('currentUser'),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		monthOptions: null,
		yearOptions: null,

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Months
			var months = [{ value: 1, label: "01" }, { value: 2, label: "02" }, { value: 3, label: "03" }, { value: 4, label: "04" }, { value: 5, label: "05" }, { value: 6, label: "06" }, { value: 7, label: "07" }, { value: 8, label: "08" }, { value: 9, label: "09" }, { value: 10, label: "10" }, { value: 11, label: "11" }, { value: 12, label: "12" }];
			this.set('monthOptions', months);

			// Years
			var thisYear = new Date().getFullYear();
			var years = [];
			for (var i = 0; i < 12; i++) {
				var y = thisYear + i;
				years.push({ value: y % 100, label: y });
			}
			this.set('yearOptions', years);
		},

		actions: {

			// Save
			saveClicked: function saveClicked() {
				var _this = this;

				console.log('save clicked');
				var errors = this.performValidation();
				if (errors.length > 0) {
					var errorMessage = "Sorry, there were some errors on the form.<br /><br />" + errors.join("<br />");
					this.get('applicationController').displayModal({ title: "Validation Error", message: errorMessage });
				} else {
					var clientID = this.get('currentUser').clientID;
					var model = this.get('model');
					model.expiryMonth = parseInt($('#expiry-month-selector').val());
					model.expiryYear = parseInt($('#expiry-year-selector').val());
					this.get('apiClient').rpc('savePaymentMethodForClient', { clientID: clientID, paymentMethod: model }).then(function () {
						_this.get('applicationController').displayModal({ title: "Success!", message: "Credit Card details updated successfully!" });
					}).catch(function (errorObject) {
						console.log('errorObject : %o', errorObject);
						_this.get('applicationController').displayModal({
							title: 'Sorry, we could not save your new credit card.',
							message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
						});
					});
				}
			}
		},

		// TODO: We are going to go through and tidy up all form validation and 
		// decide on a consistent, maintainable way to implement it. For now, we are
		// simply just doing it by hand for the last few forms.
		performValidation: function performValidation() {

			var model = this.get("model");

			var errors = [];

			if (model.name == null || model.name == "") {
				errors.push("You must supply a name on the credit card.");
			}

			if (model.number == null || model.number == "") {
				errors.push("You must supply a credit card number.");
			} else if (model.number.replace(/\s+/g, '').length == 15) {
				errors.push("Sorry, the card number you have entered is invalid. Please try again.");
			}

			if (model.securityCode == null || model.securityCode == "") {
				errors.push("You must supply a security code for the credit card.");
			}

			return errors;
		}
	});
});