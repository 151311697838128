define('storeplay-admin/helpers/format-day-ordinal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.formatDayOrdinal = formatDayOrdinal;
	function formatDayOrdinal(params) {
		var day = params[0];

		switch (day) {
			case 1:
			case 21:
			case 31:
				return day + 'st';
			case 2:
			case 22:
				return day + 'nd';
			case 3:
			case 23:
				return day + 'rd';
		}

		if (day <= 31) {
			return day + 'th';
		}

		return day;
	}

	exports.default = Ember.Helper.helper(formatDayOrdinal);
});