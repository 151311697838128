define("storeplay-admin/components/tables/offer-regions-table", ["exports", "storeplay-admin/components/sortable-table/sortable-table-base"], function (exports, _sortableTableBase) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({
		columns: [{ title: "Name", sorter: "name", width: "300px" }, { title: "Latitude", sorter: "latitude", width: "100px" }, { title: "Longitude", sorter: "longitude", width: "100px" }, { title: "Radius", sorter: "radius", width: "100px" }, { title: "", sorter: null, width: "50px" }],

		sorters: {
			name: function name(left, right) {
				return this.orderOf(left.name.toLowerCase(), right.name.toLowerCase());
			},

			latitude: function latitude(left, right) {
				return this.orderOf(left.latitude, right.latitude);
			},

			longitude: function longitude(left, right) {
				return this.orderOf(left.longitude, right.longitude);
			},

			radius: function radius(left, right) {
				return this.orderOf(left.radius, right.radius);
			}
		}
	});
});