define('storeplay-admin/components/tables/playlists-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base', 'storeplay-admin/config/environment'], function (exports, _sortableTableBase, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		linkToRoute: null,

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);
			this.set("apiBaseURL", _environment.default.apiBaseURL);
		},

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
		},
		didUpdateAttrs: function didUpdateAttrs() {
			this._super.apply(this, arguments);
		},


		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [{ title: "Title", sorter: "title", width: "200px" }, { title: "Client", sorter: "client", width: "100px" }, { title: "Channel", sorter: "channel", width: "150px" }, { title: "Available From", sorter: "availableFrom", width: "100px" }, { title: "Available To", sorter: "availableTo", width: "100px" }, { title: "Processed?", sorter: "processed", width: "100px" }, { title: "Approved?", sorter: "approved", width: "100px" }, { title: "Type", sorter: "type", width: "80px" }],

		sorters: {
			title: function title(left, right) {
				return this.orderOf(left.name.toLowerCase(), right.name.toLowerCase());
			},
			client: function client(left, right) {
				return this.orderOf(left.clientName, right.clientName);
			},
			channel: function channel(left, right) {
				return this.orderOf(left.channelName, right.channelName);
			},
			availableFrom: function availableFrom(left, right) {
				return this.orderOf(left.availableFrom, right.availableFrom);
			},
			availableTo: function availableTo(left, right) {
				return this.orderOf(left.availableTo, right.availableTo);
			},
			processed: function processed(left, right) {
				return this.orderOf(left.processed, right.processed);
			},
			approved: function approved(left, right) {
				return this.orderOf(left.approved, right.approved);
			},
			type: function type(left, right) {
				return this.orderOf(left.type, right.type);
			}
		},

		// playlist-table specific
		// #####################
		actions: {
			filterByClient: function filterByClient(clientID) {
				console.log();
			}
		}
	});
});