define('storeplay-admin/components/storeplay-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            Ember.$('#modal-trigger').trigger('click');
        },

        actions: {
            primaryButtonAction: function primaryButtonAction() {
                var applicationController = Ember.getOwner(this).lookup('controller:application');
                applicationController.primaryButtonAction();
            },
            secondaryButtonAction: function secondaryButtonAction() {
                var applicationController = Ember.getOwner(this).lookup('controller:application');
                applicationController.secondaryButtonAction();
            }
        }
    });
});