define('storeplay-admin/routes/authenticated/chain-channels/index', ['exports', 'storeplay-admin/models/channel', 'storeplay-admin/models/chain-channel'], function (exports, _channel, _chainChannel) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service('currentUser'),
		apiClient: Ember.inject.service('apiClient'),

		beforeModel: function beforeModel() {
			// Have to make sure the current user is loaded before we start
			return this.get('currentUser').load();
		},
		model: function model() {
			var apiClient = this.get('apiClient');
			var user = this.get('currentUser');
			var clientUser = this.get('currentUser').get('clientUser');
			if (user.isAdminSuperUser == false || user.client.type != 1) {
				this.transitionTo('index');
			}

			return Promise.all([apiClient.rpc('getChannelsForChainClient', { clientID: clientUser.clientID, includeRelationships: ['client', 'playlist'] }, user.adminToken), apiClient.rpc('getAvailableChannelsForRegion', { subscriptionRegionID: this.get('currentUser.clientUser.subscriptionRegionID') }, user.adminToken)]).then(function (jsons) {

				var currentChannelsJson = jsons[0];
				var availableChannelsJson = jsons[1];
				return {
					clientID: clientUser.clientID,
					currentChannels: currentChannelsJson.map(function (j) {
						return _chainChannel.default.fromJSON(j);
					}),
					availableChannels: availableChannelsJson.map(function (j) {
						return _channel.default.fromJSON(j);
					})
				};
			});
		},


		actions: {
			refreshRoute: function refreshRoute() {
				console.log('refreshCurrentRoute action');
				this.refresh();
			}
		},

		setupController: function setupController(controller, model) {
			this._super(controller, model);
			var _this = this;
			controller.set('refreshRoute', function () {
				console.log('refreshRoute in setupController...');
				_this.refresh();
			});
		}
	});
});