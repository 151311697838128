define('storeplay-admin/controllers/application', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service(),
		currentUser: Ember.inject.service(),
		apiBaseURL: _environment.default.apiBaseURL,

		randomNumber: Math.random(),

		// This can be set when impersonating a client, so that
		// the user can quickly switch back to the admin and continue
		// from where they left off.
		// For example, the admin can impersonate a client from the clients
		// table, this will be used so the admin can return to the clients table
		// with a single click from the "switch to admin" link in top navigation.
		adminRouteToReturnTo: null,

		currentPath: Ember.computed('', function () {
			return this.currentPath;
		}),

		pageTitle: Ember.computed('currentPath', function () {
			var path = this.get('currentPath');
			var params = this.target.currentURL.slice(-1);
			if (Ember.isEqual(params, '0')) {
				path = path.substring(0, path.indexOf('.')).concat(".new");
			}
			switch (path) {
				case 'authenticated.dashboard':
					return 'Dashboard';

				case 'authenticated.channels.index':
					return 'Manage Channels';

				case 'authenticated.preview-playlist.index':
					return 'Preview Playlist';

				case 'authenticated.account.index':
					return 'Manage Account';

				case 'authenticated.subscription.index':
					return 'Manage Subscription';
				case 'authenticated.subscription.exit-survey':
					return 'Exit Survey';

				case 'authenticated.branding.index':
					return 'Branding';

				case 'authenticated.reports.revenue-summary':
					return 'Revenue Report';
				case 'authenticated.reports.churn':
					return 'Churn Report';
				case 'authenticated.reports.category-subscribers':
					return 'Category Subscribers';

				case 'authenticated.stores-devices.index':
					return 'Stores & Devices';
				case 'authenticated.stores-devices.edit':
					return 'Stores & Devices → Edit';
				case 'authenticated.stores-devices.new':
					return 'Stores & Devices → New';
				case 'authenticated.stores-devices.new-device':
					return 'Stores & Devices → New Device';

				case 'authenticated.store-groups.index':
					return 'Store Groups';

				case 'authenticated.advertisements.index':
					return 'Advertisements';
				case 'authenticated.advertisements.edit':
					return 'Advertisements → Edit';

				case 'authenticated.offers.index':
					return 'Offers';
				case 'authenticated.offers.edit':
					return 'Offers → Edit';
				case 'authenticated.offers.new':
					return 'Offers → New';

				case 'authenticated.ar-experiences.index':
					return 'AR Experiences';
				case 'authenticated.ar-experiences.edit':
					return 'AR Experiences → Edit';
				case 'authenticated.ar-experiences.new':
					return 'AR Experiences → New';

				case 'authenticated.regions.index':
					return 'Regions';
				case 'authenticated.regions.new':
					return 'Region → New';
				case 'authenticated.regions.edit':
					return 'Region → Edit';

				case 'authenticated.admin.client':
					return 'Switch Client';

				case 'authenticated.connect-users.index':
					return 'Connect Users';
				case 'authenticated.connect-users.edit':
					return 'Connect Users';

				case 'authenticated.clients.index':
					return 'Clients';
				case 'authenticated.clients.new-chain':
					return 'New Chain Client';
				case 'authenticated.client-settings.index':
					return 'Client Settings';

				case 'authenticated.resellers.index':
					return 'Resellers';
				case 'authenticated.resellers.edit':
					return 'Resellers → Edit';
			}
		}),

		isReportPath: Ember.computed(function () {
			return this.currentPath.indexOf('reports') >= 0;
		}),

		/**
   * @param {*} modal that you pass in
   * title: string,								// Optional title to display in the alert
   * message: string,								// Optional message to display in the alert
   * primaryButtonLabel: string,					// This is the label to display in the primary button, defaults to 'OK'.
   * primaryButtonFunction: closure/function,		// This is an inline javascript function to execute if the user clicks the primary button, optional.
   * secondaryButtonLabel: string,				// This is the label to display on the secondary button. If not set, the button will not display.
   * secondaryButtonFunction: closure/function, 	// This is an inline javascript function to execute if the user clicks the secondary button, optional.
   */
		shouldDisplayModal: false,
		modalModel: {},
		displayModal: function displayModal(modal) {
			// Set the defaults
			if (!modal.title && !modal.message) {
				modal["title"] = "Alert!";
			}
			if (!modal.primaryButtonLabel) {
				modal["primaryButtonLabel"] = "OK";
			}
			this.set('shouldDisplayModal', true);
			this.set('modalModel', modal);
		},
		primaryButtonAction: function primaryButtonAction() {
			var modal = this.get('modalModel');
			if (modal.primaryButtonFunction) {
				modal.primaryButtonFunction();
			}
			this.set('shouldDisplayModal', false);
		},
		secondaryButtonAction: function secondaryButtonAction() {
			var modal = this.get('modalModel');
			if (modal.secondaryButtonFunction) {
				modal.secondaryButtonFunction();
			}
			this.set('shouldDisplayModal', false);
		},


		actions: {
			willTransition: function willTransition(transition) {
				this.hideMenu();
				return true;
			},
			toggleMenu: function toggleMenu() {
				$("nav").toggleClass("active");
				$("body").toggleClass("no-scroll");
			},
			showMenu: function showMenu() {
				$("nav").addClass("active");
				$("body").addClass("no-scroll");
			},
			hideMenu: function hideMenu() {
				$("nav").removeClass("active");
				$("body").removeClass("no-scroll");
			},
			signOut: function signOut() {
				var _this = this;

				Ember.Logger.log('application.signOut - start, about to actually invalidate session object');
				this.get('session').invalidate().then(function () {
					Ember.Logger.log('application.signOut - session invalidated, about to unloadClient');
					_this.get('currentUser').unloadClient();
					Ember.Logger.log('application.signOut - session invalidated, about to unloadAdmin');
					_this.get('currentUser').unloadAdmin();
				});
			},
			switchClient: function switchClient() {
				this.transitionToRoute('authenticated.admin.client');
			},
			loginAsAdmin: function loginAsAdmin() {
				var shouldTransitionToCustomRoute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

				this.get('currentUser').unloadClient();
				this.get('currentUser').loadAdmin();

				if (shouldTransitionToCustomRoute) {
					var routeToTransitionTo = this.get("adminRouteToReturnTo") || "authenticated.dashboard";
					this.transitionToRoute(routeToTransitionTo);
					this.set("adminRouteToReturnTo", null);
				}
			}
		}
	});
});