define('storeplay-admin/controllers/authenticated/regions/edit', ['exports', 'storeplay-admin/controllers/authenticated'], function (exports, _authenticated) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _authenticated.default.extend({
		apiClient: Ember.inject.service(),
		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),
		actions: {
			cancel: function cancel() {
				this.transitionToRoute('authenticated.regions');
			},
			save: function save(model, shouldStay) {
				var _this2 = this;

				var _this = this;
				var apiClient = this.get('apiClient');
				if (_this.validateModel(model)) {
					var _model = this.get('model');
					apiClient.rpc('saveGeofence', {
						geofence: _model.toJSON()
					}).then(function () {
						toastr.success("Region saved");
						if (shouldStay == false) {
							_this2.transitionToRoute('authenticated.regions');
						}
					});
				}
			},
			delete: function _delete() {
				var _this3 = this;

				var apiClient = this.get('apiClient');
				var model = this.get('model');

				this.get('applicationController').displayModal({
					title: 'Are you sure you want to delete this region?',
					primaryButtonLabel: 'Yes, delete',
					primaryButtonFunction: function primaryButtonFunction() {
						apiClient.rpc('deleteGeofence', {
							geofenceID: model.get('id')
						}).then(function () {
							_this3.transitionToRoute('authenticated.regions');
						});
					},
					secondaryButtonLabel: 'Cancel'
				});
			}
		},

		validateModel: function validateModel(model) {
			var isDirty = model.get('isDirty');
			model.validate();
			var isValid = model.get("isValid");

			if (isValid == false) {
				var errors = '';
				model.get('errors').forEach(function (error) {
					errors += '<h5>' + error.key.capitalize() + '</h5>';
					errors += '<ul>';
					error.validation.forEach(function (validationMessage) {
						errors += '<li>' + validationMessage + '</li>';
					});
					errors += '</ul>';
				});

				this.get('applicationController').displayModal({
					title: 'Please check the information of the store',
					message: Ember.String.htmlSafe(errors)
				});
			} else {
				model.execute();
				return true;
			}
		}
	});
});