define('storeplay-admin/models/store', ['exports', 'ember-resolver', 'storeplay-admin/routes/authenticated/admin/client'], function (exports, _emberResolver, _client) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Object.extend({
		id: null,
		clientID: null,
		client: null,
		publicName: null,
		internalName: null,
		customCode: null,
		latitude: null,
		longitude: null,
		latitudeLongitudeVerified: null,
		iso2CountryCode: null,
		timeZone: null,
		state: null,
		postcode: null,
		suburb: null,
		streetAddress1: null,
		streetAddress2: null,
		jukeboxEnabled: null,
		absoluteDjWebsiteUrl: null,
		headlessAccessCode: null,
		storeSize: null,
		phone: null,
		email: null,
		playbackSchedule: null,
		djWebsiteUrl: null,
		deviceStatus: Ember.computed('playbackSchedule', 'timeZone', function () {
			// Get relative time
			var tz = this.get('timeZone') || 'Australia/Melbourne'; // Olson string provided by API
			var localNow = moment(new Date()).tz(tz);
			//let localNowClamped = localNow.add(-(localNow.minute() % 15), "minutes");
			//let timestamp = localNowClamped.format("HH:mm:00");
			var day = localNow.day();

			// API Week starts on Monday, MomentJS on Sunday, no its fine now!!
			var yesterday = day - 1 > 0 ? day - 1 : 6;

			var todaysSchedule = (this.get('playbackSchedule.items') || []).find(function (s) {
				return s.dayOfWeek == day;
			});
			var yesterdaysSchedule = (this.get('playbackSchedule.items') || []).find(function (s) {
				return s.dayOfWeek == yesterday;
			});

			if (todaysSchedule == null && yesterdaysSchedule == null) return { label: "No schedule", id: -1 };

			var daysAndItemsToCheck = [];
			if (todaysSchedule != null) {
				daysAndItemsToCheck.push({ date: localNow, scheduleItem: todaysSchedule });
			}
			if (yesterdaysSchedule != null) {
				var localYesterday = moment(localNow).add(-1, "days");
				daysAndItemsToCheck.push({ date: localYesterday, scheduleItem: yesterdaysSchedule });
			}

			var status = { label: "Off", id: 2 };
			for (var i = 0; i < daysAndItemsToCheck.length; i++) {
				var scheduleDate = daysAndItemsToCheck[i].date;
				var scheduleItem = daysAndItemsToCheck[i].scheduleItem;

				var from = this.timestampToSeconds(scheduleItem.from);
				var to = this.timestampToSeconds(scheduleItem.to);

				var playbackActiveFrom = moment.tz(scheduleDate.format('YYYY-MM-DD'), tz).add(from, 'seconds');
				var playbackActiveTo = moment.tz(scheduleDate.format('YYYY-MM-DD'), tz).add(to, 'seconds');

				if (to <= from) {
					// Overlaps midnight, so "to" is next day
					playbackActiveTo = moment(playbackActiveTo).add(1, 'days');
				}

				var fifteenMinutesTillOff = moment(playbackActiveTo).add(-15, 'minutes');
				if (localNow.isAfter(fifteenMinutesTillOff) && localNow.isBefore(playbackActiveTo)) {
					console.log('Schedule is active for store ' + this.get('id') + ', as ' + localNow.format() + ' falls within ' + playbackActiveFrom.format() + ' to ' + playbackActiveTo.format() + '.');
					return { label: "About to go offline", id: 1 };
				}

				if (localNow.isAfter(playbackActiveFrom) && localNow.isBefore(playbackActiveTo)) {
					console.log('Schedule is active for store ' + this.get('id') + ', as ' + localNow.format() + ' falls within ' + playbackActiveFrom.format() + ' to ' + playbackActiveTo.format() + '.');
					return { label: "On", id: 0 };
				}
			}

			return status;
		}),

		searchString: Ember.computed('publicName', 'internalName', 'streetAddress1', 'streetAddress2', 'state', 'postcode', 'suburb', function () {
			var pName = (this.get('publicName') || '').toLowerCase();
			var iName = (this.get('internalName') || '').toLowerCase();
			var address1 = (this.get('streetAddress1') || '').toLowerCase();
			var address2 = (this.get('streetAddress2') || '').toLowerCase();
			var state = (this.get('state') || '').toLowerCase();
			var postcode = (this.get('postcode') || '').toLowerCase();
			var suburb = (this.get('suburb') || '').toLowerCase();

			return pName + ',' + iName + ',' + address1 + ',' + address2 + ',' + state + ',' + postcode + ',' + suburb;
		}),

		// Creates a schedule and assigns it to this modal.
		// provide defaults for all the 'from' and 'to' values on schedule items.
		createSchedule: function createSchedule(from, to) {
			var schedule = {
				items: []
			};
			for (var i = 0; i <= 6; i++) {
				schedule.items.push({
					dayOfWeek: i,
					from: from ? from : "00:00:00",
					to: to ? to : "00:00:00"
				});
			}
			this.set('playbackSchedule', schedule);
		},
		matches: function matches(str) {
			return this.get('searchString').indexOf(str) !== -1;
		},
		timestampToSeconds: function timestampToSeconds(ts) {
			var components = ts.split(":");
			return (components[0] || 0) * 3600 + (components[1] || 0) * 60;
		}
	});
});