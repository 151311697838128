define('storeplay-admin/components/tables/stores-devices-report-row', ['exports', 'storeplay-admin/models/store'], function (exports, _store) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),
		geolocation: Ember.inject.service('geolocation'),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		tagName: 'tr',
		item: null,
		linkStoreID: -1,
		linkLicenseID: -1,
		didInsertElement: function didInsertElement() {
			var self = '#' + this.elementId;
			var els = $(self + ' .status-indicator-great,' + self + ' .status-indicator-ok,' + self + ' .status-indicator-neutral,' + self + ' .status-indicator-bad');
			els.popover({
				html: true,
				trigger: 'focus',
				placement: 'right'
			});
		},

		actions: {
			loadAvailableLicenses: function loadAvailableLicenses() {
				var _this = this;

				var storeAndLicense = this.get('item');
				var user = this.get('currentUser');
				if (storeAndLicense.store && storeAndLicense.store.id == 0) {
					return;
				}

				if (!storeAndLicense.license && storeAndLicense.store) {

					this.set('item.linkableLicenses', [{ id: -1, name: "[ Loading... ]" }]);

					var apiClient = this.get('apiClient');

					apiClient.rpc('getAllLicensesWithoutStoreForClient', {
						clientID: storeAndLicense.client.id
					}, user.adminToken).then(function (emptyLicenses) {
						if (emptyLicenses.length == 0) {
							_this.set('item.linkableLicenses', [{ id: -1, name: "[ No unlinked licenses available ]" }]);
						} else {
							var geoLocation = _this.get('geolocation');
							var storeLatitude = storeAndLicense.store.latitude;
							var storeLongitude = storeAndLicense.store.longitude;
							var linkableLicenses = emptyLicenses.map(function (l) {
								return {
									id: l.id,
									name: 'Device ' + l.id + (l.device.name ? " - " + l.device.name : ""),
									distance: geoLocation.getDistance(l.device.latitude, l.device.longitude, storeLatitude, storeLongitude),
									uuid: '- UUID ' + l.device.udid
								};
							});

							linkableLicenses.sort(function (license1, license2) {

								var license1Name = license1.name.split(" - ")[1] || null;
								var license2Name = license2.name.split(" - ")[1] || null;

								// If the distances are null, sort by their names.
								// If they don't have names, they go to the bottom.
								// If the names are the same, they're filtered to the bottom.
								if (license1.distance === null && license2.distance === null) {
									if (license1Name === null) {
										return 1;
									} else if (license2Name === null) {
										return -1;
									} else if (license1Name < license2Name) {
										return -1;
									} else if (license1Name > license2Name) {
										return 1;
									} else {
										// Go based on the uuid.
										if (license1.uuid < license2.uuid) {
											return 1;
										} else if (license1.uuid > license2.uuid) {
											return -1;
										}
									}
								}
								// If the distances aren't null, show the closest at the top.
								else if (license1.distance != null && license2.distance != null) {
										if (license1.distance > license2.distance) {
											return 1;
										} else if (license1.distance < license2.distance) {
											return -1;
										}
									}
									// Put the items with distances on the top.
									// Everything else below.
									else if (license1.distance === null) {
											return 1;
										} else if (license2.distance === null) {
											return -1;
										} else {
											console.log("here");
										}
							});

							var _iteratorNormalCompletion = true;
							var _didIteratorError = false;
							var _iteratorError = undefined;

							try {
								for (var _iterator = linkableLicenses[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
									var licenses = _step.value;

									if (licenses["distance"] == null) {
										licenses["distance"] = "(no location)";
									} else {
										licenses["distance"] = geoLocation.convertDistance(licenses["distance"]);
									}
								}
							} catch (err) {
								_didIteratorError = true;
								_iteratorError = err;
							} finally {
								try {
									if (!_iteratorNormalCompletion && _iterator.return) {
										_iterator.return();
									}
								} finally {
									if (_didIteratorError) {
										throw _iteratorError;
									}
								}
							}

							linkableLicenses.unshift({ id: -1, name: "[ Choose device ]" });
							_this.set('item.linkableLicenses', linkableLicenses);
						}
					});
				}
			},
			loadAvailableStores: function loadAvailableStores() {
				var _this2 = this;

				var storeAndLicense = this.get('item');
				var user = this.get('currentUser');
				if (storeAndLicense.license && !storeAndLicense.store) {

					this.set('item.linkableStores', [{ id: -1, name: "[ Loading... ]" }]);

					var geoLocation = this.get('geolocation');
					var apiClient = this.get('apiClient');

					apiClient.rpc('getAllStoresWithoutLicenseForClient', {
						clientID: storeAndLicense.client.id
					}, user.adminToken).then(function (emptyStores) {
						if (emptyStores.length == 0) {
							_this2.set('item.linkableStores', [{ id: -1, name: "[ No empty stores available ]" }]);
						} else {
							var deviceLatitude = storeAndLicense.license.device.latitude;
							var deviceLongtitude = storeAndLicense.license.device.longitude;
							var linkableStores = emptyStores.map(function (s) {
								var isStoreLocationSet = !(s.latitude == 0 && s.longitude == 0);
								return {
									id: s.id,
									name: s.internalName ? s.internalName : s.publicName,
									distance: isStoreLocationSet ? geoLocation.getDistance(deviceLatitude, deviceLongtitude, s.latitude, s.longitude) : Infinity
								};
							});

							linkableStores.sort(function (store1, store2) {

								// If the distances are null, sort by their names.
								// If they don't have names, they go to the bottom.
								// If the names are the same, they're filtered to the bottom.
								if (store1.distance === null && store2.distance === null) {
									if (store1.name === null) {
										return 1;
									} else if (store2.name === null) {
										return -1;
									} else if (store1.name < store2.name) {
										return -1;
									} else if (store1.name > store2.name) {
										return 1;
									} else {
										// Go based on the id.
										if (store1.id < store2.id) {
											return 1;
										} else if (store1.id > store2.id) {
											return -1;
										}
									}
								}
								// If the distances aren't null, show the closest at the top.
								else if (store1.distance != null && store2.distance != null) {
										if (store1.distance > store2.distance) {
											return 1;
										} else if (store1.distance < store2.distance) {
											return -1;
										}
									}
									// Put the items with distances on the top.
									// Everything else below.
									else if (store1.distance === null) {
											return 1;
										} else if (store2.distance === null) {
											return -1;
										} else {
											console.log("here");
										}
							});

							var _iteratorNormalCompletion2 = true;
							var _didIteratorError2 = false;
							var _iteratorError2 = undefined;

							try {
								for (var _iterator2 = linkableStores[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
									var linkableStore = _step2.value;

									var isStoreLocationSet = linkableStore["distance"] != Infinity;
									linkableStore["distanceString"] = isStoreLocationSet ? geoLocation.convertDistance(linkableStore["distance"]) : "(store location not set)";
								}
							} catch (err) {
								_didIteratorError2 = true;
								_iteratorError2 = err;
							} finally {
								try {
									if (!_iteratorNormalCompletion2 && _iterator2.return) {
										_iterator2.return();
									}
								} finally {
									if (_didIteratorError2) {
										throw _iteratorError2;
									}
								}
							}

							linkableStores.unshift({ id: -1, name: "[ Choose store ]" });
							_this2.set('item.linkableStores', linkableStores);
						}
					});
				}
			},

			// Linking and Unlinking
			setLinkStoreID: function setLinkStoreID(storeID) {
				this.set('linkStoreID', storeID);
			},
			linkStore: function linkStore() {
				var _this3 = this;

				var storeID = this.get('linkStoreID');
				var licenseID = this.item.license.id;
				var user = this.get('currentUser');
				Ember.Logger.log("linkStore: storeID: " + storeID + ", licenseID: " + licenseID);
				if (storeID == -1) {
					this.get('applicationController').displayModal({
						title: 'Cannot link store',
						message: 'Sorry, you must choose an empty store to link to.'
					});
				} else {
					var apiClient = this.get('apiClient');
					apiClient.rpc('linkLicenseToStore', {
						licenseID: licenseID,
						storeID: storeID
					}, user.adminToken).then(function () {
						_this3.set('item.linked', true);
						apiClient.rpc('getStore', {
							storeID: storeID
						}, user.adminToken).then(function (result) {
							_this3.set('item.store', _store.default.create(result));
							_this3.set('linkStoreID', null);
						});
					});
				}
			},
			setLinkLicenseID: function setLinkLicenseID(licenseID) {
				this.set('linkLicenseID', licenseID);
			},
			linkLicense: function linkLicense() {
				var _this4 = this;

				var licenseID = this.get('linkLicenseID');
				var storeID = this.item.store.id;
				var user = this.get('currentUser');
				if (licenseID == -1) {
					this.get('applicationController').displayModal({
						title: 'Cannot link device',
						message: 'Sorry, you must choose an unlinked device to link to.'
					});
				} else {
					var apiClient = this.get('apiClient');
					apiClient.rpc('linkLicenseToStore', {
						licenseID: licenseID,
						storeID: storeID
					}, user.adminToken).then(function () {
						_this4.set('item.linked', true);

						apiClient.rpc('getLicense', {
							licenseID: licenseID
						}, user.adminToken).then(function (result) {
							_this4.set('item.license', result);
							_this4.set('linkLicenseID', null);
						});
					});
				}
			}
		}
	});
});