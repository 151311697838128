define('storeplay-admin/components/tables/connect-users-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base', 'storeplay-admin/config/environment'], function (exports, _sortableTableBase, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		currentUser: Ember.inject.service('current-user'),

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);

			this.set("apiBaseURL", _environment.default.apiBaseURL);
			this.set("allRows", []);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			var allRows = this.get("rows");
			this.set("allRows", allRows);

			this.filterRows();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [{ title: "ID", sorter: "id", width: "80px" }, { title: "Name", sorter: "name", width: "150px" }, { title: "Email", sorter: "email", width: "200px" }, { title: "Username", sorter: "username", width: "200px" }, { title: "User Type", sorter: "typeString", width: "100px" }, { title: "", sorter: null, width: "65px" }],

		sorters: {
			id: function id(left, right) {
				return this.orderOf(left.id, right.id);
			},

			name: function name(left, right) {
				return this.orderOf(left.name.toLowerCase(), right.name.toLowerCase());
			},

			email: function email(left, right) {
				return this.orderOf(left.email.toLowerCase(), right.email.toLowerCase());
			},

			username: function username(left, right) {
				return this.orderOf(left.username.toLowerCase(), right.username.toLowerCase());
			},

			typeString: function typeString(left, right) {
				return this.orderOf(left.type, right.type);
			}
		},

		// Connect-Users Specific
		// ######################

		currentSearchTerm: null,

		actions: {
			onSearchInputChanged: function onSearchInputChanged(event) {
				var searchTerm = event.target.value;
				this.set('currentSearchTerm', searchTerm);
				this.filterRows();
			},

			delete: function _delete(user) {
				// This will call the 'delete' action that is passed to
				// the component from the route's template.
				this.get('delete')(user);
			}
		},

		filterRows: function filterRows() {
			var _this = this;
			var allRows = this.get("allRows");
			var filteredRows = allRows;

			// If we have a search input, filter the rows.
			if (_this.currentSearchTerm) {

				var searchTerm = _this.currentSearchTerm.toLowerCase();

				filteredRows = filteredRows.filter(function (element) {
					var name = element.name ? element.name.toLowerCase() : "";
					var email = element.email ? element.email.toLowerCase() : "";
					return name.includes(searchTerm.toLowerCase()) || email.toLowerCase().includes(searchTerm.toLowerCase());
				});
			}

			this.set("rows", filteredRows);
		}
	});
});