define('storeplay-admin/components/dashboard/admin-welcome-panel', ['exports', 'storeplay-admin/models/client', 'storeplay-admin/config/environment'], function (exports, _client, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),
		utility: Ember.inject.service("utility"),

		numberOfColumns: 6,
		tagName: "", // This ensures the component is not wrapped in an ember-view div, which is not needed for this component.

		isFetching: false,
		fetchingError: null,

		init: function init(args) {
			// The component has been created in memory
			this._super(args);
		},

		didInsertElement: function didInsertElement() {
			// Component was added to the DOM
			this._super.apply(this, arguments);

			this.generateURLForClientLogo();
		},

		willDestroyElement: function willDestroyElement() {
			// Component is about to be removed from the DOM
			this._super.apply(this, arguments);
		},

		actions: {},

		generateURLForClientLogo: function generateURLForClientLogo() {
			// Generate the URL for the user's logo.
			var clientID = this.get("currentUser").clientID;
			var random = Math.random(); // We need a random value on the URL to get around caching.
			var logoLightSrc = _environment.default.apiBaseURL + '/clients/' + clientID + '/logo-light?width=200&height=200&' + random;
			this.set("logoLightSrc", logoLightSrc);
		}

	});
});