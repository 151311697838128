define('storeplay-admin/components/forms/scheduling/global-scheduling', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		globalPlaybackSchedule: null,

		// Inject an everyday schedule
		everydayScheduleOptions: ["Everyday", "Per Day"],
		currentEverydayScheduleOption: "Custom",
		enableEverydayStoreSchedule: false,
		everydaySchedule: {
			items: [{
				from: "00:00:00",
				to: "00:00:00"
			}]
		},

		// Component Lifecycle
		init: function init(args) {
			this._super(args);
			// The component has been created in memory
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			// Component was added to the DOM
			this.initScheduleOptions();
		},

		// This gets called when transitioning to the current route
		// (as we do when linking/unlinking), so in that case, we have to ensure the
		// list of stores/devices have the appropriate dropdown values.
		didUpdateAttrs: function didUpdateAttrs() {
			this.initScheduleOptions();
		},


		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
			// Can do any cleanup here.
		},

		actions: {
			saveSchedule: function saveSchedule() {
				var _this = this;

				var apiClient = this.get('apiClient');
				var user = this.get('currentUser');

				var globalPlaybackSchedule = this.get('globalPlaybackSchedule');
				if (globalPlaybackSchedule != null) {
					// Otherwise check for everyday schedule
					if (this.get('enableEverydayStoreSchedule')) {
						// set all the day to the same
						var everyDaySchedule = this.get('everydaySchedule');
						if (everyDaySchedule != null) globalPlaybackSchedule.createPlaybackSchedule(everyDaySchedule.items[0].from, everyDaySchedule.items[0].to);
					}

					apiClient.rpc('saveScheduleForClient', {
						clientPlaybackSchedule: globalPlaybackSchedule
					}, user.adminToken).then(function (result) {
						toastr.success("Global schedule saved!");
					}).catch(function (errorObject) {
						console.log('errorObject : %o', errorObject);
						_this.get('applicationController').displayModal({
							title: 'Sorry, we could not save the client schedule.',
							message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
						});
					});
				}
			},
			onEverydayScheduleOptionChanged: function onEverydayScheduleOptionChanged(selectedOption) {

				this.set('enableEverydayStoreSchedule', selectedOption == "Everyday" ? true : false);
			}
		},

		initScheduleOptions: function initScheduleOptions() {
			var globalPlaybackSchedule = this.get('globalPlaybackSchedule');
			if (globalPlaybackSchedule != null && globalPlaybackSchedule.playbackSchedule && globalPlaybackSchedule.playbackSchedule.items) {
				// Check to see if an everyday schedule is setup. So check if all items are the same.
				var itemTimes = globalPlaybackSchedule.playbackSchedule.items[0].from + globalPlaybackSchedule.playbackSchedule.items[0].to;
				var allDaysSame = true;
				for (var i = 0; i < globalPlaybackSchedule.playbackSchedule.items.length; i++) {
					var item = globalPlaybackSchedule.playbackSchedule.items[i];
					if (itemTimes !== item.from + item.to) allDaysSame = false;
				}

				if (allDaysSame) this.set('everydaySchedule.items', [globalPlaybackSchedule.playbackSchedule.items[0]]);

				this.set('enableEverydayStoreSchedule', allDaysSame);
				this.set('currentEverydayScheduleOption', allDaysSame ? "Everyday" : "Custom");
			}
		}
	});
});