define('storeplay-admin/models/subscription', ['exports', 'storeplay-admin/models/subscription-plan'], function (exports, _subscriptionPlan) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var subscription = Ember.Object.extend({
		id: null,
		clientID: null,
		renewalDay: null,
		dateStart: null,
		subscriptionPlanID: null,
		subscriptionPlan: null,

		renewalDayWithOrdinal: Ember.computed('renewalDay', function () {
			var r = this.get('renewalDay');
			switch (r) {
				case 1:
				case 21:
				case 31:
					return r + 'st';
				case 2:
				case 22:
					return r + 'nd';
				case 3:
				case 23:
					return r + 'rd';
			}
			if (r <= 31) {
				return r + 'th';
			}
			return r;
		}),

		toJSON: function toJSON() {
			var json = {};
			json["id"] = this.get("id");
			json["clientID"] = this.get("clientID");
			json["renewalDay"] = this.get("renewalDay");
			json["dateStart"] = this.get("dateStart");

			var date = this.get('date');
			if (date) {
				json["dateStart"] = date.format();
			}

			json["subscriptionPlanID"] = this.get("subscriptionPlanID");
			return json;
		}
	});

	subscription.reopenClass({
		fromJSON: function fromJSON(subscriptionObject) {
			console.log('subscription.fromJSON - json : %o', subscriptionObject);
			var subscription = this.create({
				id: subscriptionObject["id"] || null,
				clientID: subscriptionObject["internalName"] || null,
				dateStart: moment.unix(subscriptionObject["dateStart"]["unixTime"]) || null,
				renewalDay: subscriptionObject["renewalDay"] || null,
				subscriptionPlanID: subscriptionObject["subscriptionPlanID"] || null,
				subscriptionPlan: subscriptionObject["subscriptionPlan"] != null ? _subscriptionPlan.default.fromJSON(subscriptionObject["subscriptionPlan"]) : null
			});
			return subscription;
		}
	});

	exports.default = subscription;
});