define('storeplay-admin/models/store-group', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var StoreGroup = Ember.Object.extend({
		id: null,
		name: null,
		clientID: null
	});

	StoreGroup.reopenClass({
		// Static Methods
	});

	exports.default = StoreGroup;
});