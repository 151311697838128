define('storeplay-admin/components/tables/stores-devices-row', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'tr',
		didInsertElement: function didInsertElement() {
			var self = '#' + this.elementId;
			var els = $(self + ' .status-indicator-great,' + self + ' .status-indicator-ok,' + self + ' .status-indicator-neutral,' + self + ' .status-indicator-bad');
			els.popover({
				html: true,
				trigger: 'focus',
				placement: 'right'
			});
		}
	});
});