define('storeplay-admin/components/tables/advertisements-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base', 'storeplay-admin/config/environment'], function (exports, _sortableTableBase, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);

			this.set("allRows", []);
			this.set("apiBaseURL", _environment.default.apiBaseURL);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			this.initCheckboxes();

			//let allRows = this.get("rows");
			//this.set("allRows", allRows);

			//this.set("rows", allRows);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [{ title: "Title", sorter: "title", width: "250px" }, { title: "Client", sorter: "client", width: "150px" }, { title: "Duration (sec)", sorter: "duration", width: "180px" }, { title: "Dates", sorter: "dates", width: "170px" }, { title: "Delete", sorter: null, width: "70px" }],

		sorters: {
			title: function title(left, right) {
				return this.orderOf(left.title, right.title);
			},

			client: function client(left, right) {
				return this.orderOf(left.clientName, right.clientName);
			},

			duration: function duration(left, right) {
				return this.orderOf(left.duration, right.duration);
			},

			dates: function dates(left, right) {
				return this.orderOf(left.dateStart, right.dateStart);
			}
		},

		// Advertiser-table specific
		// #####################

		checkedItems: [],

		initCheckboxes: function initCheckboxes() {
			// Add and observer to the isChecked property of the advertisementListing object
			// this is linked to the checkbox shown in the table.
			// When checked or unchecked it is added or removed from the checkedItems list.
			// This list can be used to perform multiple action on the list of advertisements, such as delete.
			var _that = this;
			var checkedItems = this.get('checkedItems');
			this.get('rows').forEach(function (item) {
				item.addObserver('isChecked', function () {
					if (item.isChecked) {
						checkedItems.addObject(item.id);
					} else {
						checkedItems.removeObject(item.id);
					}
				});
			});
		},

		actions: {
			filterByClient: function filterByClient(clientID) {
				console.log();
			}
		}
	});
});