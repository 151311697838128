define('storeplay-admin/models/channel', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Channel = Ember.Object.extend({
		id: 0,
		name: null,
		adminName: null,
		availableFrom: null,
		availableTo: null,
		description: null,
		royaltyTypeId: null,
		subscriptionRegions: [],
		sortOrder: null,
		spotifyPlaylistUrl: null,
		playlist: null,
		isFeaturedChannel: false,
		imageURL: null,
		toJSON: function toJSON() {
			var json = {};
			json["id"] = this.get("id");
			json["name"] = this.get("name");
			json["adminName"] = this.get("adminName");
			json["description"] = this.get("description");
			json["availableFrom"] = this.get("availableFrom");
			json["availableTo"] = this.get("availableTo");
			json["royaltyType"] = this.get("royaltyTypeId");
			json["subscriptionRegions"] = this.get("subscriptionRegions");
			json["sortOrder"] = this.get("sortOrder");
			json["spotifyPlaylistUrl"] = this.get("spotifyPlaylistUrl");
			json["isFeaturedChannel"] = this.get("isFeaturedChannel");
			json["imageURL"] = this.get("imageURL");
			return json;
		}
	});

	Channel.reopenClass({
		fromJSON: function fromJSON(channelObject) {
			var channel = this.create({
				id: channelObject["id"],
				name: channelObject["name"],
				adminName: channelObject["adminName"],
				description: channelObject["description"],
				royaltyTypeId: channelObject["royaltyType"],
				subscriptionRegions: channelObject["subscriptionRegions"],
				sortOrder: channelObject["sortOrder"],
				spotifyPlaylistUrl: channelObject["spotifyPlaylistUrl"],
				playlist: channelObject["playlist"],
				isFeaturedChannel: channelObject["isFeaturedChannel"],
				imageURL: channelObject["imageURL"]
			});
			return channel;
		}
	});

	exports.default = Channel;
});