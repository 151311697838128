define('storeplay-admin/components/forms/new-store-group-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service("current-user"),
		apiClient: Ember.inject.service("api-client"),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		newGroupName: null,
		onGroupWasCreated: null,

		actions: {
			createGroup: function createGroup(name) {
				var _this2 = this;

				var errors = this.performValidation();
				console.log(errors);

				if (errors.length != 0) {
					// TODO: Make modals support new lines!
					// Present a modal saying hey something went wrong.
					var errorMessage = errors.join("\n");
					this.get('applicationController').displayModal({ title: "Validation Error", message: errorMessage });
				} else {
					// There were no errors. We can save all the information.
					var apiClient = this.get("apiClient");
					var user = this.get('currentUser');
					var model = this.get('model');
					var _this = this;

					apiClient.rpc("saveStoreGroup", {
						storeGroup: {
							name: name,
							clientID: user.clientID
						}
					}).then(function (response) {
						if (_this.onGroupWasCreated) {
							_this.onGroupWasCreated();
						}
						// The route will catch the action "refreshRoute" which we have defined in its action list.
						// _this.send("refreshRoute");
					}).catch(function (errorObject) {
						console.log('errorObject : %o', errorObject);
						_this2.get('applicationController').displayModal({
							title: 'Error',
							message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
						});
					});
				}
			}
		},

		performValidation: function performValidation() {
			var errors = [];

			var groupName = this.get("newGroupName");

			if (groupName == null || groupName == "") {
				errors.push("The group name cannot be empty.");
			}

			return errors;
		}

	});
});