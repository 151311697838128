define('storeplay-admin/helpers/format-moment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatMoment = formatMoment;
  function formatMoment(params) {
    var moment = params[0];
    var format = params[1];
    return moment.format(format);
  }

  exports.default = Ember.Helper.helper(formatMoment);
});