define('storeplay-admin/components/controls/nary-switch', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		utility: Ember.inject.service("utility"),

		options: ["One", "Two", "Three"],
		selectedOption: "One",

		// onEnable: function(id){},
		// onDisable: function(id){},

		onOptionChanged: function onOptionChanged(newOption) {},

		init: function init(args) {
			this._super(args);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component has now been added to the dom and ready to be manipulated.
			// Do any setup here.
		},

		willDestroyElement: function willDestroyElement() {
			// Component will be removed from the dom.
			// Do any cleanup here.
		},

		actions: {
			selectOption: function selectOption(option) {
				console.log(option);
				this.set("selectedOption", option);
				this.onOptionChanged(this.get("selectedOption"));
			}
		}

	});
});