define('storeplay-admin/models/admin-report', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var AdminReport = Ember.Object.extend({
		fileName: null,
		downloadUrl: null,
		toJSON: function toJSON() {
			var json = {};
			json["fileName"] = this.get("fileName");
			json["downloadUrl"] = this.get("downloadUrl");
			return json;
		}
	});

	AdminReport.reopenClass({
		// Class properties/methods
		fromJSON: function fromJSON(jsonObj) {
			var adminReport = this.create({
				fileName: jsonObj["fileName"],
				downloadUrl: jsonObj["downloadUrl"]
			});
			return adminReport;
		}
	});

	exports.default = AdminReport;
});