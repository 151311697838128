define('storeplay-admin/models/image', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Image = Ember.Object.extend({
		offerID: null, // Why is there here?!

		file: null,
		src: null,

		width: null,
		height: null,

		isImage: Ember.computed('file', function () {
			return this.get('file.type') == "image/png";
		}),

		fileSize: Ember.computed('file', function () {
			return this.get('file.size');
		}),

		fileName: Ember.computed('file', function () {
			return this.get('file.name');
		}),

		fileType: Ember.computed('file', function () {
			return this.get('file.type');
		})
	});

	exports.default = Image;
});