define('storeplay-admin/components/country-selector', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		actions: {
			setCountry: function setCountry(model, event) {
				model.set('iso2CountryCode', event.target.value);
			}
		}
	});
});