define('storeplay-admin/components/dropzone-area', ['exports', 'storeplay-admin/models/image', 'ember-changeset', 'ember-changeset-validations', 'storeplay-admin/validations/image'], function (exports, _image, _emberChangeset, _emberChangesetValidations, _image2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['uploader', 'dropzone'],
        imageObj: null,
        isNewImage: false,
        actions: {
            uploadImage: function uploadImage(event) {
                var imageFile = event.dataTransfer.files[0];
                this.checkFileType(imageFile);
            },
            selectFromComputer: function selectFromComputer(event) {
                var imageFile = event.blob;
                this.checkFileType(imageFile);
            }
        },
        processImage: function processImage(imageFile) {
            var _this = this;
            var image = new Image();
            var reader = new FileReader();

            // Process the image 
            reader.onload = function (e) {
                image.src = e.target.result;
                image.onload = function () {
                    // Create the image object
                    _this.imageObj = _image.default.create({
                        file: imageFile,
                        width: image.width,
                        height: image.height
                    });
                    var imageChangeset = new _emberChangeset.default(_this.imageObj, (0, _emberChangesetValidations.default)(_image2.default), _image2.default, { skipValidate: true });
                    imageChangeset.validate().then(function () {
                        if (Object.values(imageChangeset.get('_errors')).length > 0) {
                            var dimension = imageChangeset.get('errors.firstObject.value');
                            if (dimension < 300) {
                                _this.showModal(_this.get('modalHash.tooSmall'));
                            } else {
                                _this.showModal(_this.get('modalHash.tooLarge'));
                            }
                        } else {
                            // Set the image on the changeset 
                            _this.set("changeset.image", _this.imageObj);
                            Ember.$('#image').attr('src', image.src);
                            _this.set('isNewImage', true);
                        }
                    });
                };
            };
            reader.readAsDataURL(imageFile);
        },
        checkFileType: function checkFileType(imageFile) {
            var fileType = Ember.get(imageFile, 'type') || "none";
            switch (fileType) {
                case "image/png":
                    this.processImage(imageFile);
                    break;
                case "image/jpeg":
                    this.processImage(imageFile);
                    break;
                default:
                    this.showModal(this.get('modalHash.incorrectFormat'));
            }
        },
        showModal: function showModal(modalHash) {
            var applicationController = Ember.getOwner(this).lookup('controller:application');
            applicationController.displayModal(modalHash);
        },

        modalHash: {
            tooLarge: {
                title: 'Image too large',
                message: 'Your image is too large, please ensure the image is less than or equal to 1000 pixel in width and height'
            },
            tooSmall: {
                title: 'Image too small',
                message: 'Your image is too small, please ensure the image is greater than 400 pixel in width and height'
            },
            incorrectFormat: {
                title: 'Unsupported file type',
                message: 'The file you have uploaded is not supported, please ensure the Images is PNG or JPEG format'
            }
        }
    });
});