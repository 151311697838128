define('storeplay-admin/components/dashboard/client-notes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),

		numberOfColumns: 6,
		tagName: "", // This ensures the component is not wrapped in an ember-view div, which is not needed for this component.

		clientNotes: "",

		maxCharacters: 4000,
		remainingCharacters: Ember.computed("clientNotes", function () {
			var notes = this.get("clientNotes") || "";
			return this.maxCharacters - notes.length;
		}),

		isFetchingNotes: true,
		saveFailureMessage: null,

		init: function init(args) {
			// The component has been created in memory
			this._super(args);
		},

		didInsertElement: function didInsertElement() {
			// Component was added to the DOM
			this._super.apply(this, arguments);
			this.fetchAdminNotes();
		},

		willDestroyElement: function willDestroyElement() {
			// Component is about to be removed from the DOM
			this._super.apply(this, arguments);
		},

		actions: {
			save: function save() {

				if (this.get("remainingCharacters") < 0) {
					this.showFailure("Notes are too long");
					return;
				}

				if (this.get("isFetchingNotes")) {
					this.showFailure("Fetching notes... Please wait.");
					return;
				}

				// Otherwise we are good to go ahead and save.
				this.saveAdminNotes();
			}
		},

		// Communication with API for admin notes
		// ######################################

		fetchAdminNotes: function fetchAdminNotes() {
			var _this = this;
			var user = this.get("currentUser");
			var clientID = this.get("currentUser.clientID");
			var apiClient = this.get("apiClient");

			apiClient.rpc("getNotesForClient", { clientID: clientID }, user.adminToken).then(function (notes) {
				_this.set("isFetchingNotes", false);
				_this.set("clientNotes", notes);
			}).catch(function (error) {
				_this.set("isFetchingNotes", false);
			});
		},

		saveAdminNotes: function saveAdminNotes() {
			var _this = this;
			var user = this.get("currentUser");
			var clientID = this.get("currentUser.clientID");
			var apiClient = this.get("apiClient");

			this.showLoading();
			apiClient.rpc("saveNotesForClient", {
				clientID: clientID,
				notes: this.get("clientNotes")
			}, user.adminToken).then(function (result) {
				_this.showSuccess();
				// console.log(result);
				// console.log("Saved client notes.");
			}).catch(function (error) {
				_this.showFailure();
				// console.log(error);
			});
		},

		// Save button state indicator.
		// ############################

		// TODO: Turn this into a component which we can reuse.
		// This would be useful all over the place.

		timeoutIDForHidingAnimation: null,

		showLoading: function showLoading() {
			// Hide any visible success/failure
			clearTimeout(this.timeoutIDForHidingAnimation);
			$("#clientnotes-save-success").css("display", "none");
			$("#clientnotes-save-failure").css("display", "none");

			// Show loading
			$("#clientnotes-save-loading").css("display", "inline");
			$("#clientnotes-save-loading").animate({
				opacity: 1
			}, 300, function () {
				// Completion
				// Nothing to do.
			});
		},

		showSuccess: function showSuccess() {
			this.showResult("success");
		},

		showFailure: function showFailure(saveFailureMessage) {
			this.showResult("failure");
			this.set("saveFailureMessage", saveFailureMessage);
		},

		showResult: function showResult(result) {
			var _this = this;
			var resultID = '#clientnotes-save-' + result;

			// Fade out loading.
			$("#clientnotes-save-loading").animate({
				opacity: 0
			}, 300, function () {
				$("#clientnotes-save-loading").css("display", "none");
				$(resultID).css("display", "inline");

				// Fade in result
				$(resultID).animate({
					opacity: 1
				}, 300, function () {});

				// After 5 second. Fade out result.
				_this.timeoutIDForHidingAnimation = setTimeout(function () {
					$(resultID).animate({
						opacity: 0
					}, 300, function () {
						$(resultID).css("display", "none");
					});
				}, 2500);
			});
		}

	});
});