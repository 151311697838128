define('storeplay-admin/components/forms/schedule-amcos-bms-report-form', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service("current-user"),
		ajax: Ember.inject.service(),
		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),
		isLoading: false,
		clientId: null,
		clients: [],
		init: function init(args) {
			this._super(args);
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			// Add the options to the date selector
			var now = moment();
			var fromDate = $('#fromDate');
			for (var i = 0; i < 24; i++) {
				fromDate.append($('<option>', {
					value: now.format('YYYY-MM') + "-1",
					text: now.format('MMM YYYY')
				}));
				now.subtract(1, 'months');
			}
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			// Component is about to be removed from the DOM
		},
		actions: {
			generate: function generate() {
				var _this = this;
				var user = this.get("currentUser");
				var apiClient = this.get("apiClient");
				this.set("isLoading", true);
				apiClient.rpc('createScheduleAmcosBmsReport', {
					fromDate: $('#fromDate').val(),
					numberOfMonths: $('#numberOfMonths').val(),
					isoCountryCode: $('#isoCountryCode').val(),
					clientId: _this.get('clientId')
				}, user.adminToken).then(function (response) {
					// response is the report file name
					if (response) {
						_this.get('applicationController').displayModal({
							title: "Success",
							message: "The report has been created."
						});
					}
					_this.set("isLoading", false);
					// Redirect to API report URL. See what happens... it should download?
					window.location.href = _environment.default.apiBaseURL + "/admin-reports/" + response + "?authToken=" + user.adminToken;
				}).catch(function (error) {
					_this.get('applicationController').displayModal({ title: "Error", message: "Unable to create the report. Please try again." });
					console.log(error);
					_this.set("isLoading", false);
				});
			},
			setClient: function setClient(clientId) {
				this.set('clientId', clientId);
			}
		}
	});
});