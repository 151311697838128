define('storeplay-admin/models/ar-experience', ['exports', 'storeplay-admin/models/client'], function (exports, _client) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var arExperience = Ember.Object.extend({
		id: null,
		name: null,
		clientID: null,
		client: null,
		targetID: null,
		posterImageURL: null,
		imageURL: null,
		videoURL: null,
		targetRating: null,
		image: null,

		toJSON: function toJSON() {
			var json = {};
			json["id"] = this.get('id');
			json["name"] = this.get('name');
			json["clientID"] = this.get('clientID');
			json["videoURL"] = this.get('videoURL');
			return json;
		}
	});

	arExperience.reopenClass({
		fromJSON: function fromJSON(arExperiences) {
			var experience = this.create({
				id: arExperiences["id"],
				name: arExperiences["name"] || null,
				clientID: arExperiences["clientID"] || null,
				client: arExperiences["client"] != null ? _client.default.fromJSON(arExperiences["client"]) : null,
				targetID: arExperiences["targetID"] || null,
				posterImageURL: arExperiences["posterImageURL"] || null,
				videoURL: arExperiences["videoURL"] || null,
				targetRating: arExperiences["targetRating"],
				imageURL: arExperiences["posterImageURL"] || null
			});
			return experience;
		}
	});
	exports.default = arExperience;
});