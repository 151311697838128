define('storeplay-admin/validations/image', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var imageValidation = {
        width: [(0, _validators.validateNumber)({ gte: 300 }), (0, _validators.validateNumber)({ lte: 1000 })],
        height: [(0, _validators.validateNumber)({ gte: 300 }), (0, _validators.validateNumber)({ lte: 1000 })]
    };

    exports.default = imageValidation;
});