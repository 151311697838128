define('storeplay-admin/models/preview-playlist-song', ['exports', 'storeplay-admin/models/song'], function (exports, _song) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.PreviewPlaylistSongStatus = undefined;


	var PreviewPlaylistSongStatus = Object.freeze({
		/// The default state for the song, hasn't been changed.
		default: 0,

		/// The song has been approved to be included in the playlist.
		included: 1,

		/// The song is a spare, which can be swapped in to replace another song
		spare: 2,

		/// The song has been swapped in, that is, it was a spare, not it is included.
		swappedIn: 3,

		/// The song has been swapped out, that is, it was included but has been replaced.
		swappedOut: 4
	});

	var PreviewPlaylistSong = _song.default.extend({
		isApproved: null,
		swappedIn: null,
		swappedOut: null,
		swappedSong: null
	});

	PreviewPlaylistSong.reopenClass({
		// Static Methods
	});

	exports.default = PreviewPlaylistSong;
	exports.PreviewPlaylistSongStatus = PreviewPlaylistSongStatus;
});