define('storeplay-admin/models/song', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Song = Ember.Object.extend({
		id: null,
		extension: null,
		mediaID: null,
		title: null,
		artist: null,
		track: null,
		album: null,
		year: null,
		recordCompany: null,
		bpm: null,
		volumeOffset: null,
		isrc: null,
		fileHash: null,
		fileSize: null,
		duration: 0, // In seconds
		streamURL: null,
		albumArtImageUrl: null,
		genres: null,
		isClientBlocked: false,
		isRecent: null,
		durationFormatted: Ember.computed('duration', function () {

			var duration = this.get('duration');

			if (!duration && duration > 0) return "Unknown";

			// Else work out minutes / sec
			var minutes = Math.floor(duration / 60);
			var seconds = duration % 60;

			var display = "";
			display += "" + minutes + ":" + (seconds < 10 ? "0" : "");
			display += "" + seconds;
			return display;
		}),
		searchString: Ember.computed('title', 'artist', function () {
			return (this.get('title') + ',' + this.get('artist')).toLowerCase();
		}),
		matches: function matches(str) {
			return this.get('searchString').indexOf(str.toLowerCase()) !== -1;
		}
	});

	Song.reopenClass({
		// Static Methods
	});

	exports.default = Song;
});