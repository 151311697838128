define('storeplay-admin/components/tables/channels-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base', 'storeplay-admin/config/environment'], function (exports, _sortableTableBase, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		linkToRoute: null,

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);
			this.set("apiBaseURL", _environment.default.apiBaseURL);
		},

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
		},
		didUpdateAttrs: function didUpdateAttrs() {
			this._super.apply(this, arguments);
		},


		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [{ title: "Name", sorter: "name", width: "200px" }, { title: "Licence", sorter: "royalty", width: "150px" }, { title: "Countries", sorter: "countries", width: "200px" }, { title: "Sort Order", sorter: "sortOrder", width: "80px" }, { title: "", sorter: null, width: "80px" }],

		sorters: {
			name: function name(left, right) {
				return this.orderOf(left.name.toLowerCase(), right.name.toLowerCase());
			},
			royalty: function royalty(left, right) {
				return this.orderOf(left.royaltyType.description, right.royaltyType.description);
			},
			countries: function countries(left, right) {
				return this.orderOf(left.subscriptionRegions.toString(), right.subscriptionRegions.toString());
			},
			sortOrder: function sortOrder(left, right) {
				return this.orderOf(left.sortOrder, right.sortOrder);
			}
		},

		// Channel-table specific
		// #####################
		actions: {
			filterByClient: function filterByClient(clientID) {
				console.log();
			}
		}
	});
});