define('storeplay-admin/routes/authenticated/payment-methods/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {
			return {
				type: 1,
				name: '',
				number: '',
				expiryMonth: 0,
				expiryYear: 0,
				securityCode: ''
			};
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			var _this = this;
			controller.set('refreshRoute', function () {
				console.log('refreshRoute in setupController...');
				_this.refresh();
			});
		}
	});
});