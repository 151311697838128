define('storeplay-admin/services/api-client', ['exports', 'storeplay-admin/config/environment', 'ember-uuid'], function (exports, _environment, _emberUuid) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		currentUser: Ember.inject.service(),
		router: Ember.inject.service(),

		rpc: function rpc(method, params, _token, anon) {
			var user = this.get('currentUser');
			var ajax = this.get('ajax');
			var token = null;
			if (_token) {
				token = _token;
			} else {
				if (user.isAdminSuperUser == true && user.continueAsAdmin == true) {
					token = user.adminToken;
				} else {
					token = user.token;
				}
			}

			// check for anonymous request.no token required.
			var headers = null;
			if (anon) {
				headers = {
					'X-App-Type': 'Admin'
				};
			} else {
				headers = {
					'X-App-Type': 'Admin',
					'Authorization': 'Bearer ' + token
				};
			}

			return ajax.request(_environment.default.apiBaseURL + '/rpc', {
				method: 'POST',
				contentType: 'application/json; charset=utf-8',
				data: {
					"jsonrpc": "2.0",
					"id": (0, _emberUuid.v4)(),
					"method": method,
					"params": params
				},
				headers: headers
			}).then(function (response) {
				Ember.Logger.log(response); // TODO: only log in dev builds
				if (response.error != null) {
					Ember.Logger.error('storePlay API JSON-RPC error ' + response.error.code + ': ' + response.error.message + ' (' + response.error.data + ')');
					return response;

					// TODO: To be able to catch api errors such as methodNotFound etc, we need to throw the error here.
					// However, need to test everything to make sure this works as expected.
					// throw response; 
				} else {
					return response.result;
				}
			});
		},

		// Alternative RPC method which will report the current progress of the request.
		// This is useful for bigger uploads, such as images and audio files.
		// progressCallback will be called with the how far along the upload is. 
		// Will be a number in the range of 0-100.
		rpcWithProgressCallback: function rpcWithProgressCallback(method, params, progressCallback, _token) {
			var user = this.get('currentUser');
			var ajax = this.get('ajax');
			var token = _token || user.token;
			return ajax.request(_environment.default.apiBaseURL + '/rpc', {
				method: 'POST',
				contentType: 'application/json; charset=utf-8',
				data: {
					"jsonrpc": "2.0",
					"id": (0, _emberUuid.v4)(),
					"method": method,
					"params": params
				},
				headers: {
					'X-App-Type': 'Admin',
					'Authorization': 'Bearer ' + token
				},
				xhr: function xhr() {
					var jqXHR = null;
					if (window.ActiveXObject) {
						jqXHR = new window.ActiveXObject("Microsoft.XMLHTTP");
					} else {
						jqXHR = new window.XMLHttpRequest();
					}

					//Upload progress
					jqXHR.upload.addEventListener("progress", function (evt) {
						if (evt.lengthComputable) {
							var percentComplete = Math.round(evt.loaded * 100 / evt.total);
							//Do something with upload progress
							if (progressCallback) {
								progressCallback(percentComplete);
							}
						}
					}, false);
					return jqXHR;
				}
			}).then(function (response) {
				Ember.Logger.log(response); // TODO: only log in dev builds
				if (response.error != null) {
					Ember.Logger.error('storePlay API JSON-RPC error ' + response.error.code + ': ' + response.error.message + ' (' + response.error.data + ')');
					return response;
				} else {
					return response.result;
				}
			});
		}

	});
});