define('storeplay-admin/components/option-selector', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didChange: function didChange(event) {
			var callback = this.get('callback');
			Ember.assert("The `callback` property needs to be set when using `option-select`!", callback != null && callback instanceof Function);
			callback(event.target.value);
		}
	});
});