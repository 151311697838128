define('storeplay-admin/validations/ar-experience', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var ARValidation = Ember.Object.extend({
        name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 100 })],
        videoURL: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 500 })]
    });

    exports.default = ARValidation;
});