define('storeplay-admin/components/dashboard/no-music-clients-panel', ['exports', 'storeplay-admin/models/client', 'storeplay-admin/config/environment'], function (exports, _client, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),
		numberOfColumns: 4,
		tagName: "", // This ensures the component is not wrapped in an ember-view div, which is not needed for this component.
		isFetchingData: true,
		errorFetchingData: false,
		noMusicClients: null,
		apiBaseURL: _environment.default.apiBaseURL,

		init: function init(args) {
			// The component has been created in memory
			this._super(args);

			this.set("noMusicClients", []);
		},

		didInsertElement: function didInsertElement() {
			// Component was added to the DOM
			this._super.apply(this, arguments);

			this.set("randomNumber", Math.random());

			this.fetchNoMusicClientData();
		},

		willDestroyElement: function willDestroyElement() {
			// Component is about to be removed from the DOM
			this._super.apply(this, arguments);
		},

		actions: {},

		// Fetching No Music Client Data
		// #############

		fetchNoMusicClientData: function fetchNoMusicClientData() {
			var _this = this;
			var apiClient = this.get('apiClient');

			var getAllClientsWithNoSongsCall = apiClient.rpc('getAllClientsWithNoSongs');
			var getSubscriptionPlansCall = apiClient.rpc('getAllSubscriptionPlans');

			Promise.all([getAllClientsWithNoSongsCall, getSubscriptionPlansCall]).then(function (results) {
				console.log(results);
				var clientJSONObjects = results[0];
				var subscriptionPlans = results[1];

				var clients = _this.attachSubscriptionDetailsToClients(clientJSONObjects, subscriptionPlans);

				_this.set("noMusicClients", clients);
				_this.set("isFetchingData", false);
			}).catch(function (error) {
				_this.set("isFetchingData", false);
				_this.set("errorFetchingData", true);
				console.log(error);
			});
		},

		attachSubscriptionDetailsToClients: function attachSubscriptionDetailsToClients(clientJSONObjects, subscriptionPlans) {

			// Create the client models from the JSON
			var noMusicClients = clientJSONObjects.map(function (json) {
				var client = _client.default.fromJSON(json);

				if (json.extra) {
					client.extra = {
						subscriptionStartedDate: moment(json.extra.subscriptionStartedDate),
						subscriptionPlanID: json.extra.subscriptionPlanID
					};
				}

				return client;
			});

			// Sort the clients.
			var sortedClients = noMusicClients.sort(function (left, right) {
				if (left.extra && right.extra) {
					return left.extra.subscriptionStartedDate.isBefore(right.extra.subscriptionStartedDate) ? 1 : -1;
				}
				return 0;
			});

			// Given a plan ID, update the name accordingly.
			sortedClients.forEach(function (client) {
				if (client.extra) {
					client.extra.subscriptionPlanName = subscriptionPlans.filter(function (plan) {
						return plan.id === client.extra.subscriptionPlanID;
					})[0].name;
				}
			});

			return sortedClients;
		}
	});
});