define('storeplay-admin/authenticators/pass-through', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		restore: function restore(data) {
			return new Promise(function (resolve, reject) {
				reject();
			});
		},
		authenticate: function authenticate(token) {
			// TODO : Consider hitting some token validation endpoint on the server. For now
			// we're just automatically resolving and letting the load() method on the current
			// user service deal with issues.
			return new Promise(function (resolve, reject) {
				resolve({
					token: token
				});
			});
		}
	});
});