define('storeplay-admin/routes/authenticated/scheduling/global', ['exports', 'storeplay-admin/models/client-schedule'], function (exports, _clientSchedule) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		currentUser: Ember.inject.service('currentUser'),
		apiClient: Ember.inject.service('apiClient'),

		model: function model() {
			var apiClient = this.get('apiClient');

			return Promise.all([apiClient.rpc('getScheduleForClient', { clientID: 0 })] // global 
			).then(function (responseJSONs) {

				var clientSchedule = _clientSchedule.default.fromJSON(responseJSONs[0]);
				console.log(clientSchedule);
				return clientSchedule;
			});
		}
	});
});