define('storeplay-admin/controllers/authenticated/subscription/exit-survey', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			didSubmitSurvey: function didSubmitSurvey() {
				this.transitionToRoute('authenticated.subscription');
			}
		}
	});
});