define('storeplay-admin/helpers/capitalise-word', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.capitaliseWord = capitaliseWord;
	function capitaliseWord(value) {

		return value.charAt(0).toUpperCase() + value.slice(1);
	}

	exports.default = Ember.Helper.helper(capitaliseWord);
});