define("storeplay-admin/helpers/format-schedule-summary", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.formatScheduleSummary = formatScheduleSummary;
	function formatScheduleSummary(params) {

		var scheduleItems = params[0];

		var daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		var summary = "";
		var summarySchedules = [];

		// build an array of summary items, using the passed parameter.
		scheduleItems.forEach(function (day, index, arr) {
			var from = day.from;
			var to = day.to;
			var time = "";
			if (from == "00:00:00" && to == "00:00:00" || from == "always") {
				time = "Always On";
			} else if (from == "-01:00:00" && to == "-01:00:00" || from == "user") {
				time = "User Controlled";
			} else {
				time = day.from.slice(0, -3) + "-" + day.to.slice(0, -3);
			}

			var daySummary = { day: daysOfWeek[day.dayOfWeek], time: time };
			if (index == 0) {
				summarySchedules.push(daySummary); // add new
			} else {
				var prev = summarySchedules[summarySchedules.length - 1];
				if (prev.time == daySummary.time) {
					if (!prev.day.includes("-")) prev.day += "-" + daySummary.day;else prev.day = prev.day.slice(0, 3) + ("-" + daySummary.day);
				} else {
					summarySchedules.push(daySummary);
				}
			}
		});

		summarySchedules.forEach(function (item, index, arr) {
			summary += item.day + ": " + item.time + (index != arr.length - 1 ? ", " : "");
		});

		return summary;
	}

	exports.default = Ember.Helper.helper(formatScheduleSummary);
});