define('storeplay-admin/models/store-schedule', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var StoreSchedule = Ember.Object.extend({

		storeID: null,
		playbackSchedule: null,
		scheduleDetails: Ember.computed('playbackSchedule', 'timeZone', function () {
			// Get relative time
			var tz = this.get('timeZone') || 'Australia/Melbourne'; // Olson string provided by API
			var localNow = moment(new Date()).tz(tz);
			var localNowClamped = localNow.add(-(localNow.minute() % 15), "minutes");
			var timestamp = localNowClamped.format("HH:mm:00");
			var day = localNowClamped.day();

			// API Week starts on Monday, MomentJS on Sunday
			day = day == 0 ? 6 : day - 1;

			var schedule = (this.get('playbackSchedule.items') || []).find(function (s) {
				return s.dayOfWeek == day;
			});

			if (schedule == null) return { label: "No schedule", id: -1 };

			var from = this.timestampToSeconds(schedule.from);
			var to = this.timestampToSeconds(schedule.to);
			var now = this.timestampToSeconds(timestamp);

			if (now >= to - 15 * 60) {
				return { label: "About to go offline", id: 1 };
			} else if (now >= from && now <= to) {
				return { label: "On", id: 0 };
			} else {
				return { label: "Off", id: 2 };
			}
		}),
		createPlaybackSchedule: function createPlaybackSchedule(from, to) {
			var schedule = {
				items: []
			};
			for (var i = 0; i <= 6; i++) {
				schedule.items.push({
					dayOfWeek: i,
					from: from ? from : "00:00:00",
					to: to ? to : "00:00:00"
				});
			}
			this.set('playbackSchedule', schedule);
		},
		timestampToSeconds: function timestampToSeconds(ts) {
			var components = ts.split(":");
			return (components[0] || 0) * 3600 + (components[1] || 0) * 60;
		},
		toJSON: function toJSON() {
			var json = {};

			json["storeID"] = this.get("storeID");
			json["playbackSchedule"] = this.get("playbackSchedule");

			return json;
		}
	});

	StoreSchedule.reopenClass({
		fromJSON: function fromJSON(storeScheduleJSONObject) {
			if (storeScheduleJSONObject != null) {
				var storeSchedule = this.create({

					clientID: storeScheduleJSONObject["storeID"],
					playbackSchedule: storeScheduleJSONObject["playbackSchedule"]

				});
				return storeSchedule;
			}
			return null;
		}
	});

	exports.default = StoreSchedule;
});