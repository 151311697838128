define('storeplay-admin/helpers/format-moment-relative', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatMomentRelative = formatMomentRelative;
  function formatMomentRelative(params) {
    var moment = params[0];
    return moment.fromNow();
  }

  exports.default = Ember.Helper.helper(formatMomentRelative);
});