define('storeplay-admin/components/tables/ar-experiences-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base'], function (exports, _sortableTableBase) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		apiClient: Ember.inject.service("api-client"),

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);
		},

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super.apply(this, arguments);

			// Table has been added to the DOM. 
			// We can now start fetching the health status for each row.
			if (!this.get("isAdmin")) {
				var _loop = function _loop(arExperience) {
					_this.fetchArExperienceHealth(arExperience.id).then(function (response) {
						arExperience.set('health', response);
					});
				};

				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = this.get("rows")[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var arExperience = _step.value;

						_loop(arExperience);
					}
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator.return) {
							_iterator.return();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}
			}

			// We use this with the image URL just to get around the caching, so updates to the trigger image appear instantly.
			this.set("randomNumber", Math.random());
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [{ title: "", sorter: null, width: "65px" }, // AR-Experience Image Column
		{ title: "Client", sorter: "client", width: "12%", adminOnly: true }, { title: "Name", sorter: "name" }, { title: "Target ID", sorter: "targetID" }, { title: "Processing Status", sorter: "arProcessingStatus", clientOnly: true }, { title: "Video Playable", sorter: "isVideoPlayable", clientOnly: true }, { title: "Poster Rating", sorter: "arTrackingRating", clientOnly: true }, { title: "", sorter: null, width: "50px" }],

		sorters: {
			client: function client(left, right) {
				return this.orderOf(left.client.brandName.toLowerCase(), right.client.brandName.toLowerCase());
			},

			name: function name(left, right) {
				return this.orderOf(left.name.toLowerCase(), right.name.toLowerCase());
			},

			targetID: function targetID(left, right) {
				var leftTargetID = left.targetID ? left.targetID.toLowerCase() : null;
				var rightTargetID = right.targetID ? right.targetID.toLowerCase() : null;

				return this.orderOf(leftTargetID, rightTargetID);
			},

			arProcessingStatus: function arProcessingStatus(left, right) {
				var leftHealth = left.get("health");
				var rightHealth = right.get("health");
				return this.orderOf(leftHealth.arProcessingStatus, rightHealth.arProcessingStatus);
			},

			isVideoPlayable: function isVideoPlayable(left, right) {
				var leftHealth = left.get("health");
				var rightHealth = right.get("health");
				return this.orderOf(leftHealth.isVideoPlayable, rightHealth.isVideoPlayable);
			},

			arTrackingRating: function arTrackingRating(left, right) {

				var leftStars = left.get("health.stars");
				var rightStars = right.get("health.stars");

				if (leftStars && rightStars) {
					var numStarsLeft = leftStars.reduce(function (acc, current) {
						return acc + (current.active ? 1 : 0);
					}, 0);

					var numStarsRight = rightStars.reduce(function (acc, current) {
						return acc + (current.active ? 1 : 0);
					}, 0);

					return this.orderOf(numStarsLeft, numStarsRight);
				} else {
					return 0;
				}
			}
		},

		// AR-Experiences Table Specific
		// #############################

		isAdmin: false,

		fetchArExperienceHealth: function fetchArExperienceHealth(arExperienceID) {
			var _this2 = this;

			return new Ember.RSVP.Promise(function (resolve, reject) {
				var apiClient = _this2.get('apiClient');

				apiClient.rpc('getARExperienceHealth', {
					arExperienceID: arExperienceID
				}).then(function (arHealth) {
					if (arHealth == null) {
						reject();
					}

					// Uppercase the first letter, since it comes down all lower case.
					arHealth.arProcessingStatus = arHealth.arProcessingStatus.charAt(0).toUpperCase() + arHealth.arProcessingStatus.slice(1);

					arHealth.stars = [1, 2, 3, 4, 5].map(function (x) {
						return {
							"active": x <= arHealth.arTrackingRating ? true : false
						};
					});

					resolve(arHealth);
				}).catch(function (error) {
					Ember.Logger.log('Failed to retrieve AR experience health ' + error);
				});
			});
		}
	});
});