define('storeplay-admin/routes/reset-password', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		apiClient: Ember.inject.service('api-client'),
		setupController: function setupController(controller, model) {

			var apiClient = this.get('apiClient');
			apiClient.rpc('checkAdminPasswordReset', {
				guid: this.controller.get('guid')
			}, null, true).then(function (result) {
				console.log("Checking guid with server.");
				if (result.guid) {
					controller.set('isGuidValid', true);
				}
			}).catch(function (errorObject) {
				controller.set('isGuidValid', false);
			});
		}
	});
});