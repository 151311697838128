define('storeplay-admin/models/store-license-link', ['exports', 'storeplay-admin/models/geocoordinate'], function (exports, _geocoordinate) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Object.extend({
		geolocation: Ember.inject.service(),
		license: null,
		store: null,
		buildVersionFilter: 11720,
		linked: Ember.computed('license', 'store', function () {
			return this.get('license') && this.get('store');
		}),

		supportsOnOffScheduling: Ember.computed('license', 'buildVersionFilter', function () {
			// filter for devices with players > 2.2.2.buildVersionFilter
			var license = this.get('license');
			var buildVersionFilter = this.get('buildVersionFilter');
			if (license && license.device && license.device.appVersion != "" && license.device.appVersion != null) // might need other checks...
				{
					if (license.device.appVersion.match(/[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+/) != null) {
						var buildVersionStr = license.device.appVersion.substring(license.device.appVersion.lastIndexOf(".") + 1);
						var buildVersion = parseInt(buildVersionStr);
						if (!isNaN(buildVersion) && buildVersion >= buildVersionFilter) return true;
					}
				}

			return false;
		}),

		storeCoordinate: Ember.computed('store.{latitude,longitude}', function () {
			var store = this.get('store');
			if (store && store.latitude && store.longitude && store.latitude !== 0 && store.longitude !== 0) {
				return _geocoordinate.default.create({ latitude: store.latitude, longitude: store.longitude });
			}
			return null;
		}),

		deviceCoordinate: Ember.computed('license', function () {
			var license = this.get('license');
			if (license && license.device && license.device.latitude && license.device.longitude && license.device.latitude !== 0 && license.device.longitude !== 0) {
				return _geocoordinate.default.create({ latitude: license.device.latitude, longitude: license.device.longitude });
			}
			return null;
		}),

		deviceCoordinateSortable: Ember.computed('deviceCoordinate', 'deviceCoordinateAccuracy', function () {

			if (this.get('deviceIsNearStore')) {
				return "A";
			} else if (this.get('deviceIsNearStore') == false && this.get('deviceCoordinate') !== null && this.get('linked') != null) {
				return "B";
			} else if (this.get('deviceCoordinate') != null) {
				return "C";
			} else {
				return "D";
			}
		}),

		deviceCoordinateAccuracy: Ember.computed('license', function () {
			var license = this.get('license');
			if (license && license.device && license.device.locationAccuracy) {
				return license.device.locationAccuracy;
			}
			return 0;
		}),

		deviceDistanceFromStore: Ember.computed('license', 'store', function () {
			var deviceCoordinate = this.get('deviceCoordinate');
			var storeCoordinate = this.get('storeCoordinate');
			if (deviceCoordinate == null || storeCoordinate == null) {
				return -1;
			}
			var geolocation = this.get('geolocation');
			var distance = geolocation.getDistance(deviceCoordinate.latitude, deviceCoordinate.longitude, storeCoordinate.latitude, storeCoordinate.longitude);
			return Math.round(distance);
		}),

		deviceIsNearStore: Ember.computed('license', 'store', function () {
			var distance = this.get('deviceDistanceFromStore');
			if (distance >= 0) {
				var accuracy = this.get('deviceCoordinateAccuracy');
				return distance - accuracy < 100;
			}
			return false;
		}),

		lastPlaybackTimestamp: null,
		timeOfflineSeconds: Ember.computed('license', 'lastPlaybackTimestamp', 'lastPlaybackType', function () {
			var license = this.get('license');
			var lastPlaybackTimestamp = this.get('lastPlaybackTimestamp');
			var lastPlaybackType = this.get('lastPlaybackType');
			var difference = null;
			if (license) {
				if (lastPlaybackTimestamp) {
					var lastPlayback = moment(lastPlaybackTimestamp);
					difference = moment().diff(lastPlayback, 'seconds');
				}
			}
			return difference;
		}),
		timeOfflineDescription: Ember.computed('timeOfflineSeconds', function () {
			var timeOfflineSeconds = this.get('timeOfflineSeconds');
			if (timeOfflineSeconds != null) return this.secondsToDhm(timeOfflineSeconds);

			return "";
		}),
		lastPlaybackType: null,
		searchString: Ember.computed('store.searchString', 'license', function () {
			var searchString = '';
			var store = this.get('store.searchString');
			var license = this.get('license');

			searchString += store || '';

			if (license != null && license.device != null) {
				var deviceId = (license.device.udid || '').toLowerCase();
				var deviceName = (license.device.name || '').toLowerCase();
				var deviceModel = (license.device.model || '').toLowerCase();
				var devicePlatform = (license.device.platform || '').toLowerCase();
				var deviceSystemVersion = (license.device.operatingSystemVersion || '').toLowerCase();
				var deviceAppVersion = (license.device.appVersion || '').toLowerCase();
				searchString += ',' + deviceName + ',' + deviceId + ',' + deviceModel + ',' + devicePlatform + ',' + deviceSystemVersion + ',' + deviceAppVersion;
			}
			return searchString;
		}),

		matches: function matches(str) {
			return this.get('searchString').indexOf(str) !== -1;
		},
		secondsToDhm: function secondsToDhm(seconds) {
			seconds = Number(seconds);
			var d = Math.floor(seconds / (3600 * 24));
			var h = Math.floor(seconds % (3600 * 24) / 3600);
			var m = Math.floor(seconds % 3600 / 60);
			var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
			var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
			var mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";
			return (dDisplay + hDisplay + mDisplay).trimEnd(', ');
		}
	});
});