define("storeplay-admin/helpers/tables/sort-class", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sortClass = sortClass;


  // Usage:
  // {{sort-class thisColumnName currentSortColumn currentSortDirection}}

  // Will return the appropriate class, that is 
  // If we are sorting this column and we are ascending -> "ascending"
  // If we are sorting this column and we are descending -> "descending"
  // If we are not sorting this column -> "none"

  function sortClass(params) {
    var thisColumnName = params[0];
    var currentSortColumn = params[1];
    var currentSortDirection = params[2];

    // If we clicked on a column without a sorter,
    // we want to just ignore it.
    if (thisColumnName == null) {
      return "none";
    }

    if (thisColumnName == currentSortColumn) {
      return currentSortDirection;
    } else {
      return "none";
    }
  }

  exports.default = Ember.Helper.helper(sortClass);
});