define('storeplay-admin/components/forms/admin-playlist-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),
		router: Ember.inject.service(),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		playlist: null,
		playlistClient: null,
		playlistSongsCount: null,
		playlistSongsDuration: null,
		availableFrom: null,
		availableTo: null,
		spotifyPlaylistUrl: null,

		init: function init(args) {
			this._super(args);
			this.set('availableFrom', this.get('playlist').availableFromLocal);
			this.set('availableTo', this.get('playlist').availableToLocal);
			this.set('spotifyPlaylistUrl', this.get('playlist').spotifyPlaylistUrl);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			// Component was added to the DOM
			this.initialiseUI();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			// Component is about to be removed from the DOM
		},

		didRender: function didRender() {
			this._super.apply(this, arguments);
			this.initialiseUI();
		},

		// Updating the UI
		// ###############
		initialiseUI: function initialiseUI() {

			$('#fromDateTimePicker').datetimepicker({
				format: 'DD/MM/YYYY h:mm A'
			});

			$('#fromDateTimePickerClear').on('click', function () {
				$('#fromDateTimePicker').datetimepicker('clear');
			});

			$('#toDateTimePicker').datetimepicker({
				format: 'DD/MM/YYYY h:mm A'
			});

			$('#toDateTimePickerClear').on('click', function () {
				$('#toDateTimePicker').datetimepicker('clear');
			});
		},

		actions: {

			save: function save() {
				var _this = this;
				var errors = this.performValidation();

				if (errors.length != 0) {
					var errorMessage = errors.join("<br />");
					this.get('applicationController').displayModal({ title: "Validation Errors", message: errorMessage });
				} else {
					var playlist = this.get('playlist');
					var availableFrom = this.get('availableFrom');
					var availableTo = this.get('availableTo');
					var spotifyPlaylistUrl = this.get('spotifyPlaylistUrl');
					var user = this.get('currentUser');
					if (availableFrom) {
						// toISOString(true) makes the time local time not utc.
						playlist.availableFrom = moment.tz(availableFrom, 'DD/MM/YYYY h:mm A', "Australia/Melbourne").toISOString(true);
					} else {
						playlist.availableFrom = null;
					}

					if (availableTo != null) {
						// toISOString(true) makes the time local time not utc.
						playlist.availableTo = moment.tz(availableTo, 'DD/MM/YYYY h:mm A', "Australia/Melbourne").toISOString(true);
					} else {
						playlist.availableTo = null;
					}
					playlist.spotifyPlaylistUrl = spotifyPlaylistUrl;

					// There were no errors. We can save the playlist.
					this.get('apiClient').rpc('updatePlaylist', {
						playlist: playlist
					}, user.adminToken).then(function (response) {
						if (response.error) {
							console.log('API error: %o', response.error);
							_this.get('applicationController').displayModal({
								title: 'Sorry, we could not save the playlist.',
								message: response.error && response.error.message ? response.error.message : "An unexpected error occurred."
							});
							return;
						}

						_this.get('applicationController').displayModal({ title: "Success!", message: "Playlist saved successfully!", primaryButtonFunction: function primaryButtonFunction() {
								window.location.reload(true);
							} });
					}).catch(function (errorObject) {
						console.log('errorObject : %o', errorObject);
						_this.get('applicationController').displayModal({
							title: 'Sorry, we could not save the playlist.',
							message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
						});
					});
				}
			},
			delete: function _delete(playlist) {
				var _this2 = this;

				var _this = this;
				var user = this.get("currentUser");
				_this.get('applicationController').displayModal({
					title: 'Are you sure you want to delete this playlist?',
					primaryButtonLabel: 'Yes, delete',
					primaryButtonFunction: function primaryButtonFunction() {

						_this2.get('apiClient').rpc('deletePlaylist', { playlistID: playlist.id }, user.adminToken).then(function (response) {
							if (response && response.error) {
								console.log('API error: %o', response.error);
								_this.get('applicationController').displayModal({
									title: 'Sorry, we could not delete the playlist.',
									message: response.error && response.error.message ? response.error.message : "An unexpected error occurred."
								});
								return;
							} else {
								_this.get('applicationController').displayModal({
									title: "Success!",
									message: 'The playlist has been deleted.',
									primaryButtonFunction: function primaryButtonFunction() {
										_this.get('router').transitionTo("authenticated.admin-playlists");
									}
								});
							}
						}).catch(function (errorObject) {
							console.log('errorObject : %o', errorObject);
							_this.get('applicationController').displayModal({
								title: 'Sorry, we could not delete the playlist.',
								message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
							});
						});
					},
					secondaryButtonLabel: 'Cancel'
				});
			},
			back: function back() {
				this.get('router').transitionTo("authenticated.admin-playlists");
			}
		},

		performValidation: function performValidation() {

			var errors = [];

			// Validate dates if required.
			if (this.availableFrom && this.availableTo) {
				var fromDate = moment.tz(this.availableFrom, 'DD/MM/YYYY h:mm A', "Australia/Melbourne");
				var toDate = moment.tz(this.availableTo, 'DD/MM/YYYY h:mm A', "Australia/Melbourne");
				if (toDate <= fromDate) {
					errors.push("\'Available To\' must be a later date than \'Available From\'.");
				}
			}
			var playlist = this.get('playlist');
			if (playlist.isRecentSongsEnabled && (playlist.recentSongCount == 0 || playlist.recentSongCount == null || playlist.recentSongFrequency == 0 || playlist.recentSongFrequency == null)) {
				errors.push("The recent songs boost feature must have count and frequency parameters when enabled.");
			}

			return errors;
		}
	});
});