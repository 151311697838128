define("storeplay-admin/components/tables/offer-table", ["exports", "storeplay-admin/components/sortable-table/sortable-table-base"], function (exports, _sortableTableBase) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);
			this.set("allRows", []);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Since we are going to add filtering to this table, we will
			// keep track of the initial set of rows that were given to the
			// table, and then simply update the rows that are visible,
			// with the filtered array.
			var allRows = this.get("rows");
			this.set("allRows", allRows);

			// Then do the initial filter. (One of the filters will be selected by default.)
			this.filterRows();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [{ title: "", sorter: null, width: "90px" }, // Offer Image Column
		{ title: "Client", sorter: "client", width: "10%", adminOnly: true }, { title: "Title", sorter: "title", width: "15%" }, { title: "Description", sorter: "description" }, { title: "Start Time", sorter: "startTime", width: "12%" }, { title: "End Time", sorter: "endTime", width: "12%" }, { title: "", sorter: null, width: "50px" }],

		sorters: {
			client: function client(leftOffer, rightOffer) {
				return this.orderOf(leftOffer.client.brandName.toLowerCase(), rightOffer.client.brandName.toLowerCase());
			},

			title: function title(leftOffer, rightOffer) {
				return this.orderOf(leftOffer.title.toLowerCase(), rightOffer.title.toLowerCase());
			},

			description: function description(leftOffer, rightOffer) {
				return this.orderOf(leftOffer.description.toLowerCase(), rightOffer.description.toLowerCase());
			},

			startTime: function startTime(leftOffer, rightOffer) {
				var leftStartDateTimeString = leftOffer.get('startDateTime');
				var leftStartDateTime = leftStartDateTimeString ? moment(leftStartDateTimeString, "DD/MM/YYYY h:mm A") : null;
				var rightStartDateTimeString = rightOffer.get('startDateTime');
				var rightStartDateTime = rightStartDateTimeString ? moment(rightStartDateTimeString, "DD/MM/YYYY h:mm A") : null;

				return this.orderOf(leftStartDateTime, rightStartDateTime);
			},

			endTime: function endTime(leftOffer, rightOffer) {
				var leftEndDateTimeString = leftOffer.get('endDateTime');
				var leftEndDateTime = leftEndDateTimeString ? moment(leftEndDateTimeString, "DD/MM/YYYY h:mm A") : null;
				var rightEndDateTimeString = rightOffer.get('endDateTime');
				var rightEndDateTime = rightEndDateTimeString ? moment(rightEndDateTimeString, "DD/MM/YYYY h:mm A") : null;

				return this.orderOf(leftEndDateTime, rightEndDateTime);
			}
		},

		// Offer Table Specific
		// ####################

		isAdmin: false,

		filterOptions: ["Active", "Upcoming", "Past", "All"],
		selectedFilter: "Active",

		actions: {
			onFilterChanged: function onFilterChanged(newFilter) {
				this.filterRows();
			}
		},

		filterRows: function filterRows() {
			var _this = this;
			var allRows = this.get("allRows");
			var filteredRows = allRows;

			var filter = this.filterers[this.selectedFilter.toLowerCase()];

			filteredRows = filteredRows.filter(function (offer) {
				var startDateTimeString = offer.get('startDateTime');
				var endDateTimeString = offer.get('endDateTime');
				var startDateTime = startDateTimeString ? moment(startDateTimeString, "DD/MM/YYYY h:mm A") : null;
				var endDateTime = endDateTimeString ? moment(endDateTimeString, "DD/MM/YYYY h:mm A") : null;
				return filter(startDateTime, endDateTime);
			});

			this.set("rows", filteredRows);
		},

		filterers: {
			active: function active(startDateTime, endDateTime) {
				// No start or end, always active.
				if (!startDateTime && !endDateTime) {
					return true;
				}

				// If we have a start time that is in the past
				// but no end time, we are active.
				if (startDateTime && !endDateTime) {
					return startDateTime.isBefore();
				}

				// If we have no start time, but we do
				// have an end time that is in the future, we are active.
				if (!startDateTime && endDateTime) {
					return endDateTime.isAfter();
				}

				// If we have a start time and an end time
				// start is before and end is after now, then we are active.
				if (startDateTime && endDateTime) {
					return startDateTime.isBefore() && endDateTime.isAfter();
				}
			},

			upcoming: function upcoming(startDateTime, endDateTime) {
				if (startDateTime) {
					return startDateTime.isAfter();
				}
				return false;
			},

			past: function past(startDateTime, endDateTime) {
				if (endDateTime) {
					return endDateTime.isBefore();
				}
				return false;
			},

			all: function all(startDateTime, endDateTime) {
				return true;
			}
		}

	});
});