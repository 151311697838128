define('storeplay-admin/components/sortable-table/sortable-table-base', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		rows: [],
		columns: [],

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			// Component has been added to the DOM.
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			// Component will be removed from the DOM.
		},

		// Sorting
		// #######

		selectedSortColumn: null,
		selectedSortDirection: "ascending",

		sortedRows: function () {
			var allRows = this.get('rows');

			if (allRows && allRows.length > 0) {
				var sort = this.get('selectedSortColumn');

				// If we actually have a selected column, 
				// then we want to sort.
				if (sort) {
					var sorter = this.sorters[sort].bind(this);
					return allRows.sort(sorter);
				}

				// Otherwise, just return all of the rows as they are.
				return allRows;
			} else {
				// Otherwise, we haven't been given any rows. 
				console.log("No rows have been given to the table. Returning empty array for table rows.");
				return [];
			}
		}.property('rows', 'selectedSortColumn', 'selectedSortDirection'),

		actions: {
			applySort: function applySort(newSortColumn, newSortDirection) {
				this.set('selectedSortDirection', newSortDirection);
				this.set('selectedSortColumn', newSortColumn);
			}
		},

		// orderOf: A simple helper that takes two values and compares them to find their sort order.
		// Returns: -1 if left should be before right.
		// 			1 if left should be after right
		// 			0 if order doesn't matter (they are 'equal').
		orderOf: function orderOf(left, right) {
			var order = 1;

			if (left == null) {
				// Move empty values to the end.
				// Ignores whether we are descending/ascending, always want 
				// to move them to the end.
				return 1;
			} else if (right == null) {
				// Same goes here.
				return -1;
			} else if (left < right) {
				order = -1;
			} else if (left > right) {
				order = 1;
			} else if (left == right) {
				order = 0;
			} else {
				order = 1;
			}

			var shouldReverse = this.get('selectedSortDirection') == "descending";
			return shouldReverse ? order * -1 : order;
		}
	});
});