define('storeplay-admin/components/tables/stores-devices-report-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base'], function (exports, _sortableTableBase) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		apiClient: Ember.inject.service("api-client"),
		currentUser: Ember.inject.service('current-user'),

		// Component Lifecycle
		// ###################

		isSuperAdmin: false,
		clientFilterID: null,
		clients: null,
		filteredRows: Ember.computed('clientFilterID', 'sortedRows', 'rows', 'selectedSortColumn', 'selectedSortDirection', 'isLoading', function () {
			var sortedRows = this.get('sortedRows');
			var rows = sortedRows ? sortedRows : this.get('rows');
			var clientFilterID = this.get('clientFilterID');
			if (rows && rows.length > 0 && clientFilterID) {
				rows = rows.filter(function (item) {
					return item.client.id == clientFilterID;
				});
			}
			return rows;
		}),
		allRowsCount: Ember.computed('rows', function () {
			return this.get('rows.length') || 0;
		}),
		init: function init(args) {
			this._super(args);
		},
		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
		},
		didUpdateAttrs: function didUpdateAttrs() {
			this._super.apply(this, arguments);
		},


		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
		},

		didRender: function didRender() {
			this._super.apply(this, arguments);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			// Component will be removed from the DOM. Can do any deinit.
		},

		// SortableTable Overrides
		// #######################
		columns: [{ title: "Client", sorter: "clientSorter", width: "150px" }, { title: "Store", sorter: "storeSorter", width: "250px" }, { title: "Status", sorter: "locationStatusSorter", width: "80px" }, { title: "Linked?", sorter: "linkedSorter", width: "100px" }, { title: "Device", sorter: "deviceSorter", width: "250px" }, { title: "Status", sorter: "deviceStatusSorter", width: "80px" }, { title: "Last Playback", sorter: "lastPlaybackSorter", width: "120px" }, { title: "", sorter: null, width: "50px" }],

		sorters: {
			clientSorter: function clientSorter(left, right) {
				return this.orderOf(left.get('store.client.internalName') || left.get('license.client.internalName'), right.get('store.client.internalName') || right.get('license.client.internalName'));
			},
			storeSorter: function storeSorter(left, right) {
				var leftName = null;
				var rightName = null;

				var nameExtractor = function nameExtractor(item) {
					if (item.store == null) {
						return null;
					}

					name = item.store.internalName && item.store.internalName.length > 0 ? item.store.internalName : item.store.publicName;
					if (name) {
						name = name.toLowerCase();
					}
					return name;
				};

				leftName = nameExtractor(left);
				rightName = nameExtractor(right);

				return this.orderOf(leftName, rightName);
			},

			locationStatusSorter: function locationStatusSorter(left, right) {
				// verified = 0;
				// guessed = 1;
				// not set = 2;

				var leftStatus = null;
				var rightStatus = null;

				var statusExtractor = function statusExtractor(item) {
					if (item.store == null) {
						return null;
					}

					if (item.get("storeCoordinate") == null) {
						return 2;
					} else {
						return item.store.latitudeLongitudeVerified ? 0 : 1;
					}
				};

				leftStatus = statusExtractor(left);
				rightStatus = statusExtractor(right);

				return this.orderOf(leftStatus, rightStatus);
			},

			linkedSorter: function linkedSorter(left, right) {
				// not linked = 0;
				// linked = 1;

				var leftLinked = left.store != null && left.license != null ? 1 : 0;
				var rightLinked = right.store != null && right.license != null ? 1 : 0;

				return this.orderOf(leftLinked, rightLinked);
			},

			deviceSorter: function deviceSorter(left, right) {
				var leftDeviceName = null;
				var rightDeviceName = null;

				if (left.license) {
					leftDeviceName = left.license.device.name ? left.license.device.name : 'Device ' + left.license.device.id;
				}

				if (right.license) {
					rightDeviceName = right.license.device.name ? right.license.device.name : 'Device ' + right.license.device.id;
				}

				return this.orderOf(leftDeviceName, rightDeviceName);
			},

			deviceStatusSorter: function deviceStatusSorter(left, right) {
				// reporting-near-store = 0
				// reporting-far-from-store = 1
				// device-not-linked-to-store = 2
				// device-has-not-reported-location = 3
				var leftStatus = null;
				var rightStatus = null;

				var statusExtractor = function statusExtractor(item) {
					if (item.license == null) {
						return null;
					}

					if (item.get("deviceCoordinate") != null) {
						if (item.get("deviceIsNearStore")) {
							return 0;
						} else {
							// Device is reporting, but not close.
							if (item.get("linked")) {
								return 1;
							} else {
								return 2;
							}
						}
					} else {
						return 3;
					}
				};

				leftStatus = statusExtractor(left);
				rightStatus = statusExtractor(right);

				return this.orderOf(leftStatus, rightStatus);
			},

			connectSorter: function connectSorter(left, right) {
				var leftConnectStatus = false;
				var rightConnectStatus = false;

				if (left.store) {
					leftConnectStatus = left.store.jukeboxEnabled;
				}

				if (right.store) {
					rightConnectStatus = right.store.jukeboxEnabled;
				}

				return this.orderOf(leftConnectStatus, rightConnectStatus);
			},

			lastPlaybackSorter: function lastPlaybackSorter(left, right) {

				var leftLastPlaybackDate = null;
				var rightLastPlaybackDate = null;

				if (left.license && left.lastPlaybackTimestamp) {
					leftLastPlaybackDate = moment(left.lastPlaybackTimestamp, "YYYY-MM-DD HH:mm:ss");
				}

				if (right.license && right.lastPlaybackTimestamp) {
					rightLastPlaybackDate = moment(right.lastPlaybackTimestamp, "YYYY-MM-DD HH:mm:ss");
				}

				return this.orderOf(leftLastPlaybackDate, rightLastPlaybackDate);
			}
		},

		// Stores-devices-table specific
		// #############################
		actions: {

			setClientFilter: function setClientFilter(value) {
				this.set('clientFilterID', value);
			},

			downloadAllRowsAsCsv: function downloadAllRowsAsCsv(event) {
				var _this = this;

				var headers = "Store,Device,Address,State,Country,Latitude,Longitude,Last Online,Last Login,Device ID,Platform,App Version,Last Ping,Internal IP,External IP,MAC Address\n";
				var data = "";
				var dataRows = this.get("filteredRows");
				dataRows.forEach(function (element) {

					// store name
					if (element.store) {
						if (element.store.internalName) data += _this.createCsvTextField(element.store.internalName);else data += _this.createCsvTextField(element.store.publicName);
					} else {
						data += _this.createCsvField("N/A");
					}

					// device name
					if (element.license) {
						if (element.license.device && element.license.device.name) {
							data += _this.createCsvTextField(element.license.device.name);
						} else if (element.license.id) {
							data += _this.createCsvField("Device " + element.license.id);
						}
					} else {
						data += _this.createCsvField("N/A");
					}

					// address
					if (element.store && element.store.streetAddress1) {
						var address = element.store.streetAddress1;
						address += element.store.streetAddress2 != null ? ", " + element.store.streetAddress2 : "";
						data += _this.createCsvTextField(address);
					} else {
						data += _this.createCsvField("N/A");
					}

					// state
					if (element.store && element.store.state) {
						data += _this.createCsvField(element.store.state);
					} else {
						data += _this.createCsvField("N/A");
					}

					// country
					if (element.store && element.store.iso2CountryCode) {
						data += _this.createCsvField(element.store.iso2CountryCode);
					} else {
						data += _this.createCsvField("N/A");
					}

					// lat
					if (element.store && element.store.latitude) {
						data += _this.createCsvField(element.store.latitude);
					} else {
						data += _this.createCsvField("N/A");
					}

					// long
					if (element.store && element.store.longitude) {
						data += _this.createCsvField(element.store.longitude);
					} else {
						data += _this.createCsvField("N/A");
					}

					// last online
					if (element.lastPlaybackTimestamp) {
						data += _this.createCsvField(moment(element.lastPlaybackTimestamp).format("YYYY-MM-DD HH:mm:ss"));
					} else {
						data += _this.createCsvField("N/A");
					}

					// last login
					if (element.license && element.license.lastLogin && element.license.lastLogin.iso8601) {
						data += _this.createCsvField(moment(element.license.lastLogin.iso8601).format("YYYY-MM-DD HH:mm:ss"));
					} else {
						data += _this.createCsvField("N/A");
					}

					// device id
					if (element.license && element.license.device.udid) {
						data += _this.createCsvField(element.license.device.udid);
					} else {
						data += _this.createCsvField("N/A");
					}

					// platform
					if (element.license && element.license.device.platform) {
						data += _this.createCsvTextField(element.license.device.platform + " " + element.license.device.model + " " + element.license.device.operatingSystem + " " + element.license.device.operatingSystemVersion);
					} else {
						data += _this.createCsvField("N/A");
					}

					// app version
					if (element.license && element.license.device.appVersion) {
						data += _this.createCsvField(element.license.device.appVersion);
					} else {
						data += _this.createCsvField("N/A");
					}

					//console.log(element.license.device);
					// last ping
					if (element.license && element.license.device && element.license.device.extra && element.license.device.extra.lastPingTimestamp && element.license.device.extra.lastPingTimestamp.iso8601) {
						data += _this.createCsvField(moment(element.license.device.extra.lastPingTimestamp.iso8601).format("YYYY-MM-DD HH:mm:ss"));
					} else {
						data += _this.createCsvField("N/A");
					}

					// internal IP
					if (element.license && element.license.device && element.license.device.extra && element.license.device.extra.lastPingInternalIP) {
						data += _this.createCsvField(element.license.device.extra.lastPingInternalIP);
					} else {
						data += _this.createCsvField("N/A");
					}

					// external IP
					if (element.license && element.license.device && element.license.device.extra && element.license.device.extra.lastPingExternalIP) {
						data += _this.createCsvField(element.license.device.extra.lastPingExternalIP);
					} else {
						data += _this.createCsvField("N/A");
					}

					// mac address
					if (element.license && element.license.device && element.license.device.extra && element.license.device.extra.lastPingMACAddress) {
						data += _this.createCsvField(element.license.device.extra.lastPingMACAddress, true);
					} else {
						data += _this.createCsvField("N/A", true);
					}
				});
				var blob = new Blob([headers + data]);
				var downloadDate = moment().format('YYYY-MM-DD');;
				if (window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveBlob(blob, "store-device-summary_" + downloadDate + ".csv");
				} else {
					var anchor = window.document.createElement("a");
					var url = window.URL.createObjectURL(blob, {
						type: "text/csv"
					});
					anchor.href = url;
					anchor.download = "summary_" + downloadDate + ".csv";
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					window.URL.revokeObjectURL(url);
				}
				blob = null;
			}
		},

		createCsvField: function createCsvField(data, isLast) {
			if (data != null) return data.toString() + (isLast ? '\n' : ',');else return '' + (isLast ? '\n' : ',');
		},

		createCsvTextField: function createCsvTextField(text, isLast) {
			if (text != null) {
				var value = text.toString().trim();

				// escape any double quotes in value.
				value = value.replace('"', '"""');

				// surround value with double quotes if there is a comma
				if (value.indexOf(',') > -1) {
					value = '"' + value + '"';
				}

				// add new line if this is the last valuefor the row, or a comma if not.
				return value + (isLast ? '\n' : ',');
			} else {
				return 'N/A' + (isLast ? '\n' : ',');
			}
		}
	});
});