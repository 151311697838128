define('storeplay-admin/models/schedule-timeblock', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ScheduleTimeblock = Ember.Object.extend({
		id: null,
		days: null,
		scheduleID: null,
		day: null,
		startTime: null,
		endTime: null,
		songCount: null,
		tags: null,
		channelTags: [],
		filterTags: [],
		channelsLookup: null,
		startOffsetMinutes: null,
		startTimeText: Ember.computed('startOffsetMinutes', 'startTime', function () {
			var start = this.calculateTime(this.startOffsetMinutes + this.startTime);
			return this.formatTime(start);
		}),
		endTimeText: Ember.computed('startOffsetMinutes', 'endTime', function () {
			var end = this.calculateTime(this.startOffsetMinutes + this.endTime);
			return this.formatTime(end);
		}),
		dayName: Ember.computed('day', function () {
			var daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
			if (this.day != null) {
				return daysOfWeek[this.day];
			} else {
				return null;
			}
		}),

		tagChannelDataItems: Ember.computed('channelsLookup', 'channelTags.@each', 'filterTags.@each', function () {
			// Lets loop throu the tags now and set the tagNames array so the title of the timeblock will show.
			var tagDataItems = new Array();

			for (var i = 0; i < this.channelTags.length; i++) {
				var tag = this.channelTags[i];
				// If tag typ eis channel or playlist.
				if ((tag.tagType == 1 || tag.tagType == 4) && this.channelsLookup != null) {
					var tagDataItem = new Object();
					// if its a channel/playlist tag then get the name of the channel/playlist in the lookup set.
					var channel = this.channelsLookup.findBy('id', parseInt(tag.tagValue));
					if (channel) {
						tagDataItem.name = channel.name;
					} else {
						tagDataItem.name = 'Unavailable';
					}
					tagDataItem.tagType = tag.tagType;
					tagDataItem.tagValue = tag.tagValue;

					// channel/playlist
					tagDataItem.iconClass = "mdi mdi-music-note mdi-md";

					tagDataItem.showRemoveItemBtn = this.channelTags.length > 1 || this.filterTags.length >= 1;

					tagDataItems.push(tagDataItem);
				}
			}

			return tagDataItems;
		}),
		tagFilterDataItems: Ember.computed('filterTags.@each', 'channelTags.@each', function () {
			// Lets loop throu the tags now and set the tagNames array so the title of the timeblock will show.
			var tagDataItems = new Array();

			for (var i = 0; i < this.filterTags.length; i++) {

				var tag = this.filterTags[i];
				// If tag typ eis channel or playlist.
				if (tag.tagType == 2 || tag.tagType == 3) {
					var tagDataItem = new Object();
					// if it bpm or genre we can just use the value saved already in the tag value to help 
					tagDataItem.name = tag.tagValue;
					tagDataItem.tagType = tag.tagType;
					tagDataItem.tagValue = tag.tagValue;
					if (tag.tagType == 2) {
						// genre
						tagDataItem.iconClass = "mdi mdi-headset mdi-md";
					}
					if (tag.tagType == 3) {
						// bpm
						tagDataItem.iconClass = "fa fa-heart fa-md";
					}

					tagDataItem.showRemoveItemBtn = this.filterTags.length > 1 || this.channelTags.length >= 1;

					tagDataItems.push(tagDataItem);
				}
			}

			return tagDataItems;
		}),
		showRemoveItemBtn: Ember.computed('filterTags.@each', 'channelTags.@each', function () {
			return this.channelTags.length >= 1 || this.filterTags.length >= 1;
		}),
		title: Ember.computed('channelsLookup', 'channelTags.@each', 'filterTags.@each', function () {

			// Lets loop throu the tags now and set the tagNames array so the title of the timeblock will show.
			var tagNames = new Array();

			for (var i = 0; i < this.channelTags.length; i++) {
				var channelTag = this.channelTags[i];
				if (this.channelsLookup != null) {
					// if its a channel tag then get the name of the channel
					var channel = this.channelsLookup.findBy('id', parseInt(channelTag.tagValue));
					if (channel && tagNames.includes(channel.name) == false) {
						// Add only if not existing.
						tagNames.push(channel.name);
					}
				}
			}

			for (var _i = 0; _i < this.filterTags.length; _i++) {
				var filterTag = this.filterTags[_i];
				// if it bpm or genre we can just use the value saved already in the tag value to help 
				tagNames.push(filterTag.tagValue);
			}

			var desc = [];
			// channel txt
			if (tagNames != null) {
				if (tagNames.length == 1) desc.push(tagNames[0]);else if (tagNames.length == 2) desc.push('' + tagNames.join(' and '));else if (tagNames.length > 2) desc.push(tagNames[0] + ', ' + tagNames[1] + ' and ' + (tagNames.length - 2) + ' other' + (tagNames.length == 3 ? "" : "s"));
			}

			if (desc.length > 0) {
				return desc.join(' + <br />');
			} else {
				return "Default channels";
			}
		}),
		calculateTime: function calculateTime(minutes) {
			var now = new Date();
			now.setHours(0, 0, 0, 0); // reset hours of day to 0
			now.setMinutes(now.getMinutes() + minutes);
			return now;
		},
		formatTime: function formatTime(date) {
			var h = date.getHours();
			var m = date.getMinutes();
			return h + ":" + (m < 10 ? "0" + m : m) + (h < 12 ? " AM" : " PM");
		},
		toJSON: function toJSON() {
			var json = {};
			json["day"] = this.get("day");
			json["scheduleID"] = this.get("scheduleID");
			json["startTime"] = this.get("startTime");
			json["endTime"] = this.get("endTime");
			json["tags"] = this.get("tags");
			return json;
		}
	});

	ScheduleTimeblock.reopenClass({
		// Class properties/methods
		fromJSON: function fromJSON(jsonObj) {
			var scheduleTimeblock = this.create({
				id: jsonObj["id"] || null,
				scheduleID: jsonObj["scheduleID"] || null,
				day: jsonObj["day"],
				startTime: jsonObj["startTime"].toString() || null,
				endTime: jsonObj["endTime"].toString() || null,
				tags: jsonObj["tags"] || null,
				channelTags: jsonObj["tags"] ? jsonObj["tags"].filter(function (t) {
					return t.tagType == 1 || t.tagType == 4;
				}) : null,
				filterTags: jsonObj["tags"] ? jsonObj["tags"].filter(function (t) {
					return t.tagType == 2 || t.tagType == 3;
				}) : null,
				songCount: jsonObj["songCount"].toString() || null
			});
			return scheduleTimeblock;
		}
	});

	exports.default = ScheduleTimeblock;
});