define('storeplay-admin/components/forms/ar-experience-form', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service("current-user"),
		apiClient: Ember.inject.service("api-client"),
		ajax: Ember.inject.service(),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		init: function init(args) {
			this._super(args);

			// The component has been created in memory
			this.set("typeOptions", ["Video", "3D Model"]);
			this.set("currentType", "Video");
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component was added to the DOM
			this.initialiseUI();

			// Start fetching the health asynchronously once we've been added to the DOM.
			var arExperienceID = this.get("arExperience.id");
			if (arExperienceID) {
				this.fetchArExperienceHealth(arExperienceID);
			}
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		didUpdateAttrs: function didUpdateAttrs() {
			console.log('didUpdateAttrs');
		},

		actions: {
			onTriggerImageDropped: function onTriggerImageDropped(file) {
				var imageFile = file.dataTransfer.files[0];
				this.handleNewTriggerImage(imageFile);
			},

			onTriggerImageSelected: function onTriggerImageSelected(file) {
				var imageFile = file.blob;
				this.handleNewTriggerImage(imageFile);
			},

			onTypeChanged: function onTypeChanged(file) {
				// TODO
			},

			// Buttons

			cancel: function cancel() {
				this.backToARExperiences();
			},

			delete: function _delete() {
				var _this2 = this;

				var _this = this;

				_this.get('applicationController').displayModal({
					title: 'Are you sure you want to delete this AR experience?',
					primaryButtonLabel: 'Yes, delete',
					primaryButtonFunction: function primaryButtonFunction() {

						var experience = _this2.get("arExperience");
						_this.deleteARExperience(experience).then(function () {
							_this.get('applicationController').displayModal({
								title: 'The AR experience has been deleted.',
								primaryButtonFunction: function primaryButtonFunction() {
									_this.backToARExperiences();
								}
							});
						}).catch(function (error) {
							console.log(error);
							s_this.get('applicationController').displayModal({ title: "Error", message: "Sorry there was a problem deleting the AR experience." });
						});
					},
					secondaryButtonLabel: 'Cancel'
				});
			},

			save: function save(shouldStayOnPage) {

				var _this = this;
				var experience = this.get("arExperience");

				var errors = this.validateExperience(experience);

				if (errors.length != 0) {
					_this.presentErrors(errors);
				} else {

					_this.get('applicationController').displayModal({
						title: "Updating...",
						message: "Saving your AR experience now. This may take a moment...",
						isLoading: true
					});

					var experienceTriggerImageFile = _this.get('experienceTriggerImageFile');

					_this.saveARExperience(experience).then(function (response) {
						// console.log(response);
						var experienceID = response.id;
						return _this.uploadTriggerImage(experienceTriggerImageFile, experienceID);
					}).then(function () {
						_this.get('applicationController').displayModal({
							title: "Success",
							message: "Your AR experience has been saved successfully.",
							primaryButtonLabel: "OK",
							primaryButtonFunction: function primaryButtonFunction() {
								if (!shouldStayOnPage) {
									// We should go back to the experiences table.
									_this.backToARExperiences();
								}
							}
						});
					}).catch(function (error) {
						_this.get('applicationController').displayModal({ title: "Error", message: "Unable to save your AR experience. Please try again." });
						console.log(error);
					});
				}
			}
		},

		// Communicating with Server

		saveARExperience: function saveARExperience(experience) {
			var apiClient = this.get("apiClient");
			return apiClient.rpc('saveARExperience', {
				arExperience: experience.toJSON()
			});
		},

		deleteARExperience: function deleteARExperience(experience) {
			var apiClient = this.get('apiClient');
			return apiClient.rpc('deleteARExperience', {
				arExperienceID: experience.id
			});
		},

		uploadTriggerImage: function uploadTriggerImage(triggerImageFile, experienceID) {
			var _this = this;

			// If the user hasn't given us an image, just finish straight away.
			if (this.isEmpty(triggerImageFile) || this.isEmpty(experienceID) || experienceID == 0) {
				return new Promise(function (resolve, reject) {
					resolve();
				});
			}

			return new Promise(function (resolve, reject) {

				var apiClient = _this.get("apiClient");

				_this.readFileIntoBase64String(triggerImageFile, ["jpeg", "png"], function (base64Data) {

					apiClient.rpc('saveMediaBlob', {
						parentModelID: experienceID,
						mediaBlob: {
							type: 1, // ARExperience trigger
							contentType: triggerImageFile.type,
							data: base64Data
						}
					}).then(function (response) {
						resolve();
					}).catch(function (error) {
						reject(error);
					});
				});
			});
		},

		fetchArExperienceHealth: function fetchArExperienceHealth(arExperienceID) {
			var _this3 = this;

			var apiClient = this.get('apiClient');

			apiClient.rpc('getARExperienceHealth', {
				arExperienceID: arExperienceID
			}).then(function (arHealth) {
				arHealth.arProcessingStatus = arHealth.arProcessingStatus.charAt(0).toUpperCase() + arHealth.arProcessingStatus.slice(1); // Uppercase the first letter, since it comes down all lower case.
				_this3.set('arExperienceHealth', arHealth);
			}).catch(function (error) {
				console.log('Failed to retrieve AR experience health: ' + error);
			});
		},


		// Updating UI
		// ###########

		initialiseUI: function initialiseUI() {
			// We use this with the image URL just to get around the caching, so updates to the trigger image appear instantly.
			this.set("randomNumber", Math.random());
		},

		handleNewTriggerImage: function handleNewTriggerImage(triggerImageFile) {
			var _this = this;

			// We need to validate the image, make sure it is a JPEG or PNG and within size constraints.
			this.validateExperienceTriggerImageFile(triggerImageFile, function (validated) {

				// Completion method for the logo validation. validated will equal true if everything was ok.
				if (validated) {
					// We need to save the image into memory so that later we can upload to the server when we hit save.
					_this.set("experienceTriggerImageFile", triggerImageFile);

					// We need to update the UI with a preview of what the image would look like.
					_this.showTriggerImage(triggerImageFile);
				}
			});
		},

		showTriggerImage: function showTriggerImage(triggerImageFile) {
			this.readFileIntoBase64String(triggerImageFile, ["jpeg", "png"], function (fileAsBase64String) {
				$("#trigger-image-preview").attr("src", fileAsBase64String);
			});
		},

		// Validation
		// ##########

		validateExperience: function validateExperience(experience) {

			var errors = [];

			// Make sure the fields aren't empty.
			var isEmptyChecks = {
				"name": { value: experience.name, message: "The name for an AR experience cannot be empty." },
				"videoURL": { value: experience.videoURL, message: "The content URL for an AR experience cannot be empty." }
			};

			for (var check in isEmptyChecks) {
				if (this.isEmpty(isEmptyChecks[check].value)) {
					errors.push(isEmptyChecks[check].message);
				}
			}

			// If this is a new experience AND they haven't added an image, let them know.
			if (experience.id == 0 && this.get("experienceTriggerImageFile") == null) {
				errors.push("The trigger image for the AR experience cannot be empty.");
			}

			return errors;
		},

		validateExperienceTriggerImageFile: function validateExperienceTriggerImageFile(triggerImageFile, completion) {
			var _this = this;

			// We need to create an instance of an Image, so we can check the width and height.
			// We do this by creating a base64 string of the image, then setting that to the Image's src property.
			// Once the image has initialised, we can then check the width and height.

			this.readFileIntoImage(triggerImageFile, ["jpeg", "png"], function (image) {

				// Completion method for once we have read the new logo file into an Image object.
				if (image == null) {
					_this.get('applicationController').displayModal({ title: "Validation Error", message: "The file you have uploaded is not supported, please ensure the image is a JPEG or PNG file." });
					completion(false); // Call the validation completion method, false signifies that the image did not validate correctly.
					return;
				}

				completion(true);
			});
		},


		presentErrors: function presentErrors(errors) {
			var errorMessage = errors.join("<br />");
			this.get('applicationController').displayModal({ title: "Validation Error", message: errorMessage });
		},

		isEmpty: function isEmpty(value) {
			return value == null || value == "";
		},

		// File Helpers
		// ############

		// TODO: These helpers are used in three places. The offer-form, the ar-experience-form and the branding-form.backToOffers
		// These methods need to be promise-ised, then put into a helper service or something to make them available everywhere since
		// they are simple utilities.

		readFileIntoImage: function readFileIntoImage(file, allowedTypes, completion) {

			var isAllowedType = this.validateFileType(file, allowedTypes);

			if (!isAllowedType) {
				completion(null);
				return;
			}

			var reader = new FileReader();
			var image = new Image();

			reader.onload = function (e) {

				var fileAsBase64String = e.target.result;

				// Once the reader has given us the base64string, set the new image's src to 
				// that, then once the image has loaded we can pass it off to the completion handler.
				image.onload = function () {
					completion(image);
				};

				image.src = fileAsBase64String;
			};

			reader.onerror = function () {
				console.log("errored");
				completion(null);
			};

			reader.readAsDataURL(file);
		},


		readFileIntoBase64String: function readFileIntoBase64String(file, allowedTypes, completion) {

			var isAllowedType = this.validateFileType(file, allowedTypes);

			if (!isAllowedType) {
				completion(null);
				return;
			}

			var reader = new FileReader();

			reader.onload = function (e) {
				var fileAsBase64String = e.target.result;
				completion(fileAsBase64String);
			};

			reader.readAsDataURL(file);
		},

		validateFileType: function validateFileType(file, allowedTypes) {
			var isAllowedType = false;

			allowedTypes.forEach(function (type) {
				if (file["type"] == 'image/' + type) {
					isAllowedType = true;
				}
			});

			return isAllowedType;
		}
	});
});