define('storeplay-admin/models/geocoordinate', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Object.extend({
		latitude: null,
		longitude: null
	});
});