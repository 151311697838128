define('storeplay-admin/components/validated-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hasErrors: false,
    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      getErrors: function getErrors() {
        if (this.get('changeset').get('errors.firstObject.validation') == undefined) {
          this.set('hasErrors', false);
        } else {
          this.set('hasErrors', true);
        }
      }
    },
    input: function input() {
      this.get('changeset').set(this.get('propertyName'), event.target.value);
    }
  });
});