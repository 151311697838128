define('storeplay-admin/components/controls/song-player-view', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		utility: Ember.inject.service("utility"),
		audioPlayer: Ember.inject.service("storeplay-audio-player"),

		song: null,
		showArtist: true,
		isPlaying: false,
		rightButtonText: null,
		rightButtonAction: null,

		init: function init(args) {
			this._super(args);

			// Register for audio player events.
			this.get("audioPlayer").addAudioEventObserver(this);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component has now been added to the dom and ready to be manipulated.
			// Anything to do here?
		},

		willDestroyElement: function willDestroyElement() {
			// Unregister for audio player events.
			this.get("audioPlayer").removeAudioEventObserver(this);
		},

		actions: {
			play: function play(event) {
				this.get("audioPlayer").playSong(this.song);
			},

			stop: function stop() {
				this.get("audioPlayer").stop();
			},

			seek: function seek(clickEvent) {

				// Find the x-location of the trackbar on the screen.
				var xPositionOfTrackbar = Ember.$("#" + this.elementId).offset().left;

				// Find the x-location of the click relative to the trackbar.
				var xClickLocationInTrackbar = clickEvent.pageX - xPositionOfTrackbar;

				// Find how far through the trackbar we clicked, in the range of 0,1
				var widthOfTrackbar = Ember.$("#" + this.elementId).width();
				var normalisedSeekPosition = xClickLocationInTrackbar / widthOfTrackbar;

				// Seek to that position.
				var seekPosition = Math.floor(this.song.duration * normalisedSeekPosition);
				this.get('audioPlayer').seek(seekPosition);
			}
		},

		// AudioEventObserver
		// ##################

		songBuffered: function songBuffered(song, bufferedRange) {
			if (song.id != this.song.id) {
				return;
			}

			this.updateBufferedBar(song.duration, bufferedRange);
		},

		songStarted: function songStarted(song) {
			if (song.id != this.song.id) {
				return;
			}

			this.set("isPlaying", true);
			this.showTrackBar();
		},

		songTicked: function songTicked(song, position) {
			if (song.id != this.song.id) {
				return;
			}

			this.updateTrackBar(song.duration, position);
		},

		songSeeked: function songSeeked(song, position) {
			if (song.id != this.song.id) {
				return;
			}

			this.updateTrackBar(song.duration, position);
		},

		songStopped: function songStopped(song) {
			if (song.id != this.song.id) {
				return;
			}

			this.set("isPlaying", false);
			this.hideTrackBar();
		},

		songEnded: function songEnded(song) {
			if (song.id != this.song.id) {
				return;
			}

			this.set("isPlaying", false);
			this.hideTrackBar();
		},

		getObserverID: function getObserverID() {
			return this.song.id;
		},

		// Updating User Interface
		// #######################

		showTrackBar: function showTrackBar() {
			console.log("Showing trackbar for song: " + this.song.id);
			var containerID = "#song-id-" + this.song.id;
			this.updateTrackBar(this.song.duration, 0);

			Ember.$(containerID + " .trackbar").animate({
				height: 18
			}, 300, function () {});
		},

		hideTrackBar: function hideTrackBar() {
			console.log("Hiding trackbar for song: " + this.song.id);
			var containerID = "#song-id-" + this.song.id;

			Ember.$(containerID + " .trackbar").animate({
				height: 0
			}, 300, function () {});
		},

		updateTrackBar: function updateTrackBar(totalDuration, currentPosition) {
			var containerID = "#song-id-" + this.song.id;

			var fractionThroughSong = currentPosition / totalDuration;
			var widthPercentage = fractionThroughSong * 100;
			Ember.$(containerID + " .trackinglayer").css("width", Math.min(widthPercentage, 100) + "%");

			// Will give us a nicely formatted string like "0:37 / 3:21"
			var trackbarText = this.get("utility").audio.generateCurrentPlaytime(totalDuration, Math.floor(currentPosition));
			Ember.$(containerID + " .trackbar p").text(trackbarText);
		},
		updateBufferedBar: function updateBufferedBar(totalDuration, bufferedRange) {
			var containerID = "#song-id-" + this.song.id;

			var fractionOfEndThroughSong = bufferedRange.end / totalDuration;
			var widthPercentage = fractionOfEndThroughSong * 100;
			Ember.$(containerID + " .bufferedlayer").css("width", Math.min(widthPercentage, 100) + "%");
		}
	});
});