define('storeplay-admin/validations/region', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var region = Ember.Object.extend({
		name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 5 }), (0, _validators.validateLength)({ max: 100 })],
		latitude: [(0, _validators.validatePresence)(true)],
		longitude: [(0, _validators.validatePresence)(true)],
		radius: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })]
	});

	exports.default = region;
});