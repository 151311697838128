define('storeplay-admin/models/geo-fence', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var geoFence = Ember.Object.extend({
		id: null,
		name: null,
		latitude: null,
		longitude: null,
		radius: null,

		toJSON: function toJSON() {
			var json = {};
			json["id"] = this.get('id');
			json["name"] = this.get('name');
			json["latitude"] = this.get('latitude');
			json["longitude"] = this.get('longitude');
			json["radius"] = this.get('radius');
			return json;
		}
	});

	geoFence.reopenClass({
		fromJSON: function fromJSON(geoFenceObject) {
			var geoFence = this.create({
				id: geoFenceObject["id"] || 0,
				name: geoFenceObject["name"] || null,
				latitude: geoFenceObject["latitude"] || null,
				longitude: geoFenceObject["longitude"] || null,
				radius: geoFenceObject["radius"] || null
			});
			return geoFence;
		}
	});

	exports.default = geoFence;
});