define('storeplay-admin/helpers/format-currency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCurrency = formatCurrency;
  function formatCurrency(value) {
    var dollars = Math.floor(value / 100).toLocaleString();
    var cents = (value % 100).toFixed(0);
    var sign = '$';

    if (cents.toString().length === 1) {
      cents = '0' + cents;
    }
    return '' + sign + dollars + '.' + cents;
  }

  exports.default = Ember.Helper.helper(formatCurrency);
});