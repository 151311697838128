define('storeplay-admin/components/tables/reports-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base', 'storeplay-admin/config/environment'], function (exports, _sortableTableBase, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);

			this.set("allRows", []);
		},
		authToken: null,
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [{ title: "Filename", sorter: "fileName", width: "250px" }, { title: "Download", sorter: null, width: "70px" }],

		sorters: {
			fileName: function fileName(left, right) {
				return this.orderOf(left.fileName, right.fileName);
			}
		}
	});
});