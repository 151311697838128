define("storeplay-admin/components/forms/stores-devices/new-device-form", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service("current-user"),
		apiClient: Ember.inject.service("api-client"),
		utility: Ember.inject.service("utility"),

		applicationController: Ember.computed("", function () {
			return Ember.getOwner(this).lookup("controller:application");
		}),

		init: function init(args) {
			this._super(args);

			// The component has been created in memory

			// This form is used to create a new license.
			// We need to create a new object with the bare minimum of information
			// that will be used to create the new license.

			var clientID = this.get("currentUser.clientID");
			var license = { id: 0, clientID: clientID, device: { udid: null, name: null } };
			this.set("license", license);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component was added to the DOM
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		actions: {
			create: function create(shouldStayOnPage) {

				var _this = this;
				var license = this.get("license");

				// Since we are creating the license here, we will set the claimed 
				// time to the current time.
				var now = moment().toISOString();
				license.claimed = now;

				var errors = this.validateLicense(license);

				if (errors.length != 0) {
					_this.presentErrors(errors);
				} else {
					_this.get("applicationController").displayModal({
						title: "Updating...",
						message: "Adding your device now. This may take a moment...",
						isLoading: true
					});

					_this.createLicense(license).then(function (response) {
						var licenseID = response.id;
						_this.get("applicationController").displayModal({
							title: "Success",
							message: "Your device has been created successfully with ID: " + licenseID + ".",
							primaryButtonLabel: "OK",
							primaryButtonFunction: function primaryButtonFunction() {
								if (!shouldStayOnPage) {
									_this.back();
								}
							}
						});
					}).catch(function (error) {
						_this.get("applicationController").displayModal({ title: "Error", message: "Unable to create an entry for your device. Please try again." });
						console.log(error);
					});
				}
			}
		},

		// Saving/Persisting to Server
		// ###########################

		createLicense: function createLicense(license) {
			var apiClient = this.get("apiClient");
			return apiClient.rpc("saveLicense", {
				license: license
			});
		},

		// Validation
		// ##########

		validateLicense: function validateLicense(license) {

			var errors = [];
			var utility = this.get("utility");

			// Make sure the fields aren't empty.
			var isEmptyChecks = {
				"udid": { value: license.device.udid, message: "The UDID for a device cannot be empty." }
			};

			for (var check in isEmptyChecks) {
				if (utility.strings.isEmpty(isEmptyChecks[check].value)) {
					errors.push(isEmptyChecks[check].message);
				}
			}

			return errors;
		},

		presentErrors: function presentErrors(errors) {
			var errorMessage = errors.join("<br />");
			this.get("applicationController").displayModal({ title: "Validation Error", message: errorMessage });
		}

	});
});