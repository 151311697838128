define('storeplay-admin/components/dashboard/client-todo-panel', ['exports', 'storeplay-admin/models/client'], function (exports, _client) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),
		utility: Ember.inject.service("utility"),

		numberOfColumns: 6,
		tagName: "", // This ensures the component is not wrapped in an ember-view div, which is not needed for this component.

		isDeterminingCompletionStatus: true,

		uploadLogosComplete: false,
		addStoresComplete: false,
		linkStoresAndDevicesComplete: false,
		addFacebookUsernameComplete: false,

		init: function init(args) {
			// The component has been created in memory
			this._super(args);
		},

		didInsertElement: function didInsertElement() {
			// Component was added to the DOM
			this._super.apply(this, arguments);

			var _this = this;
			setTimeout(function () {
				_this.updateCompletionStatus();
			}, 0);
		},

		willDestroyElement: function willDestroyElement() {
			// Component is about to be removed from the DOM
			this._super.apply(this, arguments);
		},

		actions: {},

		updateCompletionStatus: function updateCompletionStatus() {

			var _this = this;
			var currentUser = this.get("currentUser");
			var apiClient = this.get("apiClient");

			// TODO: For some reason, the 'client' object does not get updated properly
			// when switching the client that you are impersonating as an administrator.
			// Therefore the client.id property will be the ID of the previous client.
			// However, clientID on the currentUser service seems to be updated accordingly and will
			// be the correct ID.
			// let client = this.get("currentUser.client");
			var clientID = currentUser.clientID;

			var getClientCall = apiClient.rpc("getClient", { clientID: clientID, includeRelationships: [] });
			var allStoresCall = apiClient.rpc("getAllStoresForClient", { clientID: clientID });
			var allDevicesCall = apiClient.rpc("getAllLicensesForClient", { clientID: clientID });

			Promise.all([getClientCall, allStoresCall, allDevicesCall]).then(function (results) {
				var client = _client.default.fromJSON(results[0]);
				var stores = results[1];
				var devices = results[2];
				_this.determineCompletionStatus(client, stores, devices);
				_this.set("isDeterminingCompletionStatus", false);
			}).catch(function (error) {
				console.log(error);
				_this.set("isDeterminingCompletionStatus", false);
			});
		},

		determineCompletionStatus: function determineCompletionStatus(client, stores, devices) {
			// Check if the user has added logos and a facebook username.
			var uploadLogosComplete = client.lightBackgroundLogoID != null && client.darkBackgroundLogoID != null;
			var addFacebookUsernameComplete = client.facebookUsername != null;

			// The user has added a store if the number of stores is greater than 0.
			var addStoresComplete = stores.length > 0;

			// As long as one device has a store linked to it
			var linkStoresAndDevicesComplete = false;
			devices.forEach(function (device) {
				if (device.storeID != null) {
					linkStoresAndDevicesComplete = true;
				}
			});

			this.set("uploadLogosComplete", uploadLogosComplete);
			this.set("addFacebookUsernameComplete", addFacebookUsernameComplete);
			this.set("addStoresComplete", addStoresComplete);
			this.set("linkStoresAndDevicesComplete", linkStoresAndDevicesComplete);
		}

	});
});