define('storeplay-admin/components/forms/account-info-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		client: null,
		clientUser: null,
		blockAppPasswordReset: Ember.computed('client', function () {
			var user = this.get('currentUser');
			// if hasHeadlessDevices == true and we are not the suepr admin the password field is disabled
			return user.get('isAdminSuperUser') == false && this.get('client').hasHeadlessDevices;
		}),

		init: function init(args) {
			this._super(args);

			// The component has been created in memory
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component was added to the DOM
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		actions: {
			save: function save(client, clientUser) {
				var _this = this;

				var errors = this.performValidation(client, clientUser);
				console.log(errors);

				if (errors.length != 0) {
					var errorMessage = errors.join("<br />");
					this.get('applicationController').displayModal({ title: "Validation Error", message: errorMessage });
				} else {
					// There were no errors. We can save all the information.

					var saveClientCall = this.get('apiClient').rpc('saveClient', {
						client: client
					});

					var saveClientUserCall = this.get("apiClient").rpc("saveClientUser", {
						user: clientUser
					});

					var saveClientAdminPasswordCall = null;
					var saveClientPlayerAppPasswordCall = null;
					if (!this.isEmpty(clientUser.password)) {
						saveClientAdminPasswordCall = this.get('apiClient').rpc('saveClientAdminPassword', {
							clientID: clientUser.clientID,
							password: clientUser.password
						});
					}

					if (!this.isEmpty(clientUser.appPassword)) {
						saveClientPlayerAppPasswordCall = this.get('apiClient').rpc('saveClientPlayerAppPassword', {
							clientID: clientUser.clientID,
							password: clientUser.appPassword
						});
					}

					var allAPICalls = [];
					allAPICalls.push(saveClientCall);
					allAPICalls.push(saveClientUserCall);
					if (saveClientAdminPasswordCall != null) {
						allAPICalls.push(saveClientAdminPasswordCall);
					}
					if (saveClientPlayerAppPasswordCall != null) {
						allAPICalls.push(saveClientPlayerAppPasswordCall);
					}

					// TODO: Perform both calls after debugging why server is not accepting this.
					Promise.all(allAPICalls).then(function () {
						_this.get('applicationController').displayModal({ title: "Success!", message: "Details updated successfully!" });
						_this.get('currentUser').reload();
					}).catch(function (errorObject) {
						console.log('errorObject : %o', errorObject);
						_this.get('applicationController').displayModal({
							title: 'Sorry, we could not save your account details.',
							message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
						});
					});
				}
			}
		},

		performValidation: function performValidation(client, clientUser) {
			// Make sure the fields aren't empty.
			var isEmptyChecks = {
				"onlineUsername": { value: clientUser.username, message: "Your login username cannot be empty." },
				// "onlinePassword" : {value: clientUser.password, message: "Your login password cannot be empty."},

				"appUsername": { value: clientUser.appUsername, message: "Your login username for storeplay Connect cannot be empty." },
				// "appPassword" : {value: clientUser.appPassword, message: "Your login password for storeplay Connect cannot be empty."},

				"givenName": { value: clientUser.givenName, message: "Your given name cannot be empty." },
				"familyName": { value: clientUser.familyName, message: "Your family name cannot be empty." },

				"internalName": { value: client.internalName, message: "Your company name cannot be empty." },
				"emailAddress": { value: clientUser.emailAddress, message: "Your email address cannot be empty" },
				"phone": { value: clientUser.phone, message: "Your phone number cannot be empty." }
			};

			var errors = [];

			for (var check in isEmptyChecks) {
				if (this.isEmpty(isEmptyChecks[check].value)) {
					errors.push(isEmptyChecks[check].message);
				}
			}

			// If they have specified a password, make sure it is at least 8 characters long.
			if (clientUser.password && clientUser.password.length < 8) {
				errors.push("Your login password must be at least 8 characters long.");
			}

			if (clientUser.appPassword && clientUser.appPassword.length < 8) {
				errors.push("Your login password for storeplay Connect must be at least 8 characters long.");
			}

			return errors;
		},

		isEmpty: function isEmpty(value) {
			return value == null || value == "";
		}

	});
});