define('storeplay-admin/components/tables/client-playlist-subs-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base', 'storeplay-admin/config/environment'], function (exports, _sortableTableBase, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		linkToRoute: null,

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);
			this.set("apiBaseURL", _environment.default.apiBaseURL);
		},

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
		},
		didUpdateAttrs: function didUpdateAttrs() {
			this._super.apply(this, arguments);
		},


		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [{ title: "Client", sorter: "client", width: "100px" }, { title: "Playlist", sorter: "playlist", width: "150px" }, { title: "Channel", sorter: "channel", width: "100px" }, { title: "Added", sorter: "added", width: "100px" }, { title: "Removed", sorter: "removed", width: "100px" }, { title: "User", sorter: "user", width: "100px" }],

		sorters: {
			client: function client(left, right) {
				return this.orderOf(left.clientName.toLowerCase(), right.clientName.toLowerCase());
			},
			playlist: function playlist(left, right) {
				return this.orderOf(left.playlistName.toLowerCase(), right.playlistName.toLowerCase());
			},
			channel: function channel(left, right) {
				return this.orderOf(left.playlistCategoryName, right.playlistCategoryName);
			},
			added: function added(left, right) {
				return this.orderOf(left.added, right.added);
			},
			removed: function removed(left, right) {
				return this.orderOf(left.removed, right.removed);
			},
			user: function user(left, right) {
				return this.orderOf(left.userName, right.userName);
			}
		},

		// playlist-table specific
		// #####################
		actions: {
			filterByClient: function filterByClient(clientID) {
				console.log();
			}
		}
	});
});