define('storeplay-admin/services/storeplay-audio-player', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		// We use a single HTMLAudio instance.
		// HTMLAudio is just a simple wrapper around the HTMLAudioElement Web API to make things a little bit easier.
		audio: null,

		init: function init() {
			this._super.apply(this, arguments);

			var htmlAudio = HTMLAudio();
			htmlAudio.setOnBuffered(this.onSongBuffered.bind(this));
			htmlAudio.setOnTick(this.onSongTicked.bind(this));
			htmlAudio.setOnEnd(this.onSongEnded.bind(this));

			this.set("audio", htmlAudio);

			// TODO: Do all the initialisation here to ensure private/public separation of methods?
		},


		currentSong: null,

		// List of songs that are currently playing.
		// Once the current song is finished, it will move on to the next song.
		// Once all songs have been played, it will stop playing the audio.
		// The song at index 0 (if it exists, will always be the currently playing song.)
		// Calling "stop()", clears this list.
		currentPlaylist: [],
		currentSongIndex: null,

		// List of all observers who want to be notified about different audio events.
		// An observer must implement a certain set of methods, documented (todo: documentation)
		observers: [],

		// Observers
		// ########

		observerAlreadyExists: function observerAlreadyExists(observer) {
			var observerIndex = this.observers.findIndex(function (element) {

				// console.log("element: " + element.getObserverID());
				// console.log("observer: " + observer.getObserverID());

				return element.getObserverID() === observer.getObserverID();
			});

			// observerIndex will be -1 if it wasn't found in the observers array.
			// console.log(observerIndex);
			return observerIndex > -1;
		},

		addAudioEventObserver: function addAudioEventObserver(observer) {
			if (!this.observerAlreadyExists(observer)) {
				this.observers.push(observer);
			}
		},

		removeAudioEventObserver: function removeAudioEventObserver(observer) {
			if (this.observerAlreadyExists(observer)) {
				var indexOfObserver = this.observers.findIndex(function (element) {
					return element.getObserverID() == observer.getObserverID();
				});

				this.observers.splice(indexOfObserver, 1);
			}
		},

		// Song Events
		// ###########

		onSongBuffered: function onSongBuffered() {
			// Notify observers
			for (var i = 0; i < this.observers.length; i++) {
				this.observers[i].songBuffered(this.currentSong, this.audio.getBufferedRange());
			}
		},

		onSongStarted: function onSongStarted() {
			// Notify observers
			for (var i = 0; i < this.observers.length; i++) {
				this.observers[i].songStarted(this.currentSong);
			}
		},

		onSongTicked: function onSongTicked() {
			// Notify observers
			for (var i = 0; i < this.observers.length; i++) {
				this.observers[i].songTicked(this.currentSong, this.audio.getCurrentTime());
			}
		},

		onSongSeeked: function onSongSeeked() {
			// Notify observers
			for (var i = 0; i < this.observers.length; i++) {
				this.observers[i].songSeeked(this.currentSong, this.audio.getCurrentTime());
			}
		},

		// This is for when a song is stopped /manually/.
		// For example, when starting a new song, when pressing stop, when starting a new playlist, etc.
		// Basically, if a song was interrupted, this event will be sent out to all registered listeners.
		onSongStopped: function onSongStopped(songThatWasStopped) {
			console.log("onSongStopped");

			// Notify observers
			for (var i = 0; i < this.observers.length; i++) {
				this.observers[i].songStopped(songThatWasStopped);
			}

			// Play the next song if there is one.
			if (this.nextSongExistsInCurrentPlaylist) {
				this.next();
			}
		},

		// This will become onSongEnded, for when songs reach their end /automatically/. 
		// Is called when the song simply has nothing left to play.
		onSongEnded: function onSongEnded() {
			console.log("onSongEnded");

			var songThatEnded = this.currentSong;
			this.currentSong = null;

			// Notify observers
			for (var i = 0; i < this.observers.length; i++) {
				this.observers[i].songEnded(songThatEnded);
			}

			// Play the next song if there is one.
			if (this.nextSongExistsInCurrentPlaylist) {
				this.next();
			}
		},

		// Public Methods - Safe to call externally.
		// #########################################

		// Clears the currently-playing playlist and plays only this song.
		playSong: function playSong(song) {
			// Attempt to start playing the song...
			this.currentPlaylist = [song];
			this.currentSongIndex = 0;

			this.playSongAtIndex(this.currentSongIndex);
		},

		// Clears the currently-playing playlist and plays this new playlist instead.
		playSongs: function playSongs(playlist) {
			// Attempt to play the new playlist...
			this.currentPlaylist = playlist;
			this.currentSongIndex = 0;

			this.playSongAtIndex(this.currentSongIndex);
		},

		// TODO:
		// Appends a song to the currently-playing playlist.
		// playSongNext: function(song) {
		// 	// Two cases:
		// 	// 1: Called when playlist is empty.
		// 	if(this.currentPlaylist.length == 0) {
		// 		this.playSong(song)
		// 	}

		// 	// 2: Called when there are songs in playlist.
		// 	else {
		// 		this.currentPlaylist.push(song);
		// 	}
		// },

		// TODO:
		// // Appends a new list of songs to the currently-playing playlist.
		// playSongsNext: function(song) {

		// },

		next: function next() {
			if (this.nextSongExistsInCurrentPlaylist()) {
				this.currentSongIndex += 1;
				this.playSongAtIndex(this.currentSongIndex);
			}
		},

		previous: function previous() {
			if (this.previousSongExistsInCurrentPlaylist()) {
				this.currentSongIndex -= 1;
				this.playSongAtIndex(this.currentSongIndex);
			}
		},

		// Stops playing the current song and clears the current playlist
		// TODO: Perhaps make this a "stopAndClear" and add a separate "pause" event/function.
		// This will require a bit of thinking and adding. We only currently need "play and stop".
		stop: function stop() {
			if (this.currentSong) {
				var songThatWasStopped = this.currentSong;

				this.stopAudio();
				this.currentSong = null;
				this.currentPlaylist = [];
				this.currentSongIndex = null;

				this.onSongStopped(songThatWasStopped);
			}
		},

		// Seeks to a position (in seconds) in the current song.
		seek: function seek(position) {
			if (this.currentSong) {
				this.audio.seek(position);
				this.onSongSeeked();
			}
		},

		// Internal Helper Methods - Do not call these externally.
		// #######################################################

		nextSongExistsInCurrentPlaylist: function nextSongExistsInCurrentPlaylist() {
			if (this.currentPlaylist.length == 0 || this.currentSongIndex == null) {
				return false;
			}

			return this.currentSongIndex != this.currentPlaylist.length - 1;
		},

		previousSongExistsInCurrentPlaylist: function previousSongExistsInCurrentPlaylist() {
			if (this.currentPlaylist.length == 0 || this.currentSongIndex == null) {
				return false;
			}

			return this.currentSong.length > 1 && this.currentSongIndex > 0;
		},

		playSongAtIndex: function playSongAtIndex(index) {
			var isValidIndex = this.currentPlaylist.length > 0 && index <= this.currentPlaylist.length - 1;
			if (isValidIndex) {

				// Stop current song (if playing).
				if (this.currentSong) {
					var songThatWasStopped = this.currentSong;
					this.currentSong = null;
					this.stopAudio();
					this.onSongStopped(songThatWasStopped);
				}

				// Using this to ensure the stopAudio() call above
				// gets a chance to fire its events.
				setTimeout(function () {
					// Play the song at the specified index.
					var song = this.currentPlaylist[index];
					this.currentSong = song;
					this.startAudio(song.streamURL);
					this.onSongStarted();
				}.bind(this), 0);
			}
		},


		startAudio: function startAudio(url) {
			this.audio.setSource(url);
			this.audio.play();
		},

		stopAudio: function stopAudio() {
			this.audio.pause();
		}
	});


	var AudioState = Object.freeze({
		PREINIT: "preinit",
		READY: "ready",
		PLAYING: "playing",
		PAUSED: "paused",
		BUFFERING: "buffering",
		ENDED: "ended"
	});

	var HTMLAudio = function HTMLAudio(url, config) {

		var me = {};
		var sound = {}; // Holds our html audio element.

		var currentStatus = AudioState.PREINIT;

		var intervalTime = 1000; // The time between ticks. Defaults to 1 update per second.
		var intervalID = 0; // Store the current interval loop ID so we can cancel it.
		var bufferIntervalID = 0; // The currently buffered loop so we can see how much has buffered and display it.

		var hasBeenCancelled = false;

		// Audio Status

		// For tracking the status of an audio source.
		var currentStatus = AudioState.PREINIT;

		var onStatusChange = function onStatusChange() {
			console.log("Audio status in storeplay-audio-player changed to: " + currentStatus);
		};

		me.getStatus = function () {
			return currentStatus;
		};

		me.setStatus = function (newStatus) {
			if (newStatus) {
				currentStatus = newStatus;
				onStatusChange();
			}
		};

		me.setOnStatusChange = function (callback) {
			onStatusChange = callback;
		};

		// Callbacks and Public Properties

		me.autoplay = false;
		me.startAt = 0.0;
		me.loop = false;
		me.buffered = { start: 0.0, end: 0.0 };

		// I want the update time to be consistent and variable, so I will deal with the 
		// ticks myself instead of relying on the HTML <audio> ontimeupdate which seems to
		// fire randomly.
		var onTick = [];
		me.setOnTick = function (callback) {
			onTick.push(callback);
		};

		// We want to be notified periodically that the buffered state has been updated.
		var onBuffered = [];
		me.setOnBuffered = function (callback) {
			onBuffered.push(callback);
		};

		me.setOnReady = function (callback) {
			sound.addEventListener("loadeddata", callback);
		};
		me.setOnPlay = function (callback) {
			sound.addEventListener("playing", callback);
		};
		me.setOnPause = function (callback) {
			sound.addEventListener("pause", callback);
		};
		me.setOnEnd = function (callback) {
			sound.addEventListener("ended", callback);
		};
		me.setOnSeeked = function (callback) {
			sound.addEventListener("seeked", callback);
		};

		// Controls for the audio.
		me.setSource = function (source) {
			console.log("Setting new source for storeplay-audio-player...");
			hasBeenCancelled = false;
			sound.src = source;
		};
		me.play = function () {
			sound.play();
		};
		me.pause = function () {
			// If we stop the song before it has started, we should make sure we don't actually play it anyway.
			var status = this.getStatus();
			if (status === AudioState.PREINIT || status === AudioState.READY) {
				hasBeenCancelled = true;
			}
			sound.pause();
		};
		me.seek = function (time) {
			sound.currentTime = time;
		};
		me.setSpeed = function (speed) {
			if (speed > 0.0) {
				sound.playbackRate = Math.min(speed, 2.0);
			}
		};

		// Info
		me.isReady = function () {
			return this.getStatus() === AudioState.READY;
		};
		me.isPlaying = function () {
			return this.getStatus() === AudioState.PLAYING;
		};
		me.getCurrentTime = function () {
			return sound.currentTime;
		};
		me.getDuration = function () {
			return sound.duration;
		};
		me.getBufferedRange = function () {
			return me.buffered;
		};

		// Initialisation
		var init = function init(url, config) {

			sound = new Audio(url);

			if (sound) {

				// Keep the current status updated.
				this.setOnReady(function () {
					me.setStatus(AudioState.READY);
				});
				this.setOnPlay(function () {
					me.setStatus(AudioState.PLAYING);
				});
				this.setOnPause(function () {
					me.setStatus(AudioState.PAUSED);
				});
				this.setOnEnd(function () {
					me.setStatus(AudioState.ENDED);
				});

				// CONFIGURATION

				// Config object can contain:
				//
				//	startAt: float,
				//	autoplay: bool,
				//	speed:  float,
				//	loop: bool,
				//	ticksPerSecond: int	
				if (config) {
					// Allow starting times to be specified.
					if (config.startAt) {
						this.startAt = config.startAt;
					}

					// Allow autoplay.
					if (config.autoplay) {
						this.setOnReady(function () {
							if (!hasBeenCancelled) {
								me.seek(me.startAt);
								me.play();
							}
						});
					} else {
						this.setOnReady(function () {
							if (me.startAt > 0) {
								me.seek(me.startAt);
							}
						});
					}

					// Allow looping.
					if (config.loop) {
						sound.loop = true;
					}

					// Configure the speed.
					if (config.speed) {
						this.setSpeed(config.speed);
					}

					// Updates per second.
					if (config.ticksPerSecond) {
						intervalTime = 1000 / config.ticksPerSecond;
					}
				}

				// Update LOOPS
				// Start our playing update loop, but only when the audio is playing.
				this.setOnPlay(function () {
					intervalID = setInterval(function () {
						for (var i = 0; i < onTick.length; i++) {
							onTick[i]();
						}
					}, intervalTime);
				});

				// Buffering update loop
				var updateBuffered = function updateBuffered() {
					var length = sound.buffered.length;
					if (length) {
						me.buffered.start = sound.buffered.start(length - 1);
						me.buffered.end = sound.buffered.end(length - 1);

						// Let everyone know that the buffered range was updated.
						for (var i = 0; i < onTick.length; i++) {
							onBuffered[i]();
						}

						// Stop updating the amount we have buffered if it has fully loaded.
						if (me.buffered.start === 0.0 && me.buffered.end >= me.getDuration()) {
							clearInterval(bufferIntervalID);
						}
					}
				};

				this.setOnReady(function () {
					if (!hasBeenCancelled) {
						bufferIntervalID = setInterval(updateBuffered, 333); // Thrice a second
					} else {
						clearInterval(intervalID);
						clearInterval(bufferIntervalID);
					}
				});

				this.setOnPause(function () {
					clearInterval(intervalID);
					clearInterval(bufferIntervalID);
				});

				this.setOnEnd(function () {
					clearInterval(intervalID);
					clearInterval(bufferIntervalID);
				});

				// If the sound is cached by the browser, then the onready doesn't get fired. (?)
				// Check the ready state here and then update the status.
				if (sound.readyState > 0) {
					// Fire the event manually.
					var event = new CustomEvent("loadeddata", {});
					sound.dispatchEvent(event);
				}
			}
			// Couldn't create an audio object.
			else {
					throw {
						name: "NoAudioError",
						message: "Unable to create the HTML audio object."
					};
				}
		};

		init.apply(me, [url, config]);

		return me;
	};
});