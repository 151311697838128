define("storeplay-admin/components/tables/preview-playlists-table", ["exports", "storeplay-admin/components/sortable-table/sortable-table-base"], function (exports, _sortableTableBase) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({
		columns: [{ title: "Name", sorter: "name" }, { title: "Available From", sorter: "availableFrom" }, { title: "", sorter: null, width: "105px" }],

		sorters: {
			name: function name(leftPlaylist, rightPlaylist) {
				return this.orderOf(leftPlaylist.name.toLowerCase(), rightPlaylist.name.toLowerCase());
			},

			availableFrom: function availableFrom(leftPlaylist, rightPlaylist) {
				return this.orderOf(leftPlaylist.availableFrom, rightPlaylist.availableFrom);
			}
		}
	});
});