define('storeplay-admin/controllers/reset-password', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		apiClient: Ember.inject.service('api-client'),
		queryParams: ['guid'],
		guid: null,
		isGuidValid: false,
		isSubmitted: false,
		errorMessage: null,

		actions: {
			resetPassword: function resetPassword() {
				var _this2 = this;

				var _this = this;
				var password = this.get('password');
				var passwordConfirm = this.get('passwordConfirm');
				var guid = this.get('guid');

				if (password != passwordConfirm) {
					this.set('errorMessage', 'Password and Password Confirm must match.');
					return;
				}

				// If they have specified a password, make sure it is at least 8 characters long.
				if (password.length < 8) {
					this.set('errorMessage', 'Your login password must be at least 8 characters long.');
					return;
				}

				if (guid == null) {
					this.set('errorMessage', 'An error occurred.');
					return;
				}

				var apiClient = this.get('apiClient');

				apiClient.rpc('useAdminPasswordReset', {
					guid: guid,
					newPassword: passwordConfirm
				}, null, true).then(function (result) {
					_this.set('errorMessage', null);
				}).catch(function (errorObject) {
					_this2.set('errorMessage', 'An error occurred. Please try again. If problems persist please contact storePlay directly.');
					console.log('errorObject : %o', errorObject);
				});
				this.set('isSubmitted', true);
			}
		}

	});
});