define("storeplay-admin/helpers/tables/sort-indicator", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sortIndicator = sortIndicator;
  function sortIndicator(params) {
    var thisColumnName = params[0];
    var currentSortColumn = params[1];
    var currentSortDirection = params[2];

    // We don't want any indicator when clicking on a column
    // that doesn't have a sorter.
    if (thisColumnName == null) {
      return "&nbsp";
    }

    if (thisColumnName == currentSortColumn) {
      var sortArrow = currentSortDirection == "ascending" ? "<i class='fa fa-chevron-up'></i>" : "<i class='fa fa-chevron-down'></i>";
      return sortArrow;
    } else {
      return "&nbsp;";
    }
  }

  exports.default = Ember.Helper.helper(sortIndicator);
});