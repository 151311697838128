define('storeplay-admin/components/forms/scheduling/settings-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service("current-user"),
		apiClient: Ember.inject.service("api-client"),
		ajax: Ember.inject.service(),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		minsInterval: 15,
		startTimes: null,
		endTimes: null,

		init: function init(args) {
			this._super(args);

			// The component has been created in memory
			this.startTimes = this.getTimeOptions();
			this.endTimes = this.getTimeOptions();
		},
		getTimeOptions: function getTimeOptions() {

			var options = [];
			var padded = function padded(n) {
				return n.toString().padStart(2, '0');
			};
			var interval = this.minsInterval;
			for (var i = 0; i <= 1440; i += interval) {
				var hours = Math.floor(i / 60);
				var minutes = i % 60;
				options.push({
					value: i,
					text: padded(hours) + ':' + padded(minutes)
				});
			}
			return options;
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		actions: {

			// Buttons

			cancel: function cancel() {
				this.backToScheduling();
			},

			save: function save() {

				var _this = this;
			}
		}

	});
});