define('storeplay-admin/components/tables/clients-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base', 'storeplay-admin/config/environment'], function (exports, _sortableTableBase, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);

			this.set("allRows", []);
			this.set("apiBaseURL", _environment.default.apiBaseURL);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// We pass all of the clients into the table.
			// We want to default to only clients that are Active.
			// So we save all the rows (as we want to switch back and forward
			// between active and archvied), then just filter out the archived ones
			var shouldDefaultToActive = this.archivedCurrentFilterOption == "Active";

			var allRows = this.get("rows");
			this.set("allRows", allRows);

			var filtered = allRows.filter(function (element) {
				return element.isArchivedInAdmin == !shouldDefaultToActive;
			});

			this.set("rows", filtered);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [
		//TODO: Confirm we need the logo. Causes issues when loading hundreds of logos at once. Server can crash (resizes a bunch).
		// {title: "", 			sorter: null, 				width:"100px"},
		{ title: "Company", sorter: "company", width: "150px" }, { title: "Brand Category", sorter: "brandCategory", width: "150px" }, { title: "Contact Details", sorter: "contact", width: "180px" }, { title: "Stores & Devices", sorter: "storesAndDevices", width: "170px" }, { title: "Type", sorter: "type", width: "150px" }],

		sorters: {
			company: function company(left, right) {
				return this.orderOf(left.internalName, right.internalName);
			},

			brandCategory: function brandCategory(left, right) {
				return this.orderOf(left.brandCategoryID, right.brandCategoryID);
			},

			contact: function contact(left, right) {
				// Sort by the last name of the contact.
				return this.orderOf(left.extra.contactLastName, right.extra.contactLastName);
			},

			storesAndDevices: function storesAndDevices(left, right) {
				// return this.orderOf(left.username.toLowerCase(), right.username.toLowerCase());
			},

			type: function type(left, right) {
				var leftType = left.type + (left.extra.subscriptionPlanName || "");
				var rightType = right.type + (right.extra.subscriptionPlanName || "");
				return this.orderOf(leftType, rightType);
			}
		},

		// Client-table specific
		// #####################

		currentSearch: null,

		archivedFilterOptions: ["Active", "Archived", "All"],
		archivedCurrentFilterOption: "Active",

		clientTypeFilterOptions: ["Independent", "Chain", "All"],
		clientTypeCurrentFilterOption: "All",

		filterRows: function filterRows() {
			var _this = this;
			var allRows = this.get("allRows");
			var filteredRows = allRows;

			if (this.archivedCurrentFilterOption != "All") {
				filteredRows = filteredRows.filter(function (element) {
					return element.isArchivedInAdmin == !(_this.archivedCurrentFilterOption == "Active");
				});
			}

			if (this.clientTypeCurrentFilterOption != "All") {
				filteredRows = filteredRows.filter(function (element) {
					var type = element.get('typeString');
					return type == _this.clientTypeCurrentFilterOption;
				});
			}

			// Filter whatever is left by what the user has entered in the search field.
			filteredRows = filteredRows.filter(function (element) {
				if (_this.currentSearch) {
					var internalName = element.internalName ? element.internalName.toLowerCase() : "";

					return internalName.includes(_this.currentSearch.toLowerCase());

					// TODO: username & email
					// var username = ...
					// var email = ...
					// return internalName.includes(currentSearch.toLowerCase()) || email.toLowerCase().includes(currentSearch.toLowerCase()) || username.toLowerCase().includes(currentSearch.toLowerCase());
				} else {
					return true;
				}
			});

			this.set("rows", filteredRows);
		},

		actions: {
			newChainClientButtonClicked: function newChainClientButtonClicked() {
				console.log("new chain client button clicked");
			},
			onArchivedFilterChanged: function onArchivedFilterChanged(newArchivedFilter) {
				this.filterRows();
			},
			onClientTypeFilterChanged: function onClientTypeFilterChanged(newClientTypeFilter) {
				this.filterRows();
			},
			onSearchInputChanged: function onSearchInputChanged(event) {
				var searchTerm = event.target.value;
				this.set('currentSearch', searchTerm);
				this.filterRows();
			},
			transitionToLogoBranding: function transitionToLogoBranding(clientID) {
				console.log('logo/branding: ' + clientID);
			},
			transitionToStoresDevices: function transitionToStoresDevices(clientID) {
				console.log('stores/devices: ' + clientID);
			}
		}
	});
});