define('storeplay-admin/routes/index', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
		session: Ember.inject.service(''),

		beforeModel: function beforeModel() {
			if (this.get('session.isAuthenticated')) {
				Ember.Logger.log('route/index.beforeModel - session is authenticated, going to dashboard.');
				this.transitionTo('authenticated');
			} else {
				this.transitionTo('login');
			}
		}
	});
});