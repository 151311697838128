define('storeplay-admin/models/playlist-category-grouping', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var PlaylistCategoryGrouping = Ember.Object.extend({
		categoryGrouping: null,
		categoryID: null,
		name: null,
		months: null
	});

	PlaylistCategoryGrouping.reopenClass({
		// Static Methods
	});

	exports.default = PlaylistCategoryGrouping;
});