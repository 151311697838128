define('storeplay-admin/models/client', ['exports', 'storeplay-admin/models/money'], function (exports, _money) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.ClientType = undefined;


	var client = Ember.Object.extend({
		id: null,
		lightBackgroundLogoID: null,
		darkBackgroundLogoID: null,
		internalName: null,
		brandName: null,
		type: null,
		maximumLicenses: null,
		isArchivedInAdmin: null,
		maximumUserCredentialsCacheDays: null,
		brandCategoryID: null,
		brandCategory: null,
		facebookUsername: null,
		advertisementFrequency: null,
		isAfterpayEnabled: null,
		qrCodeHexColour: null,
		isZipMoneyEnabled: null,
		isOpenpayEnabled: null,
		isOptedOutOfConnectApp: null,
		affiliateID: null,
		playlistPrice: null,
		enableWeeklyDeviceActivityEmail: null,
		nonPlayerLicenseCountAU: null,
		nonPlayerLicenseCountNZ: null,
		archetype: null,
		industry: null,
		isSchedulingEnabled: null,
		hasHeadlessDevices: null,
		allowAnonymousDJAccess: null,
		// Fee related properties
		oneMusicAUFeeID: null,
		ppcaFeeID: null,
		oneMusicNZFeeID: null,
		adminSupportEmail: null,
		isDemoClient: null,
		typeString: Ember.computed('type', function () {
			switch (this.get('type')) {
				case 1:
					return 'Chain';
				case 2:
					return 'Independent';
			}
			return this.get('type');
		}),

		toJSON: function toJSON() {
			var json = {};

			json["id"] = this.get("id");
			json["lightBackgroundLogoID"] = this.get("lightBackgroundLogoID");
			json["darkBackgroundLogoID"] = this.get("darkBackgroundLogoID");
			json["internalName"] = this.get("internalName");
			json["brandName"] = this.get("brandName");
			json["type"] = this.get("type");
			json["maximumLicenses"] = this.get("maximumLicenses");
			json["isArchivedInAdmin"] = this.get("isArchivedInAdmin");
			json["maximumUserCredentialsCacheDays"] = this.get("maximumUserCredentialsCacheDays");
			json["brandCategoryID"] = this.get("brandCategoryID");
			json["brandCategory"] = this.get("brandCategory");
			json["facebookUsername"] = this.get("facebookUsername");
			json["advertisementFrequency"] = this.get("advertisementFrequency");
			json["qrCodeHexColour"] = this.get("qrCodeHexColour");
			json["isAfterpayEnabled"] = this.get("isAfterpayEnabled");
			json["isZipMoneyEnabled"] = this.get("isZipMoneyEnabled");
			json["isOpenpayEnabled"] = this.get("isOpenpayEnabled");
			json["isOptedOutOfConnectApp"] = this.get("isOptedOutOfConnectApp");
			json["affiliateID"] = this.get("affiliateID");
			json["playlistPrice"] = this.get("playlistPrice") ? this.get('playlistPrice').toJSON() : null;
			json["enableWeeklyDeviceActivityEmail"] = this.get("enableWeeklyDeviceActivityEmail");

			json["nonPlayerLicenseCountAU"] = this.get("nonPlayerLicenseCountAU") || null;
			json["nonPlayerLicenseCountNZ"] = this.get("nonPlayerLicenseCountNZ") || null;

			json["archetype"] = this.get("archetype") || null;
			json["industry"] = this.get("industry") || null;

			json["isSchedulingEnabled"] = this.get("isSchedulingEnabled") || false;

			// Fee related properties
			json["oneMusicAUFeeID"] = this.get("oneMusicAUFeeID");
			json["ppcaFeeID"] = this.get("ppcaFeeID");
			json["oneMusicNZFeeID"] = this.get("oneMusicNZFeeID");

			json["adminSupportEmail"] = this.get("adminSupportEmail");
			json["allowAnonymousDJAccess"] = this.get("allowAnonymousDJAccess");
			json["isDemoClient"] = this.get("isDemoClient");

			return json;
		}
	});

	client.reopenClass({
		fromJSON: function fromJSON(clientObject) {
			var client = this.create({
				id: clientObject["id"] || null,
				lightBackgroundLogoID: clientObject["lightBackgroundLogoID"] || null,
				darkBackgroundLogoID: clientObject["darkBackgroundLogoID"] || null,
				internalName: clientObject["internalName"] || null,
				brandName: clientObject["brandName"] || null,
				type: clientObject["type"] || null,
				maximumLicenses: clientObject["maximumLicenses"],
				isArchivedInAdmin: clientObject["isArchivedInAdmin"],
				maximumUserCredentialsCacheDays: clientObject["maximumUserCredentialsCacheDays"],
				brandCategoryID: clientObject["brandCategoryID"] || null,
				brandCategory: clientObject["brandCategory"] || null,
				facebookUsername: clientObject["facebookUsername"] || null,
				advertisementFrequency: clientObject["advertisementFrequency"],
				qrCodeHexColour: clientObject["qrCodeHexColour"],
				isAfterpayEnabled: clientObject["isAfterpayEnabled"],
				isZipMoneyEnabled: clientObject["isZipMoneyEnabled"],
				isOpenpayEnabled: clientObject["isOpenpayEnabled"],
				isOptedOutOfConnectApp: clientObject["isOptedOutOfConnectApp"],
				affiliateID: clientObject["affiliateID"],
				playlistPrice: clientObject["playlistPrice"] ? _money.default.fromJSON(clientObject["playlistPrice"]) : null,
				enableWeeklyDeviceActivityEmail: clientObject["enableWeeklyDeviceActivityEmail"],
				nonPlayerLicenseCountAU: clientObject["nonPlayerLicenseCountAU"],
				nonPlayerLicenseCountNZ: clientObject["nonPlayerLicenseCountNZ"],
				archetype: clientObject["archetype"],
				industry: clientObject["industry"],
				isSchedulingEnabled: clientObject["isSchedulingEnabled"],
				hasHeadlessDevices: clientObject["hasHeadlessDevices"] || null,
				allowAnonymousDJAccess: clientObject["allowAnonymousDJAccess"],
				// Fee related properties
				oneMusicAUFeeID: clientObject["oneMusicAUFeeID"] || null,
				ppcaFeeID: clientObject["ppcaFeeID"] || null,
				oneMusicNZFeeID: clientObject["oneMusicNZFeeID"] || null,
				adminSupportEmail: clientObject["adminSupportEmail"] || null,
				isDemoClient: clientObject["isDemoClient"]

			});
			return client;
		}
	});

	var ClientType = Object.freeze({
		/// Chain client. Has their subscription, costs and playlists selected individually by storeplay staff.
		chain: 1,

		/// Independent client. Signs up normally on the website and chooses channels manually.
		independent: 2
	});

	exports.default = client;
	exports.ClientType = ClientType;
});