define('storeplay-admin/components/tables/resellers-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base', 'storeplay-admin/config/environment'], function (exports, _sortableTableBase, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		// Component Lifecycle
		// ###################

		init: function init(args) {
			this._super(args);

			this.set("allRows", []);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Doing this to allow filtering in the table.
			var allRows = this.get("rows");
			this.set("allRows", allRows);
			this.set("rows", allRows);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
		},

		// SortableTable Overrides
		// #######################

		columns: [{ title: "ID", sorter: "id", width: "120px" }, { title: "Organisation", sorter: "org", width: "100px" }, { title: "Name", sorter: "name", width: "100px" }, { title: "Email", sorter: "email", width: "100px" }, { title: "Reward", sorter: "reward", width: "100px" }, { title: "Code", sorter: "code", width: "100px" }, { title: "", sorter: null, width: "50px" }],

		sorters: {
			id: function id(left, right) {
				return this.orderOf(left.id, right.id);
			},

			org: function org(left, right) {
				return this.orderOf(left.organisation, right.organisation);
			},

			name: function name(left, right) {
				return this.orderOf(left.firstName, right.firstName);
			},

			email: function email(left, right) {
				return this.orderOf(left.emailAddress, right.emailAddress);
			},

			reward: function reward(left, right) {
				return this.orderOf(left.rewardType, right.rewardType);
			},

			code: function code(left, right) {
				return this.orderOf(left.referralCode, right.referralCode);
			}
		},

		// Resellers-table specific
		// ########################


		currentSearch: null,

		filterRows: function filterRows() {
			var _this = this;
			var allRows = this.get("allRows");
			var filteredRows = allRows;

			// Filter whatever is left by what the user has entered in the search field.
			filteredRows = filteredRows.filter(function (element) {
				if (_this.currentSearch) {

					var lowerCaseSearchTerm = _this.currentSearch.toLowerCase();

					var firstName = element.firstName ? element.firstName.toLowerCase() : "";
					var lastName = element.lastName ? element.lastName.toLowerCase() : "";
					var organisation = element.organisation ? element.organisation.toLowerCase() : "";
					var emailAddress = element.emailAddress ? element.emailAddress.toLowerCase() : "";
					var referralCode = element.referralCode ? element.referralCode.toLowerCase() : "";

					return firstName.includes(lowerCaseSearchTerm) || lastName.includes(lowerCaseSearchTerm) || organisation.includes(lowerCaseSearchTerm) || emailAddress.includes(lowerCaseSearchTerm) || referralCode.includes(lowerCaseSearchTerm);
				} else {
					return true;
				}
			});

			this.set("rows", filteredRows);
		},

		actions: {
			onSearchInputChanged: function onSearchInputChanged(event) {
				var searchTerm = event.target.value;
				this.set('currentSearch', searchTerm);
				this.filterRows();
			}
		}
	});
});