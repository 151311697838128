define("storeplay-admin/helpers/day-of-week", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.dayOfWeek = dayOfWeek;


	var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

	function dayOfWeek(params /*, hash*/) {
		var dayInt = parseInt(params[0]);

		if (isNaN(dayInt) || dayInt > 6 || dayInt < 0) {
			return "Invalid day";
		} else {
			return days[dayInt];
		}
	}

	exports.default = Ember.Helper.helper(dayOfWeek);
});