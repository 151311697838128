define('storeplay-admin/components/forms/channels-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('apiClient'),
		currentUser: Ember.inject.service('current-user'),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		swapOldChannelID: 0,
		swapNewChannelID: 0,
		addChannelID: 0,
		addChannelMode: false,
		swapChannelMode: false,

		canAddMoreChannels: Ember.computed('model', function () {
			return this.model.subscriptionPlan && this.model.subscriptionPlan.maxChannels > this.model.currentChannels.length;
		}),

		actions: {

			// Swap channel
			swapChannelClicked: function swapChannelClicked(channelID) {
				this.set('swapOldChannelID', channelID);
				this.set('swapNewChannelID', channelID);
				this.set('swapChannelMode', true);
				this.set('addChannelMode', false);
				this.set('addChannelID', 0);
			},
			swapChannelSelected: function swapChannelSelected(channelID) {
				this.set('swapNewChannelID', channelID);
			},
			saveSwapChannelClicked: function saveSwapChannelClicked(channelID) {
				var _this = this;

				var user = this.get('currentUser');

				// Validate
				if (this.model.currentChannels.filter(function (c) {
					return c.id == _this.swapNewChannelID;
				}).length > 0) {
					this.get('applicationController').displayModal({ title: 'You\'re already subscribed to this channel' });
					return;
				}
				console.log('saveSwapChannelClicked, about to RPC with subs ID : ' + this.model.subscription.id + ', oldChannelID : ' + this.swapOldChannelID + ', newChannelID : ' + this.swapNewChannelID);
				this.get('apiClient').rpc('swapChannelOnSubscription', { subscriptionID: this.model.subscription.id, oldChannelID: this.swapOldChannelID, newChannelID: this.swapNewChannelID }, user.adminToken).then(function () {
					_this.set('swapChannelMode', false);
					_this.set('swapOldChannelID', 0);
					_this.set('swapNewChannelID', 0);
					_this.get('refreshRoute')();
				}).catch(function (errorObject) {
					console.log('errorObject : %o', errorObject);
					_this.get('applicationController').displayModal({
						title: 'Sorry, we could not add the change this channel',
						message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
					});
				});
			},
			cancelSwapChannelClicked: function cancelSwapChannelClicked() {
				this.set('swapOldChannelID', 0);
				this.set('swapNewChannelID', 0);
				this.set('swapChannelMode', false);
			},


			// Remove channel
			removeChannelClicked: function removeChannelClicked(channelID) {
				var _this2 = this;

				console.log('remove channel clicked');
				var user = this.get('currentUser');
				this.get('applicationController').displayModal({
					title: 'Are you sure you want to remove this channel?',
					primaryButtonLabel: 'Yes, I\'m sure',
					primaryButtonFunction: function primaryButtonFunction() {
						_this2.get('apiClient').rpc('removeChannelFromSubscription', { subscriptionID: _this2.model.subscription.id, oldChannelID: channelID }, user.adminToken).then(function (response) {
							_this2.get('refreshRoute')();
						}).catch(function (errorObject) {
							console.log('errorObject : %o', errorObject);
							_this2.get('applicationController').displayModal({
								title: 'Sorry, we could not remove this channel',
								message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
							});
						});
					},
					secondaryButtonLabel: 'Cancel'
				});
			},


			// Add channel
			addChannelClicked: function addChannelClicked() {
				console.log('add channel clicked');
				this.set('addChannelMode', true);
				this.set('swapChannelMode', false);
				this.set('swapOldChannelID', 0);
				this.set('swapNewChannelID', 0);
			},
			addChannelSelected: function addChannelSelected(channelID) {
				this.set('addChannelID', channelID);
			},
			saveAddChannelClicked: function saveAddChannelClicked() {
				var _this3 = this;

				var user = this.get('currentUser');

				// Validate
				if (this.addChannelID == 0) {
					this.get('applicationController').displayModal({ title: 'Please choose a channel to add to your subscription' });
					return;
				}

				if (this.model.currentChannels.filter(function (c) {
					return c.id == _this3.addChannelID;
				}).length > 0) {
					this.get('applicationController').displayModal({ title: 'You\'re already subscribed to this channel' });
					return;
				}

				console.log('saveAddChannelClicked, about to RPC with subs ID : ' + this.model.subscription.id + ', newChannelID : ' + this.addChannelID);
				this.get('apiClient').rpc('addChannelToSubscription', { subscriptionID: this.model.subscription.id, newChannelID: this.addChannelID }, user.adminToken).then(function () {
					_this3.set('addChannelMode', false);
					_this3.set('addChannelID', 0);
					_this3.get('refreshRoute')();
				}).catch(function (errorObject) {
					console.log('errorObject : %o', errorObject);
					_this3.get('applicationController').displayModal({
						title: 'Sorry, we could not add the new channel',
						message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
					});
				});
			},
			cancelAddChannelClicked: function cancelAddChannelClicked() {
				this.set('addChannelMode', false);
				this.set('addChannelID', 0);
			}
		}
	});
});