define('storeplay-admin/models/jukebox-user', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var JukeboxUser = Ember.Object.extend({
		// Instance properties/methods
		id: null,
		name: null,
		username: null,
		email: null,
		type: null,
		mobilePhone: null,
		toJSON: function toJSON() {
			var json = {};
			json['id'] = this.get('id');
			json['name'] = this.get('name');
			json['username'] = this.get('username');
			json['email'] = this.get('email');
			json['type'] = this.get('type');
			json['mobilePhone'] = this.get('mobilePhone');
			return json;
		},


		typeString: Ember.computed('type', function () {
			switch (this.get('type')) {
				case 1:
					return "Email";
				case 2:
					return "Facebook";
				case 3:
					return "Spotify";
				default:
					return "Unknown";
			}
		})
	});

	JukeboxUser.reopenClass({
		// Class properties/methods
		fromJSON: function fromJSON(jukeboxUserJSONObject) {
			var jukeboxUser = this.create({
				id: jukeboxUserJSONObject["id"] || 0,
				name: jukeboxUserJSONObject["name"] || null,
				username: jukeboxUserJSONObject["username"] || null,
				email: jukeboxUserJSONObject["email"] || null,
				type: jukeboxUserJSONObject["type"] || 1,
				mobilePhone: jukeboxUserJSONObject["mobilePhone"] || null
			});
			return jukeboxUser;
		}
	});

	exports.default = JukeboxUser;
});