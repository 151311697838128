define('storeplay-admin/models/client-user', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ClientUser = Ember.Object.extend({

		id: 0,
		clientID: 0,
		username: null,
		appUsername: null,
		givenName: null,
		familyName: null,
		emailAddress: null,
		phone: null,
		subscriptionRegionID: 0,

		toJSON: function toJSON() {
			var json = {};

			json["id"] = this.get("id");
			json["clientID"] = this.get("clientID");
			json["username"] = this.get("username");
			json["appUsername"] = this.get("appUsername");
			json["givenName"] = this.get("givenName");
			json["familyName"] = this.get("familyName");
			json["emailAddress"] = this.get("emailAddress");
			json["phone"] = this.get("phone");
			json["subscriptionRegionID"] = this.get("subscriptionRegionID");

			return json;
		}
	});

	ClientUser.reopenClass({
		fromJSON: function fromJSON(clientUserObject) {
			var clientUser = this.create({
				id: clientUserObject["id"],
				clientID: clientUserObject["clientID"],
				username: clientUserObject["username"],
				appUsername: clientUserObject["appUsername"],
				givenName: clientUserObject["givenName"],
				familyName: clientUserObject["familyName"],
				emailAddress: clientUserObject["emailAddress"],
				phone: clientUserObject["phone"],
				subscriptionRegionID: clientUserObject["subscriptionRegionID"]
			});
			return clientUser;
		}
	});

	exports.default = ClientUser;
});