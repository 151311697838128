define("storeplay-admin/components/tables/former-clients-table", ["exports", "storeplay-admin/components/sortable-table/sortable-table-base"], function (exports, _sortableTableBase) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		columns: [{ title: "ID", sorter: "id", width: "80px" }, { title: "Client", sorter: "clientName", width: "200px" }, { title: "First Payment", sorter: "firstPayment", width: "150px" }, { title: "Last Payment", sorter: "lastPayment", width: "150px" }, { title: "Licenses", sorter: "licenses", width: "100px" }, { title: "Revenue", sorter: "revenue", width: "100px" }],

		sorters: {
			id: function id(left, right) {
				return this.orderOf(left.ID, right.ID);
			},

			clientName: function clientName(left, right) {
				// The model currently returns this instead of null..., need to check for the string.
				left = left.clientName == "(unknown, possibly deleted)" ? null : left.clientName.toLowerCase();
				right = right.clientName == "(unknown, possibly deleted)" ? null : right.clientName.toLowerCase();

				return this.orderOf(left, right);
			},

			firstPayment: function firstPayment(left, right) {
				return this.orderOf(left.firstPayment, right.firstPayment);
			},

			lastPayment: function lastPayment(left, right) {
				return this.orderOf(left.lastPayment, right.lastPayment);
			},

			licenses: function licenses(left, right) {
				return this.orderOf(left.licenses, right.licenses);
			},

			revenue: function revenue(left, right) {
				return this.orderOf(left.revenue, right.revenue);
			}
		}

	});
});