define('storeplay-admin/components/forms/reseller-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service("current-user"),
		apiClient: Ember.inject.service("api-client"),
		ajax: Ember.inject.service(),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		init: function init(args) {
			this._super(args);

			// The component has been created in memory

			var columns = [{ title: "Client Name" }, { title: "Type" }];

			this.set("columns", columns);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component was added to the DOM
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		actions: {

			onSearchInputChanged: function onSearchInputChanged(event) {
				var searchValue = event.target.value;
				console.log(searchValue);
			},

			rewardTypeChanged: function rewardTypeChanged(event) {
				var newValue = event.target.value;
				this.set("reseller.rewardType", newValue);

				// If we've changed to the discount reward type, "7", and there's currently no value set for the reward quantity (the amount of the discount), then we'll set to $5 right away
				if ((newValue == '7' || newValue == '8') && (!this.reseller.rewardQuantity || this.reseller.rewardQuantity == "1")) {
					this.set("reseller.rewardQuantityCurrency", "5.00");
				}
			},

			// Buttons

			cancel: function cancel() {
				this.backToResellers();
			},

			delete: function _delete() {
				var _this2 = this;

				var _this = this;

				_this.get('applicationController').displayModal({
					title: 'Are you sure you want to delete this reseller?',
					primaryButtonLabel: 'Yes, delete',
					primaryButtonFunction: function primaryButtonFunction() {

						var reseller = _this2.get("reseller");
						_this.deleteReseller(reseller).then(function () {
							_this.get('applicationController').displayModal({
								title: 'The reseller has been deleted.',
								primaryButtonFunction: function primaryButtonFunction() {
									_this.backToResellers();
								}
							});
						}).catch(function (error) {
							console.log(error);
							_this.get('applicationController').displayModal({ title: "Error", message: "Sorry there was a problem deleting the reseller." });
						});
					},
					secondaryButtonLabel: 'Cancel'
				});
			},

			save: function save(shouldStayOnPage) {

				var _this = this;
				var reseller = this.get("reseller");

				var errors = this.validateReseller(reseller);

				if (errors.length != 0) {
					_this.presentErrors(errors);
				} else {

					_this.get('applicationController').displayModal({
						title: "Updating...",
						message: "Saving your reseller now. This may take a moment...",
						isLoading: true
					});

					_this.saveReseller(reseller).then(function (response) {
						console.log('Reseller saved with ID: ' + response.id);

						_this.get('applicationController').displayModal({
							title: "Success",
							message: "The reseller has been saved successfully.",
							primaryButtonLabel: "OK",
							primaryButtonFunction: function primaryButtonFunction() {
								if (!shouldStayOnPage) {
									// We should go back to the resellers table.
									_this.backToResellers();
								}
							}
						});
					}).catch(function (error) {
						_this.get('applicationController').displayModal({ title: "Error", message: "Unable to save your reseller. Please try again." });
						console.log(error);
					});
				}
			}
		},

		// Persisting to server
		// ####################

		saveReseller: function saveReseller(reseller) {
			var apiClient = this.get("apiClient");
			return apiClient.rpc('saveAffiliate', {
				affiliate: reseller.toJSON()
			});
		},

		deleteReseller: function deleteReseller(reseller) {
			var apiClient = this.get("apiClient");
			return apiClient.rpc('deleteAffiliate', {
				affiliateID: reseller.id
			});
		},

		// Validation
		// ##########

		validateReseller: function validateReseller(reseller) {

			var isEmptyChecks = {
				"firstName": { value: reseller.firstName, message: "The reseller's first name cannot be empty." },
				"lastName": { value: reseller.lastName, message: "The reseller's last name cannot be empty." },
				"organisation": { value: reseller.organisation, message: "The reseller's organisation cannot be empty." },
				"emailAddress": { value: reseller.emailAddress, message: "The reseller's email address cannot be empty." },
				"commissionRate": { value: reseller.commissionRate, message: "The reseller's commission rate cannot be empty." },
				"referralCode": { value: reseller.referralCode, message: "The reseller's referral code cannot be empty." },
				"rewardType": { value: reseller.rewardType, message: "The reseller's reward type cannot be empty." }
			};

			var errors = [];

			for (var check in isEmptyChecks) {
				if (this.isEmpty(isEmptyChecks[check].value)) {
					errors.push(isEmptyChecks[check].message);
				}
			}

			return errors;
		},

		presentErrors: function presentErrors(errors) {
			var errorMessage = errors.join("<br />");
			this.get('applicationController').displayModal({ title: "Validation Error", message: errorMessage });
		},

		isEmpty: function isEmpty(value) {
			return value == null || value == "";
		}

	});
});