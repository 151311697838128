define('storeplay-admin/models/background-license-fee', ['exports', 'storeplay-admin/models/money'], function (exports, _money) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.BackgroundLicenseFeeAgency = undefined;


	var BackgroundLicenseFeeAgency = Object.freeze({
		"APRA": 0,
		"PPCA": 1,
		"OneMusicNZ": 2,
		"OneMusicAURetail": 3,
		"OneMusicAUDining": 4,
		"OneMusicAURetailPPCAFree": 5,
		"OneMusicAUDiningPPCAFree": 6
	});

	var BackgroundLicenseFeeAgencyStrings = Object.freeze({
		0: "APRA",
		1: "PPCA",
		2: "OneMusic NZ",
		3: "OneMusic",
		4: "OneMusic",
		5: "OneMusic (PPCA free)",
		6: "OneMusic (PPCA free)"
	});

	var BackgroundLicenseFee = Ember.Object.extend({
		id: null,
		agency: null,
		description: null,
		cafeOnly: false,
		monthlyCost: null,
		isPPCARestaurantLicenseFee: false
	});

	BackgroundLicenseFee.reopenClass({
		fromJSON: function fromJSON(backgroundLicenseFeeJSONObject) {
			var backgroundLicenseFee = this.create({
				id: backgroundLicenseFeeJSONObject["id"],
				agency: backgroundLicenseFeeJSONObject["agency"],
				description: backgroundLicenseFeeJSONObject["description"],
				cafeOnly: backgroundLicenseFeeJSONObject["cafeOnly"],
				monthlyCost: _money.default.fromJSON(backgroundLicenseFeeJSONObject["monthlyCost"]),
				isPPCARestaurantLicenseFee: backgroundLicenseFeeJSONObject["isPPCARestaurantLicenseFee"]
			});
			return backgroundLicenseFee;
		}
	});

	exports.default = BackgroundLicenseFee;
	exports.BackgroundLicenseFeeAgency = BackgroundLicenseFeeAgency;
});