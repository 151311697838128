define('storeplay-admin/validations/store', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var store = Ember.Object.extend({
		publicName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 100 })],
		latitude: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })],
		longitude: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })],
		iso2CountryCode: [(0, _validators.validatePresence)(true)]
	});

	exports.default = store;
});