define("storeplay-admin/models/subscriptionRegion", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var SubscriptionRegion = Ember.Object.extend({

		id: 0,
		name: null,
		isoAlpha2Code: null,
		paymentGatewayCode: null

	});

	SubscriptionRegion.reopenClass({
		fromJSON: function fromJSON(subscriptionRegionObject) {
			var subscriptionRegion = this.create({
				id: subscriptionRegionObject["id"],
				name: subscriptionRegionObject["name"],
				isoAlpha2Code: subscriptionRegionObject["isoAlpha2Code"],
				paymentGatewayCode: subscriptionRegionObject["paymentGatewayCode"]
			});
			return subscriptionRegion;
		}
	});

	exports.default = SubscriptionRegion;
});