define('storeplay-admin/components/forms/client-settings-form', ['exports', 'storeplay-admin/models/client'], function (exports, _client) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		client: null,
		playerConfiguration: null,
		affiliates: null,
		oneMusicBackgroundLicenseFees: null,
		oneMusicPPCAFreeBackgroundLicenseFees: null,
		ppcaBackgroundLicenseFees: null,
		cafeBackgroundLicenseFees: null,
		oneMusicNZBackgroundLicenseFees: null,

		// General Settings
		// We are doing it this way instead of binding directly to the client
		// object because there were binding issues when binding the playlist price
		// The value would not update correctly.
		affiliateID: -1,
		oneMusicBackgroundLicenseFeeID: -1,
		ppcaBackgroundLicenseFeeID: -1,
		oneMusicNZBackgroundLicenseFeeID: -1,
		playlistPrice: null,
		regularDeviceEmailsEnabled: false,
		allowAnonymousDJAccess: false,
		isDemoClient: false,

		// Player Settings (Configuration)
		// Binding to playerConfiguration directly.

		// Archiving
		isArchivedInAdmin: false,

		// Scheduling
		showSchedulingSetting: false,

		// Confirmation for changing client type.
		shouldShowConfirmation: false,

		// archetypes and industries
		archetype: -1,
		industry: -1,
		archetypeOptions: [{
			id: 1,
			description: "Caregiver"
		}, {
			id: 2,
			description: "Creator"
		}, {
			id: 3,
			description: "Explorer"
		}, {
			id: 4,
			description: "Hero"
		}, {
			id: 5,
			description: "Innocent"
		}, {
			id: 6,
			description: "Jester"
		}, {
			id: 7,
			description: "Lover"
		}, {
			id: 8,
			description: "Rebel"
		}, {
			id: 9,
			description: "Regular"
		}, {
			id: 10,
			description: "Ruler"
		}, {
			id: 11,
			description: "Sage"
		}, {
			id: 12,
			description: "Wizard"
		}],
		industryOptions: [{
			id: 1,
			description: "Retail"
		}, {
			id: 2,
			description: "Food & Beverage"
		}, {
			id: 3,
			description: "Health & Beauty"
		}, {
			id: 4,
			description: "Gym & Fitness"
		}, {
			id: 5,
			description: "Corporate"
		}],

		init: function init(args) {
			this._super(args);

			// The component has been created in memory

			// Set default values for the form. (Which are the current values for the client.)
			this.set('affiliateID', this.client.affiliateID);
			this.set('playlistPrice', this.client.playlistPrice.decimalAmount);
			this.set('regularDeviceEmailsEnabled', this.client.enableWeeklyDeviceActivityEmail);
			this.set('isArchivedInAdmin', this.client.isArchivedInAdmin);
			this.set('allowAnonymousDJAccess', this.client.allowAnonymousDJAccess);
			this.set('isDemoClient', this.client.isDemoClient);

			this.set('oneMusicBackgroundLicenseFeeID', this.client.oneMusicAUFeeID);
			this.set('ppcaBackgroundLicenseFeeID', this.client.ppcaFeeID);
			this.set('oneMusicNZBackgroundLicenseFeeID', this.client.oneMusicNZFeeID);

			this.set('archetype', this.client.archetype);
			this.set('industry', this.client.industry);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component was added to the DOM
			this.initPopovers();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		initPopovers: function initPopovers() {
			$('.mdi').popover({
				html: true,
				trigger: 'focus'
			});
		},


		actions: {

			affiliateChanged: function affiliateChanged() {
				var selectedAffiliateID = event.target.value >= 0 ? event.target.value : null;
				this.set('affiliateID', selectedAffiliateID);
			},

			oneMusicBackgroundLicenseFeeChanged: function oneMusicBackgroundLicenseFeeChanged() {
				var selectedFeeID = event.target.value >= 0 ? event.target.value : null;
				this.set('oneMusicBackgroundLicenseFeeID', selectedFeeID);
				this.$('#oneMusicPPCAFreeBackgroundLicenseFee').val(-1);
			},

			oneMusicPPCAFreeBackgroundLicenseFeeChanged: function oneMusicPPCAFreeBackgroundLicenseFeeChanged() {
				var selectedFeeID = event.target.value >= 0 ? event.target.value : null;
				this.set('oneMusicBackgroundLicenseFeeID', selectedFeeID);
				this.$('#oneMusicBackgroundLicenseFee').val(-1);
			},

			ppcaBackgroundLicenseFeeChanged: function ppcaBackgroundLicenseFeeChanged() {
				var selectedFeeID = event.target.value >= 0 ? event.target.value : null;
				this.set('ppcaBackgroundLicenseFeeID', selectedFeeID);
				this.$('#cafeBackgroundLicenseFee').val(-1);
			},

			cafeBackgroundLicenseFeeChanged: function cafeBackgroundLicenseFeeChanged() {
				var selectedFeeID = event.target.value >= 0 ? event.target.value : null;
				this.set('ppcaBackgroundLicenseFeeID', selectedFeeID);
				this.$('#ppcaBackgroundLicenseFee').val(-1);
			},

			oneMusicNZBackgroundLicenseFeeChanged: function oneMusicNZBackgroundLicenseFeeChanged() {
				var selectedFeeID = event.target.value >= 0 ? event.target.value : null;
				this.set('oneMusicNZBackgroundLicenseFeeID', selectedFeeID);
			},

			saveGeneralSettings: function saveGeneralSettings() {
				var _this = this;
				var apiClient = this.get('apiClient');

				// Validation
				// If we are a chain, check the playlist price is all OK.
				if (this.get('client.type') == 1) {
					var validated = this.validatePlaylistPrice();
					if (!validated) {
						return;
					} else {
						// TODO: One day handle currencies with different numbers of minor units per major units.
						this.client.set('playlistPrice.minorUnitsAmount', this.get('playlistPrice') * 100);
						this.client.set('playlistPrice.decimalAmount', this.get('playlistPrice'));
					}
				}

				// Update the client object
				this.client.set('affiliateID', this.get('affiliateID'));
				this.client.set('enableWeeklyDeviceActivityEmail', this.get('regularDeviceEmailsEnabled'));
				this.client.set('allowAnonymousDJAccess', this.get('allowAnonymousDJAccess'));
				this.client.set('isDemoClient', this.get('isDemoClient'));

				// Save to server
				apiClient.rpc('saveClient', { client: this.client.toJSON() }).then(function (response) {
					_this.get('applicationController').displayModal({
						title: 'Update successful.',
						message: "General settings saved successfully."
					});
				});
			},

			savePlayerConfiguration: function savePlayerConfiguration() {
				var _this = this;
				var apiClient = this.get('apiClient');

				var validated = this.validateNumberOfDaysUserCanSignInWithoutInternet();
				if (!validated) {
					return;
				}

				apiClient.rpc('savePlayerConfiguration', { configuration: this.playerConfiguration.toJSON() }).then(function (response) {
					console.log(response);
					_this.get('applicationController').displayModal({
						title: 'Update successful.',
						message: "Player settings saved successfully."
					});
				});
			},

			saveBackgroundLicenseFees: function saveBackgroundLicenseFees() {
				var _this = this;
				var apiClient = this.get('apiClient');

				this.client.set('oneMusicAUFeeID', this.get('oneMusicBackgroundLicenseFeeID') > 0 ? parseInt(this.get('oneMusicBackgroundLicenseFeeID')) : null);
				this.client.set('ppcaFeeID', this.get('ppcaBackgroundLicenseFeeID') > 0 ? parseInt(this.get('ppcaBackgroundLicenseFeeID')) : null);
				this.client.set('oneMusicNZFeeID', this.get('oneMusicNZBackgroundLicenseFeeID') > 0 ? parseInt(this.get('oneMusicNZBackgroundLicenseFeeID')) : null);

				// Save to server
				apiClient.rpc('saveClient', { client: this.client.toJSON() }).then(function (response) {
					console.log(response);
					_this.get('applicationController').displayModal({
						title: 'Update successful.',
						message: "Background license fees saved successfully."
					});
				});
			},

			archetypeChanged: function archetypeChanged() {
				var selectedArchetype = event.target.value >= 0 ? event.target.value : null;
				this.set('archetype', selectedArchetype);
			},

			industryChanged: function industryChanged() {
				var selectedIndustry = event.target.value >= 0 ? event.target.value : null;
				this.set('industry', selectedIndustry);
			},

			saveAccountSettings: function saveAccountSettings() {
				var _this = this;
				var apiClient = this.get('apiClient');

				this.client.set('isArchivedInAdmin', this.get('isArchivedInAdmin'));
				this.client.set('archetype', this.get('archetype'));
				this.client.set('industry', this.get('industry'));

				apiClient.rpc('saveClient', { client: this.client.toJSON() }).then(function (response) {
					console.log(response);
					_this.get('applicationController').displayModal({
						title: 'Update successful.',
						message: "Account details and archive status saved successfully."
					});
				});
			},

			showConfirmation: function showConfirmation() {
				this.set('shouldShowConfirmation', true);
			},

			changeClientFromChainToIndependent: function changeClientFromChainToIndependent() {
				var _this = this;
				var apiClient = this.get('apiClient');

				apiClient.rpc('changeClientFromChainToIndependent', { clientID: this.client.id }).then(function (response) {
					console.log(response);
					_this.get('applicationController').displayModal({
						title: 'Update successful.',
						message: "Client has successfully been changed to an independent-client.",
						primaryButtonLabel: "OK",
						primaryButtonFunction: function primaryButtonFunction() {
							_this.set('shouldShowConfirmation', false);
							_this.set('client.type', 2);
						}
					});
				}).catch(function (errorObject) {
					console.log('errorObject : %o', errorObject);
					_this.get('applicationController').displayModal({
						title: 'Sorry, we could not change the client type.',
						message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
					});
				});
			},
			changeClientFromIndependentToChain: function changeClientFromIndependentToChain() {
				var _this = this;
				var apiClient = this.get('apiClient');

				apiClient.rpc('changeClientFromIndependentToChain', { clientID: this.client.id }).then(function (response) {
					console.log(response);
					_this.get('applicationController').displayModal({
						title: 'Change Successful!.',
						message: "Client has successfully been changed to a chain-client.",
						primaryButtonLabel: "OK",
						primaryButtonFunction: function primaryButtonFunction() {
							_this.set('shouldShowConfirmation', false);
							_this.set('client.type', 1);
						}
					});
				}).catch(function (errorObject) {
					console.log('errorObject : %o', errorObject);
					_this.get('applicationController').displayModal({
						title: 'Sorry, we could not change the client type.',
						message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
					});
				});
			}
		},

		// Validations
		// ###########

		validatePlaylistPrice: function validatePlaylistPrice() {
			var playlistPrice = this.get('playlistPrice');
			if (playlistPrice == null || playlistPrice.length == 0) {
				this.displayValidationError("'Playlist Price' must not be empty.");
				return false;
			}

			if (isNaN(Number(playlistPrice))) {
				this.displayValidationError("'Playlist Price' must be a number.");
				return false;
			}

			if (Number(playlistPrice) < 0) {
				this.displayValidationError("'Playlist Price' must be a number greater than zero.");
				return false;
			}

			return true;
		},

		validateNumberOfDaysUserCanSignInWithoutInternet: function validateNumberOfDaysUserCanSignInWithoutInternet() {
			var numberOfDays = this.get('playerConfiguration.maximumUserCredentialsCacheDays');

			if (numberOfDays == null || numberOfDays == "") {
				this.displayValidationError("'Number of days user can sign in without internet' must not be empty.");
				return false;
			}

			if (isNaN(Number(numberOfDays))) {
				this.displayValidationError("'Number of days user can sign in without internet' must be a number.");
				return false;
			}

			if (Number(numberOfDays) < 0) {
				this.displayValidationError("'Number of days user can sign in without internet' must be a number greater than zero.");
				return false;
			}

			return true;
		},

		displayValidationError: function displayValidationError(message) {
			if (message != null) {
				this.get('applicationController').displayModal({
					title: "Error Validating Input",
					message: message,
					primaryButtonLabel: 'OK'
				});
			}
		}
	});
});