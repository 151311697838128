define('storeplay-admin/services/current-user', ['exports', 'ember-simple-auth-token/authenticators/jwt', 'moment', 'storeplay-admin/models/client', 'storeplay-admin/models/client-user'], function (exports, _jwt, _moment, _client, _clientUser) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		session: Ember.inject.service(),
		store: Ember.inject.service(),
		apiClient: Ember.inject.service(),
		token: null,
		adminToken: null,
		userID: 0,
		clientID: 0,
		isAdminSuperUser: false,
		continueAsAdmin: false,
		client: null,
		clientUser: null,
		clientSubscriptionPlan: null,
		clientDisplayName: Ember.computed('session.data.clientDisplayName', function () {
			return this.get('session.data.clientDisplayName');
		}),

		// Check if the token has expired
		isExpired: Ember.computed.lte('session.data.authenticated.exp', (0, _moment.default)().unix()),

		decodeToken: function decodeToken(token) {
			var base64Url = token.split('.')[1];
			var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			return JSON.parse(window.atob(base64));
		},
		load: function load() {
			var _this = this;

			Ember.Logger.log('current-user.load - start');
			if (this.get('session.isAuthenticated')) {
				Ember.Logger.log('current-user.load - session is authed, checking expiry');
				if (this.get('isExpired')) {
					Ember.Logger.log('current-user.load - session expired, rejecting');
					return Ember.RSVP.reject("token expired");
				};
				this.set('token', this.get('session.data.authenticated.token'));

				var tokenData = null;

				// This is pretty defensive code, but deals with a situation where you're using localhost:4200 for some other Cuscus admin, then switch to this one
				try {
					tokenData = this.decodeToken(this.get('token'));
				} catch (err) {
					Ember.Logger.log('current-user.load - could not load JWT token data, could be left over from some other app.');
					Ember.Logger.log('current-user.load - error : %o', err);
					return Ember.RSVP.reject("session has invalid token");
				}

				if (tokenData == null) {
					Ember.Logger.log('current-user.load - JWT token data is null.');
					return Ember.RSVP.reject("session has null token");
				}

				this.set('userID', tokenData.userID);
				this.set('clientID', tokenData.clientID);
				this.set('isAdminSuperUser', tokenData.isAdminSuperUser);

				if (this.get('isAdminSuperUser') == true && !Ember.isEmpty(this.get('session.data.clientToken'))) {
					var clientToken = this.get('session.data.clientToken');
					var _tokenData = _jwt.default.prototype.getTokenData(clientToken);
					// If this token is still valid, then we'll use it, otherwise just throw it away
					if (_tokenData.exp > (0, _moment.default)().unix()) {
						this.set('clientID', _tokenData.clientID);
						this.set('token', clientToken);
						Ember.Logger.log('current-user.load - continue as client');
					} else {
						this.set('session.data.clientToken', null);
					}
				}

				if (!Ember.isEmpty(this.get('session.data.continueAsAdmin'))) {
					this.set('continueAsAdmin', this.get('session.data.continueAsAdmin'));
					if (this.get('continueAsAdmin') == true) {
						this.set('clientID', 0);
						Ember.Logger.log('current-user.load - continue as admin');
					}
				}

				// If we're a super admin user, stash the super user auth token somewhere else
				if (this.get('isAdminSuperUser') == true) {
					this.set('adminToken', this.get('session.data.authenticated.token'));
				}

				// Finally, if we haven't actually loaded the client (and user) yet, get it now
				if (this.get('client') == null && this.get('clientID')) {
					this.get('apiClient').rpc('getClientUser', { clientID: this.get('clientID'), includeRelationships: ['client'] }).then(function (clientUserJSON) {
						var clientUser = _clientUser.default.fromJSON(clientUserJSON);
						var client = _client.default.fromJSON(clientUserJSON.client);
						_this.set('clientUser', clientUser);
						_this.set('client', client);
						_this.get('session').set('data.clientDisplayName', client.get('internalName'));
					});
					Ember.Logger.log('current-user.load - loaded client, client user.');
				}

				// We'll need to load up the clientSubscriptionPlan as some areas of the site nav need the client subscription plan id to determine visiblity.
				if (this.get('clientSubscriptionPlan') == null && this.get('clientID')) {
					this.get('apiClient').rpc("getSubscriptionForClient", {
						clientID: this.get('clientID'),
						includeRelationships: ["subscriptionPlan"] }).then(function (clientSubscriptionJSON) {
						_this.set("clientSubscriptionPlan", clientSubscriptionJSON.subscriptionPlan);
					});
					Ember.Logger.log('current-user.load - loaded client subscription plan.');
				}

				Ember.Logger.log('current-user.load - cool, resolving');
				return Ember.RSVP.resolve();
			} else {
				Ember.Logger.log('current-user.load - not authed, rejecting');
				return Ember.RSVP.reject("session is not authenticated!");
			}
		},


		// Syncs the client and clientUser objects to whatever is on the server.
		// This is required when updating any information on these models, as the changes 
		// will need to be reflected throughout the entire app.
		// e.g., in the `account` route where you can manage the client/user details.
		reload: function reload() {
			var _this2 = this;

			this.get('apiClient').rpc('getClientUser', { clientID: this.get('clientID'), includeRelationships: ['client'] }).then(function (clientUserJSON) {
				var clientUser = _clientUser.default.fromJSON(clientUserJSON);
				var client = _client.default.fromJSON(clientUserJSON.client);
				_this2.set('clientUser', clientUser);
				_this2.set('client', client);
				_this2.get('session').set('data.clientDisplayName', client.get('internalName'));
			});

			if (this.get('client') != null && this.get('client.type') == 2) {
				this.get('apiClient').rpc("getSubscriptionForClient", {
					clientID: this.get('client.id'),
					includeRelationships: ["subscriptionPlan"] }).then(function (clientSubscriptionJSON) {
					_this2.set("clientSubscriptionPlan", clientSubscriptionJSON.subscriptionPlan);
				});
				Ember.Logger.log('current-user.reload - reloading client subscription plan.');
			}
		},
		loadClient: function loadClient(access_token) {
			var _this3 = this;

			this.get('session').set('data.clientToken', access_token);
			this.get('session').set('data.continueAsAdmin', false);

			var clientTokenData = _jwt.default.prototype.getTokenData(access_token);
			var clientID = clientTokenData.clientID;

			this.get('apiClient').rpc('getClientUser', { clientID: clientID, includeRelationships: ['client'] }, access_token).then(function (clientUserJSON) {
				var clientUser = _clientUser.default.fromJSON(clientUserJSON);
				var client = _client.default.fromJSON(clientUserJSON.client);
				_this3.set('clientUser', clientUser);
				_this3.set('client', client);
				_this3.get('session').set('data.clientDisplayName', client.get('internalName'));
			});

			if (this.get('client') != null && this.get('client.type') == 2) {
				this.get('apiClient').rpc("getSubscriptionForClient", {
					clientID: clientID,
					includeRelationships: ["subscriptionPlan"]
				}, access_token).then(function (clientSubscriptionJSON) {
					_this3.set("clientSubscriptionPlan", clientSubscriptionJSON.subscriptionPlan);
				});
				Ember.Logger.log('current-user.loadClient - loading client subscription plan.');
			}
		},
		unloadClient: function unloadClient() {
			Ember.Logger.log('current-user.unloadClient - start');
			this.get('session').set('data.clientToken', '');
			this.get('session').set('data.clientDisplayName', null);
			this.set('clientID', 0);
			this.set('client', null);
			this.set('clientSubscriptionPlan', null); // unload plan also
		},
		loadAdmin: function loadAdmin() {
			this.set('continueAsAdmin', true);
			this.get('session').set('data.continueAsAdmin', true);
			this.get('session').set('data.clientDisplayName', 'Admin');
			this.set('client', null);
			this.set('clientSubscriptionPlan', null);
		},
		unloadAdmin: function unloadAdmin() {
			Ember.Logger.log('current-user.unloadAdmin - start');
			this.get('session').set('data.continueAsAdmin', '');
			this.get('session').set('data.clientDisplayName', null);
			this.set('client', null); // Just in case
			this.set('clientSubscriptionPlan', null);
		}
	});
});