define('storeplay-admin/models/schedule-settings', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ScheduleSettings = Ember.Object.extend({
		scheduleId: 0,
		startTime: null,
		endTime: null,
		storeSpecificEnabled: null,
		daysOfWeek: null,
		hours: Ember.computed('startTime', 'endTime', function () {
			// whole number only.
			var values = [];
			var start = Math.floor(this.startTime / 60);
			var end = Math.ceil(this.endTime / 60);
			for (var i = start; i < end; i++) // dont include end hr
			{
				values.push(i);
			}
			return values;
		}),
		hoursFormatted: Ember.computed('hours', function () {

			var values = [];
			var start = Math.floor(this.startTime / 60);
			var end = Math.ceil(this.endTime / 60);
			for (var i = start; i < end; i++) // dont include end hr
			{
				var time = i < 12 ? i != 0 ? i + " AM" : "12 AM" : i == 12 ? i + " PM" : i - 12 + " PM";
				values.push(time);
			}
			return values;
		}),
		toJSON: function toJSON() {
			var json = {};
			json["scheduleId"] = this.get("scheduleId");
			json["startTime"] = this.get("startTime"), json["endTime"] = this.get("endTime");
			json["storeSpecificEnabled"] = this.get("storeSpecificEnabled");
			json["daysOfWeek"] = this.get("daysOfWeek");
			return json;
		}
	});

	ScheduleSettings.reopenClass({
		// Class properties/methods
		fromJSON: function fromJSON(scheduleSettingsJSONObject) {
			var scheduleSettings = this.create({
				scheduleId: scheduleSettingsJSONObject["scheduleId"] || 0,
				startTime: scheduleSettingsJSONObject["startTime"] || 0,
				endTime: scheduleSettingsJSONObject["endTime"] || null,
				storeSpecificEnabled: scheduleSettingsJSONObject["storeSpecificEnabled"] || null,
				daysOfWeek: scheduleSettingsJSONObject["daysOfWeek"] || null
			});
			return scheduleSettings;
		}
	});

	exports.default = ScheduleSettings;
});