define('storeplay-admin/components/controls/album-art-image', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		ajax: Ember.inject.service("ajax"),
		currentUser: Ember.inject.service('current-user'),

		imageUrl: null,
		defaultImageUrl: null,
		albumArtImageUrl: null,
		width: "auto",
		height: "auto",
		init: function init(args) {
			this._super(args);
			this.set('imageUrl', this.get('defaultImageUrl'));
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			var _this = this;
			// Component has now been added to the dom and ready to be manipulated.
			// Anything to do here?
			Ember.run.debounce(this, this.setImageData, 150);
		},

		willDestroyElement: function willDestroyElement() {
			// Component will be removed from the dom.
			// Do any cleanup here.
		},

		setImageData: function setImageData() {

			var _this = this;
			var user = this.get('currentUser');
			var ajax = this.get('ajax');
			var albumArtImageUrl = this.get('albumArtImageUrl');

			// do a quick ajax check to see if the artwork exist and replace the url if we don't get an error.
			$.ajax({
				type: 'get',
				accepts: "image/jpeg",
				url: albumArtImageUrl,
				success: function success(data) {
					_this.set('imageUrl', albumArtImageUrl);
				},
				error: function error(data) {
					// request failed. Don't set the url, leave as default.
				}
			});
		}
	});
});