define('storeplay-admin/models/country', ['exports', 'ember-i18n-iso-countries'], function (exports, _emberI18nIsoCountries) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Country = Ember.Object.extend({
		iso2Code: null,
		englishName: null
	});

	Country.reopenClass({
		all: function all() {
			var all = [];
			var source = (0, _emberI18nIsoCountries.getNames)('en');
			for (var iso2Code in (0, _emberI18nIsoCountries.getNames)('en')) {
				var country = Country.create({
					iso2Code: iso2Code,
					englishName: source[iso2Code]
				});
				all.push(country);
			}
			return all.sort(function (a, b) {
				return a.englishName > b.englishName;
			});
		}
	});

	exports.default = Country;
});