define('storeplay-admin/models/schedule-grid', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ScheduleGrid = Ember.Object.extend({
		client: null,
		channels: null,
		bpms: null,
		genres: null,
		schedule: null,
		hasGridChanged: false,
		channelListTagTypeID: null
	});

	exports.default = ScheduleGrid;
});