define('storeplay-admin/routes/authenticated/scheduling/client', ['exports', 'storeplay-admin/models/client-schedule'], function (exports, _clientSchedule) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		currentUser: Ember.inject.service('currentUser'),
		apiClient: Ember.inject.service('apiClient'),

		model: function model() {

			var defaultSchedule = new _clientSchedule.default();
			// create a default schedule.
			defaultSchedule.createPlaybackSchedule("00:00:00", "00:00:00");

			return defaultSchedule;
		}
	});
});