define('storeplay-admin/models/money', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var money = Ember.Object.extend({
		minorUnitsAmount: null,
		currencyCode: null,
		displayValue: null,
		decimalAmount: null,

		toJSON: function toJSON() {
			var json = {};
			json["minorUnitsAmount"] = this.get("minorUnitsAmount");
			json["currencyCode"] = this.get('currencyCode');
			return json;
		}
	});

	money.reopenClass({
		fromJSON: function fromJSON(moneyObject) {
			var money = this.create({
				minorUnitsAmount: moneyObject["minorUnitsAmount"],
				currencyCode: moneyObject["currencyCode"] || null,
				displayValue: moneyObject["displayValue"] || null,
				decimalAmount: moneyObject["decimalAmount"]
			});
			return money;
		}
	});

	exports.default = money;
});