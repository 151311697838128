define("storeplay-admin/components/tables/store-groups-table", ["exports", "storeplay-admin/components/sortable-table/sortable-table-base"], function (exports, _sortableTableBase) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		columns: [{ title: "Group Name", sorter: "groupName", width: "200px" }, { title: "", sorter: null, width: "65px" }],

		actions: {
			delete: function _delete(group) {
				// This will call the 'delete' action that is passed to
				// the component from the route's template.
				this.get('delete')(group);
			}
		},

		sorters: {
			groupName: function groupName(leftGroup, rightGroup) {
				return this.orderOf(leftGroup.name.toLowerCase(), rightGroup.name.toLowerCase());
			}
		}

	});
});