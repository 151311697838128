define('storeplay-admin/validations/client', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var client = Ember.Object.extend({
		isAfterpayEnabled: [(0, _validators.validatePresence)(true)],
		isZipMoneyEnabled: [(0, _validators.validatePresence)(true)],
		isOpenpayEnabled: [(0, _validators.validatePresence)(true)]
	});

	exports.default = client;
});