define('storeplay-admin/controllers/login', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service(),
		//	ajax: service('ajax'),
		actions: {
			authenticate: function authenticate() {
				var credentials = this.getProperties('identification', 'password');
				this.get('session').authenticate('authenticator:jwt', credentials).then(function () {}).catch(function () {
					//this.set('errorMessage', reason.error || reason);
					toastr.info("Login Failed: Incorrect username or password.", "", { positionClass: 'toast-top-full-width' });
				});
			}
		}
	});
});