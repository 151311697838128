define('storeplay-admin/router', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Router = Ember.Router.extend({
		location: _environment.default.locationType,
		rootURL: _environment.default.rootURL,

		actions: {
			willTransition: function willTransition() {
				console.log("willTransition() called in Router");
			}
		}
	});

	Router.map(function () {
		this.route('index');
		this.route('login');
		this.route('forgotten-password');
		this.route('reset-password');
		this.route('admin');
		this.route('pass-through');

		this.route('authenticated', { path: '' }, function () {
			this.route('dashboard');

			// Music and ads
			this.route('channels', function () {});

			this.route('preview-playlist', function () {
				this.route('approve', {
					path: ':preview_playlist_id'
				});
			});

			this.route('your-music', function () {});

			// My account
			this.route('account', function () {});

			this.route('subscription', function () {
				this.route('exit-survey');
			});

			this.route('branding', function () {});

			this.route('advertisements', function () {
				this.route('edit', {
					path: ':audio_media_id'
				});
			});

			this.route('chain-channels', function () {});

			this.route('stores-devices', function () {
				this.route('edit');
				this.route('new-device');
			});

			this.route('payment-methods', function () {});

			this.route('renewal-history', function () {});

			// storePlay Connect
			this.route('offers', function () {
				this.route('edit', {
					path: ':offer_id'
				});
			});

			this.route('ar-experiences', function () {
				this.route('edit', {
					path: ':experience_id'
				});
			});

			// Super admin
			this.route('regions', function () {
				this.route('edit', {
					path: ':region_id'
				});
			});

			this.route('reports', function () {
				this.route('revenue-summary');
				this.route('churn');
				this.route('royalty-report');
				this.route('revenue-report');
				this.route('storeplay-report');
				this.route('amcos-bms-report');
				this.route('category-subscriber-report');
				this.route('category-subscribers', {
					path: 'category-subscribers/:category_id'
				});
				this.route('stores-devices-report');
				this.route('preview-report');
				this.route('reseller-commission-report');
				this.route('client-playlist-subs-events');
			});

			this.route('schedule-reports', function () {
				this.route('royalty-report');
				this.route('amcos-bms-report');
			});

			this.route('admin', function () {
				this.route('client');
			});

			this.route('connect-users', function () {
				this.route('edit', {
					path: ':user_id'
				});
			});

			this.route('store-groups', function () {});

			this.route('client-settings', function () {});

			this.route('clients', function () {
				this.route('new-chain');
			});

			this.route('resellers', function () {
				this.route('edit', { path: ':reseller_id' });
			});

			this.route('admin-advertisements', function () {
				this.route('index');
				this.route('edit', {
					path: ':audio_media_id'
				});
			});

			this.route('admin-songs', function () {
				this.route('view', {
					path: ':song_id'
				});
			});

			this.route('admin-channels', function () {
				this.route('edit', {
					path: ':channel_id'
				});
				this.route('new');
			});

			this.route('admin-playlists', function () {
				this.route('view', {
					path: ':playlist_id'
				});
			});

			this.route('scheduling', function () {
				this.route('global');
				this.route('client');
				this.route('client-stores');
				this.route('index');
				this.route('edit', {
					path: ':schedule_id'
				});
				this.route('settings');
			});

			this.route('system-settings', function () {
				this.route('playlist-suffix');
			});
		});
	});

	exports.default = Router;
});