define('storeplay-admin/components/forms/connect-user-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service("current-user"),
		apiClient: Ember.inject.service("api-client"),
		ajax: Ember.inject.service(),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		init: function init(args) {
			this._super(args);

			// The component has been created in memory
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component was added to the DOM
			this.initialiseUI();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		actions: {

			// Buttons

			cancel: function cancel() {
				this.backToConnectUsers();
			},

			save: function save(shouldStayOnPage) {

				var _this = this;
				var jukeboxUser = this.get("connectUser");

				var errors = this.validateConnectUser(jukeboxUser);

				if (errors.length != 0) {
					_this.presentErrors(errors);
				} else {
					_this.get('applicationController').displayModal({
						title: "Updating...",
						message: "Saving the connect user now. This may take a moment...",
						isLoading: true
					});

					_this.saveConnectUser(jukeboxUser).then(function (response) {
						// console.log(response);
						var savedUserID = response.id;
						_this.get('applicationController').displayModal({
							title: "Success",
							message: "The user been saved successfully.",
							primaryButtonLabel: "OK",
							primaryButtonFunction: function primaryButtonFunction() {
								if (!shouldStayOnPage) {
									// We should go back to the list of connect users.
									_this.backToConnectUsers();
								}
							}
						});
					}).catch(function (error) {
						_this.get('applicationController').displayModal({ title: "Error", message: "Unable to save the connect user. Please try again." });
						console.log(error);
					});
				}
			},

			initiatePasswordReset: function initiatePasswordReset() {
				var _this = this;
				var jukeboxUser = this.get("connectUser");

				_this.get('applicationController').displayModal({
					title: "Requesting Password Reset",
					message: "Requesting a password reset for the connect user now. This may take a moment...",
					isLoading: true
				});

				_this.requestPasswordReset(jukeboxUser.email).then(function (response) {
					_this.get('applicationController').displayModal({
						title: "Success",
						message: "A password reset has been initiated for the connect user. They will receive an email momentarily.",
						primaryButtonLabel: "OK"
					});
				}).catch(function (error) {
					_this.get('applicationController').displayModal({ title: "Error", message: "Unable to initiate a password reset. Please try again." });
					console.log(error);
				});
			}
		},

		// Communicating with Server

		saveConnectUser: function saveConnectUser(connectUser) {

			// When editing the email address for a connect user who signed up with their email address,
			// we should always make sure the username is updated to the new email address as well.

			// type of 1 == email
			if (connectUser.get("type") == 1) {
				var newEmail = connectUser.get("email");
				connectUser.set("username", newEmail);
			}

			var apiClient = this.get("apiClient");
			return apiClient.rpc('saveJukeboxUser', {
				user: connectUser.toJSON()
			});
		},

		requestPasswordReset: function requestPasswordReset(connectUserEmail) {
			var apiClient = this.get("apiClient");
			return apiClient.rpc('addJukeboxPasswordReset', {
				email: connectUserEmail
			});
		},

		// deleteARExperience: function(experience) {
		// 	let apiClient = this.get('apiClient');
		// 	return apiClient.rpc('deleteARExperience', {
		// 		arExperienceID: experience.id
		// 	});
		// },

		// Updating the UI
		// ###############

		initialiseUI: function initialiseUI() {
			// Any init
			var user = this.connectUser;
			console.log(this.get("connectUser.id"));
		},

		// Validation
		// ##########

		validateConnectUser: function validateConnectUser(connectUser) {

			var errors = [];

			// TODO:
			// // Make sure the fields aren't empty.
			// var isEmptyChecks = {
			// 	"title" : {value: offer.title, message: "The title for an offer cannot be empty."},
			// 	"description" : {value: offer.description, message: "The description for an offer cannot be empty."},
			// }

			// for(var check in isEmptyChecks) {
			// 	if(this.isEmpty(isEmptyChecks[check].value)) {
			// 		errors.push(isEmptyChecks[check].message);
			// 	}
			// }

			// // If this is a new offer AND they haven't added an image, let them know.
			// if(offer.id == 0 && this.get("offerImage") == null) {
			// 	errors.push("The offer image cannot be empty.");
			// }

			return errors;
		},

		presentErrors: function presentErrors(errors) {
			var errorMessage = errors.join("<br />");
			this.get('applicationController').displayModal({ title: "Validation Error", message: errorMessage });
		},

		isEmpty: function isEmpty(value) {
			return value == null || value == "";
		}

	});
});