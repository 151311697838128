define('storeplay-admin/models/offer', ['exports', 'moment', 'storeplay-admin/models/client'], function (exports, _moment, _client) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Offer = Ember.Object.extend({
		id: null,
		title: null,
		clientID: null,
		client: null,
		startDateTime: null,
		endDateTime: null,
		description: null,
		termsConditionsURL: null,
		isRedeemable: null,
		redemptionCode: null,
		redemptionCodeType: null,
		redemptionInstructions: null,
		arExperienceID: null,
		arInstructions: null,
		arExperiences: null,
		imageURL: null,
		image: null,

		toJSON: function toJSON() {
			var json = {};
			json["id"] = this.get('id');
			json["title"] = this.get('title');
			json["clientID"] = this.get('clientID');
			json["startDateTime"] = (0, _moment.default)(this.get('startDateTime'), 'DD/MM/YYYY h:mm A').toISOString() || null;
			json["endDateTime"] = (0, _moment.default)(this.get('endDateTime'), 'DD/MM/YYYY h:mm A').toISOString() || null;
			json["description"] = this.get('description');
			json["termsConditionsURL"] = this.get('termsConditionsURL');
			json["isRedeemable"] = this.get('isRedeemable');
			json["redemptionCode"] = this.get('redemptionCode');
			json["redemptionCodeType"] = this.get('redemptionCodeType') || 0;
			json["redemptionInstructions"] = this.get('redemptionInstructions');
			json["arExperienceID"] = this.get('arExperienceID');
			json["arInstructions"] = this.get('arInstructions');
			return json;
		}
	});

	Offer.reopenClass({
		fromJSON: function fromJSON(offerObject, arExperiences) {

			var offer = this.create({
				id: offerObject["id"] || 0,
				title: offerObject["title"] || null,
				clientID: offerObject["clientID"] || null,
				client: offerObject["client"] != null ? _client.default.fromJSON(offerObject["client"]) : null,
				startDateTime: offerObject["startDateTime"] == null ? "" : _moment.default.unix(offerObject["startDateTime"].unixTime).format('DD/MM/YYYY h:mm A'),
				endDateTime: offerObject["endDateTime"] == null ? "" : _moment.default.unix(offerObject["endDateTime"].unixTime).format('DD/MM/YYYY h:mm A'),
				description: offerObject["description"] || "",
				termsConditionsURL: offerObject["termsConditionsURL"] || null,
				isRedeemable: offerObject["isRedeemable"],
				redemptionCode: offerObject["redemptionCode"] || null,
				redemptionCodeType: offerObject["redemptionCodeType"] || 0,
				redemptionInstructions: offerObject["redemptionInstructions"] || null,
				arExperienceID: offerObject["arExperienceID"] || null,
				arInstructions: offerObject["arInstructions"] || null,
				arExperiences: new Ember.A(arExperiences) || null,
				imageURL: offerObject["imageURL"] || null
			});
			return offer;
		}
	});
	exports.default = Offer;
});