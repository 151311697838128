define('storeplay-admin/components/sortable-table/sortable-table-header', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: "thead",
		currentUser: Ember.inject.service('currentUser'),

		selectedSortColumn: null,
		selectedSortDirection: "ascending",

		init: function init(args) {
			this._super(args);

			var defaultColumn = this.get('defaultSortingColumn');
			if (defaultColumn) {
				this.set('selectedSortColumn', defaultColumn.sorter);
			}

			var defaultDirection = this.get('defaultSortingDirection');
			if (defaultDirection) {
				this.set('selectedSortDirection', defaultDirection);
			}

			// If a default column to sort by was specified, call the column clicked
			// handler to notify whoever is listening that a sort has occurred.
			if (defaultColumn) {
				var col = this.get('selectedSortColumn');
				var dir = this.get('selectedSortDirection');

				var handler = this.get('onColumnClicked');
				if (handler) {
					handler(col, dir);
				}
			}
		},

		actions: {
			columnClicked: function columnClicked(newSortColumn) {
				// newSortColumn is only ever null if we are clicking on an unsortable column.
				// We have nothing to do in that case.
				if (newSortColumn == null) {
					return;
				}

				var currentSortColumn = this.get('selectedSortColumn');
				var currentSortDirection = this.get('selectedSortDirection');

				var newSortDirection = currentSortColumn == newSortColumn ? currentSortDirection == "ascending" ? "descending" : "ascending" : "ascending";
				Ember.Logger.log('Applying ' + newSortDirection + ' sort to column: ' + newSortColumn);
				this.set('selectedSortDirection', newSortDirection);
				this.set('selectedSortColumn', newSortColumn);

				var handler = this.get('onColumnClicked');
				if (handler) {
					handler(newSortColumn, newSortDirection);
				}
			}
		}
	});
});