define('storeplay-admin/components/tables/your-music-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base', 'storeplay-admin/config/environment', 'storeplay-admin/services/current-user'], function (exports, _sortableTableBase, _environment, _currentUser) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		apiClient: Ember.inject.service("api-client"),
		currentUser: Ember.inject.service('current-user'),
		audioPlayer: Ember.inject.service('audio-player'),
		utility: Ember.inject.service('utility'),

		// Component Lifecycle
		// ###################

		defaultArtImageUrl: _environment.default.rootURL + 'assets/img/default_artwork_3x.png',
		currentlyPlayingSong: null,
		minSearchTermLength: 3,
		pageSize: 50,
		currentPage: 1,
		pageRange: 10,
		startCount: 0,
		endCount: 0,
		totalRows: 0,
		pageLinks: [],
		pagedRows: Ember.computed('sortedRows', 'pageSize', 'currentPage', function () {
			Ember.Logger.log('Computing paged rows.');
			var currentRows = this.get("sortedRows");
			var pageSize = this.get("pageSize");
			var currentPage = this.get("currentPage");
			// GET page size.
			var start = pageSize * (currentPage - 1);
			var end = start + (pageSize - 1);
			end = end > currentRows.length ? currentRows.length : end;
			return currentRows.slice(start, end + 1);
		}),
		pageCount: Ember.computed('sortedRows', 'pageSize', function () {
			var pageCount = Math.ceil(this.get('rows').length / this.get('pageSize'));
			return pageCount;
		}),
		showViewAction: false,
		showBlockAction: false,

		init: function init(args) {
			this._super(args);
			this.set("allRows", []);
		},

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
		},
		didUpdateAttrs: function didUpdateAttrs() {
			this._super.apply(this, arguments);

			/*if (this.get('allRows.length') == 0)
   {
   	this.set("allRows", this.get('rows'));
   }*/
		},


		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component has been added to the DOM. Can do any setup/init.

			// Since we are going to add filtering to this table and pagination, we will
			// keep track of the initial set of rows that were given to the
			// table, and then simply update the rows that are visible,
			// with the filtered/paged array.
			this.set("allRows", this.get('rows'));
			// Then do the initial filter. (One of the filters will be selected by default.)
			this.filterRows();
			this.setPagination();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			// Component will be removed from the DOM. Can do any deinit.
		},

		// SortableTable Overrides
		// #######################
		columns: Ember.computed('showViewAction', 'currentUser', function () {
			var currentUser = this.get("currentUser");
			var cols = [{ title: "Title", sorter: "title", width: "250px" }, { title: "Artist", sorter: "artist", width: "150px" }, { title: "Genre", sorter: "genre", width: "150px" }, { title: "Duration", sorter: "duration", width: "80px" }, { title: "Actions", sorter: null, width: "80px" }];
			return cols;
		}),

		sorters: {

			title: function title(left, right) {
				return this.orderOf(left.title.toLowerCase(), right.title.toLowerCase());
			},

			artist: function artist(left, right) {
				return this.orderOf(left.artist.toLowerCase(), right.artist.toLowerCase());
			},

			genre: function genre(left, right) {
				return this.orderOf(left.genres[0], right.genres[0]);
			},

			duration: function duration(left, right) {
				return this.orderOf(left.duration, right.duration);
			}
		},

		// your-music-table specific
		// #############################

		currentSearchTerm: null,
		blockedSongsFilterOptions: ["Unblocked", "Blocked"],
		currentBlockedSongsFilterOption: "Unblocked",
		actions: {

			onSearchInputChanged: function onSearchInputChanged(event) {
				var searchTerm = event.target.value;
				this.set('currentSearchTerm', searchTerm);

				// Debounce the filtering to improve perceived performance
				Ember.run.debounce(this, this.filterRows, 150);
			},
			onPageSizeChanged: function onPageSizeChanged(event) {
				var pageSize = event.target.value;
				this.set('pageSize', parseInt(pageSize));
				this.setPagination();
			},
			onPageChanged: function onPageChanged(pageNumber) {

				this.set('currentPage', pageNumber);
				this.setPagination();
			},
			downloadAllRowsAsCsv: function downloadAllRowsAsCsv(event) {
				var _this2 = this;

				var headers = "title";
				var data = "";
				var dataRows = this.get("allRows");
				dataRows.forEach(function (element) {
					// song name
					if (element) {
						data += _this2.createCsvTextField(element.store.publicName);
					} else {
						data += _this2.createCsvField("N/A");
					}
				});
				var blob = new Blob([headers + data]);
				var downloadDate = moment().format('YYYY-MM-DD');;
				if (window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveBlob(blob, "your-music-summary_" + downloadDate + ".csv");
				} else {
					var anchor = window.document.createElement("a");
					var url = window.URL.createObjectURL(blob, {
						type: "text/csv"
					});
					anchor.href = url;
					anchor.download = "your-music-summary_" + downloadDate + ".csv";
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					window.URL.revokeObjectURL(url);
				}
				blob = null;
			},
			stopSong: function stopSong() {
				// If the user presses stop manually, then we shouldn't keep playing consecutive songs next time.
				this.get('audioPlayer').stop();
			},

			// Seeks to a certain point in the song. Can be used by clicking on the track bar for the song.
			// param: 'position' is a value between 0 and 1, representing how far through the song.
			seekSong: function seekSong(position) {},

			blockSong: function blockSong(song) {
				var _this = this;
				console.log('block song - ' + song.mediaID);
				// API call to block the song
				var currentUser = this.get('currentUser');
				this.get('apiClient').rpc('addClientBlockedSong', { clientID: currentUser.clientID, songID: song.mediaID }, currentUser.adminToken).then(function (response) {
					if (response && response.error) {
						console.log('API error: %o', response.error);
						_this.get('applicationController').displayModal({
							title: 'Sorry, we could not block the song.',
							message: response.error && response.error.message ? response.error.message : "An unexpected error occurred."
						});
						return;
					} else {
						// find song in the current list and set the isClientBlocked flag
						_this.get('rows').find(function (s) {
							return s.mediaID == song.mediaID;
						}).set("isClientBlocked", true);
					}
				}).catch(function (errorObject) {
					console.log('errorObject : %o', errorObject);
					_this.get('applicationController').displayModal({
						title: 'Sorry, we could not block the song.',
						message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
					});
				});
			},

			unBlockSong: function unBlockSong(song) {
				var _this = this;
				console.log('unblock song - ' + song.mediaID);

				// API call to block the song
				var currentUser = this.get('currentUser');
				this.get('apiClient').rpc('removeClientBlockedSong', { clientID: currentUser.clientID, songID: song.mediaID }, currentUser.adminToken).then(function (response) {
					if (response && response.error) {
						console.log('API error: %o', response.error);
						_this.get('applicationController').displayModal({
							title: 'Sorry, we could not block the song.',
							message: response.error && response.error.message ? response.error.message : "An unexpected error occurred."
						});
						return;
					} else {
						// find song in the current list and set the isClientBlocked flag
						_this.get('rows').find(function (s) {
							return s.mediaID == song.mediaID;
						}).set("isClientBlocked", false);
					}
				}).catch(function (errorObject) {
					console.log('errorObject : %o', errorObject);
					_this.get('applicationController').displayModal({
						title: 'Sorry, we could not block the song.',
						message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
					});
				});
			},

			// param: 'song' is the song which you want to start playing.
			playSong: function playSong(song) {
				console.log("Attempting to play song " + song + "...");

				this.get('audioPlayer').songBuffered = this.songBuffered.bind(this);
				this.get('audioPlayer').songStarted = this.songStarted.bind(this);
				this.get('audioPlayer').songDidTick = this.songDidTick.bind(this);
				this.get('audioPlayer').songSeeked = this.songSeeked.bind(this);
				this.get('audioPlayer').songFinished = this.songFinished.bind(this);

				this.get('audioPlayer').playSongs([song]);
			},

			blockedSongsFilterChanged: function blockedSongsFilterChanged(blockedSongsFilterOption) {
				this.set('currentBlockedSongsFilterOption', blockedSongsFilterOption);
				this.filterRows();
			}
		},

		createCsvField: function createCsvField(data, isLast) {
			if (data != null) return data.toString() + (isLast ? '\n' : ',');else return '' + (isLast ? '\n' : ',');
		},

		createCsvTextField: function createCsvTextField(text, isLast) {
			if (text != null) {
				var value = text.toString().trim();

				// escape any double quotes in value.
				value = value.replace('"', '"""');

				// surround value with double quotes if there is a comma
				if (value.indexOf(',') > -1) {
					value = '"' + value + '"';
				}

				// add new line if this is the last valuefor the row, or a comma if not.
				return value + (isLast ? '\n' : ',');
			} else {
				return 'N/A' + (isLast ? '\n' : ',');
			}
		},

		filterRows: function filterRows() {

			var _this = this;
			var filteredRows = this.get("allRows");

			if (this.currentBlockedSongsFilterOption) {
				filteredRows = filteredRows.filter(function (element) {
					return element.isClientBlocked == (_this.currentBlockedSongsFilterOption == "Blocked");
				});
			}

			var searchTerm = (this.get('currentSearchTerm') || '').toLowerCase();

			// Filter whatever is left by what the user has entered in the search field.
			filteredRows = filteredRows.filter(function (row) {
				if (searchTerm.length >= _this.get('minSearchTermLength')) {
					return row.matches(searchTerm);
				} else {
					return true;
				}
			});

			this.set("rows", filteredRows);

			this.setPagination();
		},

		setPagination: function setPagination() {

			var currentRows = this.get("sortedRows");
			var pageSize = this.get("pageSize");
			var currentPage = this.get("currentPage");
			var pageCount = this.get("pageCount");

			// Deal with some cases when we are reloading from zero, results or less pages then current.
			if (currentPage >= pageCount) {
				currentPage = pageCount;
				this.set('currentPage', currentPage);
			}

			if (currentPage == 0 && pageCount > 0) {
				currentPage = 1;
				this.set('currentPage', currentPage);
			}

			var start = pageSize * (currentPage - 1);

			start = start < 0 ? 0 : start;
			var end = start + (pageSize - 1);
			end = end > currentRows.length ? currentRows.length : end + 1;
			// Now set the paged rows to the current page.
			this.set('startCount', currentRows.length == 0 ? 0 : start + 1);
			this.set('endCount', currentRows.length == 0 ? 0 : end);
			this.set('totalRows', currentRows.length);
			this.setPaginationLinks();
		},
		setPaginationLinks: function setPaginationLinks() {

			var min = 1;
			var pageRange = this.get("pageRange");
			var pageCount = this.get("pageCount");
			var currentPage = this.get("currentPage");

			// limit the range to the cound
			if (pageRange > pageCount) {
				pageRange = pageCount;
			}

			// Determine a start
			var start = currentPage - Math.floor(pageRange / 2);
			// find the min page
			start = Math.max(start, min);
			// Find the max page
			start = Math.min(start, min + pageCount - pageRange);
			// build the range.
			var rangeArray = Array.from({ length: pageRange }, function (el, i) {
				return start + i;
			});

			var pageLinks = [];

			if (currentPage > 1) {
				pageLinks.push({
					selected: false,
					number: currentPage - 1,
					text: '< Prev',
					isPrev: true
				});
			}

			for (var i = 0; i < rangeArray.length; i++) {
				var r = rangeArray[i];
				pageLinks.push({
					selected: currentPage == r,
					number: r,
					text: r
				});
			}

			if (currentPage < pageCount) {
				pageLinks.push({
					selected: false,
					number: currentPage + 1,
					text: 'Next >',
					isNext: true
				});
			}

			this.set('pageLinks', pageLinks);
		}
	});
});