define('storeplay-admin/components/dashboard/new-clients-panel', ['exports', 'storeplay-admin/models/client'], function (exports, _client) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),

		numberOfColumns: 6,
		tagName: "", // This ensures the component is not wrapped in an ember-view div, which is not needed for this component.

		isFetchingData: true,
		errorFetchingData: false,

		init: function init(args) {
			// The component has been created in memory
			this._super(args);

			this.set("newClients", []);

			this.set("currentYear", moment().year());
			this.set("previousYear", moment().year() - 1);
			this.set("years", [this.get("previousYear"), this.get("currentYear")]);
		},

		didInsertElement: function didInsertElement() {
			// Component was added to the DOM
			this._super.apply(this, arguments);

			this.fetchNewClientData();
		},

		willDestroyElement: function willDestroyElement() {
			// Component is about to be removed from the DOM
			this._super.apply(this, arguments);
		},

		actions: {
			onYearChanged: function onYearChanged(newValue) {
				this.updateGraphForYear(newValue);
			}
		},

		// Fetching Data
		// #############

		fetchNewClientData: function fetchNewClientData() {
			var _this = this;
			var apiClient = this.get('apiClient');

			var getNewIndependentsCall = apiClient.rpc('getNewIndependentClients', { numberOfClients: 7, includeRelationships: null });
			var getSubscriptionPlansCall = apiClient.rpc('getAllSubscriptionPlans');
			var getNewIndependentCountByMonthCall = apiClient.rpc("getNewIndependentClientCountByMonth", { year: this.get("currentYear") });

			Promise.all([getNewIndependentsCall, getSubscriptionPlansCall, getNewIndependentCountByMonthCall]).then(function (results) {
				var clientJSONObjects = results[0];
				var subscriptionPlans = results[1];
				var clientCountsByMonth = results[2];

				var clients = _this.attachSubscriptionDetailsToClients(clientJSONObjects, subscriptionPlans);
				_this.set("newClients", clients);
				_this.set("isFetchingData", false);

				setTimeout(function () {
					_this.initialiseGraph(clientCountsByMonth);
				}, 0);
			}).catch(function (error) {
				_this.set("isFetchingData", false);
				_this.set("errorFetchingData", true);
				console.log(error);
			});
		},

		attachSubscriptionDetailsToClients: function attachSubscriptionDetailsToClients(clientJSONObjects, subscriptionPlans) {

			// Create the client models from the JSON
			var newIndependentClients = clientJSONObjects.map(function (json) {
				var client = _client.default.fromJSON(json);

				if (json.extra) {
					client.extra = {
						subscriptionStartedDate: moment(json.extra.subscriptionStartedDate),
						subscriptionPlanID: json.extra.subscriptionPlanID
					};
				}

				return client;
			});

			// Sort the clients.
			var sortedClients = newIndependentClients.sort(function (left, right) {
				return left.extra.subscriptionStartedDate.isBefore(right.extra.subscriptionStartedDate) ? 1 : -1;
			});

			// Given a plan ID, update the name accordingly.
			sortedClients.forEach(function (client) {
				client.extra.subscriptionPlanName = subscriptionPlans.filter(function (plan) {
					return plan.id === client.extra.subscriptionPlanID;
				})[0].name;
			});

			return sortedClients;
		},

		// Graph
		// #####

		initialiseGraph: function initialiseGraph(clientCountsByMonth) {
			this.newClientsGraphConfig = {
				type: 'line',
				data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
					datasets: [{
						label: 'New Independent Clients',
						backgroundColor: "#00a2ff",
						borderColor: "#00a2ff",
						data: [clientCountsByMonth[1], clientCountsByMonth[2], clientCountsByMonth[3], clientCountsByMonth[4], clientCountsByMonth[5], clientCountsByMonth[6], clientCountsByMonth[7], clientCountsByMonth[8], clientCountsByMonth[9], clientCountsByMonth[10], clientCountsByMonth[11], clientCountsByMonth[12]],
						fill: false
					}]
				},
				options: {
					responsive: true,

					title: {
						display: false,
						text: "New Clients"
					},
					tooltips: {
						mode: 'index',
						intersect: false
					},
					hover: {
						mode: 'nearest',
						intersect: true
					},
					scales: {
						xAxes: [{
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Month'
							}
						}],
						yAxes: [{
							display: true,
							scaleLabel: {
								display: true,
								labelString: 'Number of new clients'
							},
							ticks: {
								beginAtZero: true,
								stepSize: 1
							}
						}]
					},
					legend: {
						display: false
					}
				}
			};

			var canvas = document.getElementById("new-clients-canvas");
			var context = canvas.getContext("2d");
			this.newClientsGraph = new Chart(context, this.newClientsGraphConfig);
		},

		updateGraphForYear: function updateGraphForYear(year) {
			var _this = this;
			var apiClient = this.get('apiClient');

			apiClient.rpc("getNewIndependentClientCountByMonth", { year: year }).then(function (results) {
				var clientCountsByMonth = results;

				_this.newClientsGraphConfig.data.datasets[0].data = [clientCountsByMonth[1], clientCountsByMonth[2], clientCountsByMonth[3], clientCountsByMonth[4], clientCountsByMonth[5], clientCountsByMonth[6], clientCountsByMonth[7], clientCountsByMonth[8], clientCountsByMonth[9], clientCountsByMonth[10], clientCountsByMonth[11], clientCountsByMonth[12]];

				_this.newClientsGraph.update();
			}).catch(function (error) {
				console.log(error);
			});
		}

	});
});