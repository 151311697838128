define('storeplay-admin/components/forms/storeplay-report-form', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service("current-user"),
		ajax: Ember.inject.service(),

		init: function init(args) {
			this._super(args);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Add the options to the date selector
			var now = moment();
			var fromDate = $('#fromDate');
			for (var i = 0; i < 24; i++) {
				fromDate.append($('<option>', {
					value: now.format('YYYY-MM') + "-1",
					text: now.format('MMM YYYY')
				}));
				now.subtract(1, 'months');
			}
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		actions: {

			generate: function generate() {
				var currentUser = this.get("currentUser");
				var reportURL = _environment.default.apiBaseURL + "/admin-reports/storeplay-report" + "?authToken=" + currentUser.adminToken + "&fromDate=" + $('#fromDate').val() + "&numberOfMonths=" + $('#numberOfMonths').val() + "&isoCountryCode=" + $('#isoCountryCode').val() + "&includeChainSpecific=" + $('#includeChainSpecific').is(':checked') + "&includeChainChannel=" + $('#includeChainChannel').is(':checked') + "&includeIndyChannel=" + $('#includeIndyChannel').is(':checked');
				window.location.href = reportURL;
			}

		}

	});
});