define('storeplay-admin/components/store-schedule-picker', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['row', 'mb-2'],
		fromWrapperClass: 'col-lg-4',
		interval: 15, // Minutes
		fullList: [],
		fromOptions: [],
		toOptions: [],
		isEveryday: false,
		scheduleType: "time",
		onOptionChanged: function onOptionChanged(newOption) {},
		init: function init() {
			this._super.apply(this, arguments);
			this.set('fullList', this.getOptions());
		},
		didReceiveAttrs: function didReceiveAttrs() {
			var _this = this;

			var fromList = [];
			fromList.push({ label: 'Always On', value: 'always' });
			fromList.push({ label: 'User controlled', value: 'user' });
			fromList.push.apply(fromList, this.get('fullList').filter(function (o) {
				return o.value != _this.get('model.to');
			}));
			this.set('fromWrapperClass', 'col-lg-4');
			if (this.get('model.from') == "00:00:00" && this.get('model.to') == "00:00:00") {
				this.set('model.from', "always");
				this.set('model.to', "always");
				this.set('fromWrapperClass', 'col-lg-8');
			}
			if (this.get('model.from') == "-01:00:00" && this.get('model.to') == "-01:00:00") {
				this.set('model.from', "user");
				this.set('model.to', "user");
				this.set('fromWrapperClass', 'col-lg-8');
			}
			this.set('fromOptions', fromList);

			var toOptions = this.get('fullList').filter(function (o) {
				return o.value > _this.get('model.from') && o.value != "always" && o.value != "user";
			});
			// inject in the 00:00:00 midnight.
			toOptions.push({ label: '00:00', value: '00:00:00' });

			this.set('toOptions', toOptions);
		},
		didChangeFrom: function didChangeFrom(event) {
			var _this2 = this;

			this.set('model.from', event.target.value);
			var toOptions = this.get('fullList').filter(function (o) {
				return o.value > _this2.get('model.from') && o.value != "always" && o.value != "user";
			});
			// inject in the 00:00:00 midnight.
			toOptions.push({ label: '00:00', value: '00:00:00' });
			this.set('toOptions', toOptions);
			this.onOptionChanged(this.get("model.from"));
			if (this.get("model.from") == "always" || this.get("model.from") == "user") {
				this.set('fromWrapperClass', 'col-lg-8');
				// Reset to 
				this.set('model.to', null);
			} else {
				this.set('fromWrapperClass', 'col-lg-4');
				if (!this.get('model.to')) {
					// default to first
					console.log(toOptions[0].value);
					this.set('model.to', toOptions[0].value);
				}
			}
		},
		didChangeTo: function didChangeTo(event) {
			var _this3 = this;

			this.set('model.to', event.target.value);
			var fromList = [];
			fromList.push({ label: 'Always On', value: 'always' });
			fromList.push({ label: 'User controlled', value: 'user' });
			if (event.target.value != "00:00:00") {
				fromList.push.apply(fromList, this.get('fullList').filter(function (o) {
					return o.value < _this3.get('model.to');
				}));
			} else {
				fromList.push.apply(fromList, this.get('fullList'));
			}
			this.set('fromOptions', fromList);
			this.onOptionChanged(this.get("model.to"));
		},
		getOptions: function getOptions() {
			var interval = this.get('interval');
			var intervalInSeconds = interval * 60;
			var day = 24 * (60 / interval) * intervalInSeconds;
			var arr = [];

			var padded = function padded(n) {
				return n.toString().padStart(2, '0');
			};

			for (var i = 0; i < day; i += intervalInSeconds) {
				var hours = Math.floor(i / 3600);
				var minutes = Math.floor((i - hours * 3600) / 60);
				var seconds = i - (hours * 3600 + minutes * 60);

				arr.push({
					label: padded(hours) + ':' + padded(minutes),
					value: padded(hours) + ':' + padded(minutes) + ':' + padded(seconds),
					offset: i // Practical if we switch to more complex scheduling.
				});
			}
			return arr;
		}
	});
});