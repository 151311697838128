define('storeplay-admin/components/tables/stores-devices-table', ['exports', 'storeplay-admin/components/sortable-table/sortable-table-base'], function (exports, _sortableTableBase) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		apiClient: Ember.inject.service("api-client"),
		currentUser: Ember.inject.service('current-user'),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		// Component Lifecycle
		// ###################

		isSuperAdminView: false,
		isAdminSuperUser: false,
		rebootRequestDeviceUdids: [],
		rebootDateTime: null,
		showRebootRequests: false,
		minSearchTermLength: Ember.computed('isSuperAdminView', function () {
			return this.get('isSuperAdminView') ? 3 : 0;
		}),
		displayRows: Ember.computed('isSuperAdminView', 'currentSearchTerm', function () {
			var search = this.get('currentSearchTerm') || '';
			var isSuperAdminView = this.get('isSuperAdminView');
			return !isSuperAdminView || search.length >= this.get('minSearchTermLength');
		}),
		allRowsCount: Ember.computed('sortedRows', 'displayRows', function () {

			if (this.get('isSuperAdminView')) {
				return this.get('displayRows') ? this.get('sortedRows.length') : 0;
			}
			return this.get('allRows.length');
		}),
		totalStores: Ember.computed('currentUser', 'allRows', function () {
			var user = this.get('currentUser');
			if (user.isAdminSuperUser) {
				return this.get('allRows').filter(function (row) {
					return row.store != null;
				}).length;
			}
			return 0;
		}),
		totalStoresOnline: Ember.computed('currentUser', 'allRows', function () {

			var user = this.get('currentUser');
			if (user.isAdminSuperUser) {
				var playbackEventCutoff = moment().subtract(15, 'minutes');
				return this.get('allRows').filter(function (row) {
					return row.store != null && row.lastPlaybackTimestamp > playbackEventCutoff;
				}).length;
			}
			return 0;
		}),
		totalStoresNeverOnline: Ember.computed('currentUser', 'allRows', function () {

			var user = this.get('currentUser');
			if (user.isAdminSuperUser) {
				return this.get('allRows').filter(function (row) {
					return row.store != null && row.lastPlaybackTimestamp == null;
				}).length;
			}
			return 0;
		}),
		totalStoresOfflineWithin2: Ember.computed('currentUser', 'allRows', function () {

			var user = this.get('currentUser');
			if (user.isAdminSuperUser) {
				var playbackEventCutoffStart = moment().subtract(15, 'minutes');
				var playbackEventCutoffEnd = moment().subtract(2, 'weeks');
				return this.get('allRows').filter(function (row) {
					return row.store != null && row.lastPlaybackTimestamp > playbackEventCutoffEnd && row.lastPlaybackTimestamp < playbackEventCutoffStart;
				}).length;
			}
			return 0;
		}),
		totalStoresOfflineOver2: Ember.computed('currentUser', 'allRows', function () {

			var user = this.get('currentUser');
			if (user.isAdminSuperUser) {
				var playbackEventCutoff = moment().subtract(2, 'weeks');
				return this.get('allRows').filter(function (row) {
					return row.store != null && row.lastPlaybackTimestamp != null && row.lastPlaybackTimestamp < playbackEventCutoff;
				}).length;
			}
			return 0;
		}),

		init: function init(args) {
			this._super(args);
			this.set("allRows", []);

			//console.log("Is Super Admin View: " + this.get('isSuperAdminView'));
			//console.log("Is Super Admin User: " + this.get('isAdminSuperUser'));

			this.initRebootRequestUdids();
		},

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
		},
		didUpdateAttrs: function didUpdateAttrs() {
			this._super.apply(this, arguments);

			if (this.get('allRows.length') == 0) {
				this.set("allRows", this.get('rows'));
			}

			this.initRebootRequestUdids();

			// Set the formatting for the datetimepicker.
			this.initDateTimePickerFormat();
		},


		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component has been added to the DOM. Can do any setup/init.

			// Since we are going to add filtering to this table, we will
			// keep track of the initial set of rows that were given to the
			// table, and then simply update the rows that are visible,
			// with the filtered array.
			this.set("allRows", this.get('rows'));
			// Then do the initial filter. (One of the filters will be selected by default.)
			this.filterRows();

			this.initRebootRequestUdids();

			// Set the formatting for the datetimepicker.
			this.initDateTimePickerFormat();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			// Component will be removed from the DOM. Can do any deinit.
		},

		didRender: function didRender() {
			this._super.apply(this, arguments);
			this.initDateTimePickerFormat();
		},

		// SortableTable Overrides
		// #######################

		columns: Ember.computed('isSuperAdminView', 'userColumns', 'adminColumns', 'showRebootRequests', function () {
			return this.get('isSuperAdminView') ? this.get('adminColumns') : this.get('userColumns');
		}),

		userColumns: [{ title: "Store", sorter: "storeSorter", width: "250px" }, { title: "Status", sorter: "locationStatusSorter", width: "80px" }, { title: "Linked?", sorter: "linkedSorter", width: "100px" }, { title: "Device", sorter: "deviceSorter", width: "300px" }, { title: "Status", sorter: "deviceStatusSorter", width: "80px" }, { title: "Connect", sorter: "connectSorter", width: "100px" }, { title: "Last Playback", sorter: "lastPlaybackSorter", width: "120px" }, { title: "", sorter: null, width: "80px" }],

		adminColumns: [{ title: "Client", sorter: "clientSorter", width: "200px" }, { title: "Store", sorter: "storeSorter", width: "250px" }, { title: "Status", sorter: "locationStatusSorter", width: "80px" }, { title: "Linked?", sorter: "linkedSorter", width: "100px" }, { title: "Device", sorter: "deviceSorter", width: "300px" }, { title: "Status", sorter: "deviceStatusSorter", width: "80px" }, { title: "Last Playback", sorter: "lastPlaybackSorter", width: "120px" }],

		sorters: {
			clientSorter: function clientSorter(left, right) {
				return this.orderOf(left.get('store.client.internalName') || left.get('license.client.internalName'), right.get('store.client.internalName') || right.get('license.client.internalName'));
			},
			storeSorter: function storeSorter(left, right) {
				var leftName = null;
				var rightName = null;

				var nameExtractor = function nameExtractor(item) {
					if (item.store == null) {
						return null;
					}

					name = item.store.internalName ? item.store.internalName : item.store.publicName;
					if (name) {
						name = name.toLowerCase();
					}
					return name;
				};

				leftName = nameExtractor(left);
				rightName = nameExtractor(right);

				return this.orderOf(leftName, rightName);
			},

			locationStatusSorter: function locationStatusSorter(left, right) {
				// verified = 0;
				// guessed = 1;
				// not set = 2;

				var leftStatus = null;
				var rightStatus = null;

				var statusExtractor = function statusExtractor(item) {
					if (item.store == null) {
						return null;
					}

					if (item.get("storeCoordinate") == null) {
						return 2;
					} else {
						return item.store.latitudeLongitudeVerified ? 0 : 1;
					}
				};

				leftStatus = statusExtractor(left);
				rightStatus = statusExtractor(right);

				return this.orderOf(leftStatus, rightStatus);
			},

			linkedSorter: function linkedSorter(left, right) {
				// not linked = 0;
				// linked = 1;

				var leftLinked = left.store != null && left.license != null ? 1 : 0;
				var rightLinked = right.store != null && right.license != null ? 1 : 0;

				return this.orderOf(leftLinked, rightLinked);
			},

			deviceSorter: function deviceSorter(left, right) {
				var leftDeviceName = null;
				var rightDeviceName = null;

				if (left.license) {
					leftDeviceName = left.license.device.name ? left.license.device.name : 'Device ' + left.license.device.id;
				}

				if (right.license) {
					rightDeviceName = right.license.device.name ? right.license.device.name : 'Device ' + right.license.device.id;
				}

				return this.orderOf(leftDeviceName, rightDeviceName);
			},

			deviceStatusSorter: function deviceStatusSorter(left, right) {
				// reporting-near-store = 0
				// reporting-far-from-store = 1
				// device-not-linked-to-store = 2
				// device-has-not-reported-location = 3
				var leftStatus = null;
				var rightStatus = null;

				var statusExtractor = function statusExtractor(item) {
					if (item.license == null) {
						return null;
					}

					if (item.get("deviceCoordinate") != null) {
						if (item.get("deviceIsNearStore")) {
							return 0;
						} else {
							// Device is reporting, but not close.
							if (item.get("linked")) {
								return 1;
							} else {
								return 2;
							}
						}
					} else {
						return 3;
					}
				};

				leftStatus = statusExtractor(left);
				rightStatus = statusExtractor(right);

				return this.orderOf(leftStatus, rightStatus);
			},

			connectSorter: function connectSorter(left, right) {
				var leftConnectStatus = false;
				var rightConnectStatus = false;

				if (left.store) {
					leftConnectStatus = left.store.jukeboxEnabled;
				}

				if (right.store) {
					rightConnectStatus = right.store.jukeboxEnabled;
				}

				return this.orderOf(leftConnectStatus, rightConnectStatus);
			},

			lastPlaybackSorter: function lastPlaybackSorter(left, right) {

				var leftLastPlaybackDate = null;
				var rightLastPlaybackDate = null;

				if (left.license && left.lastPlaybackTimestamp) {
					leftLastPlaybackDate = moment(left.lastPlaybackTimestamp, "YYYY-MM-DD HH:mm:ss");
				}

				if (right.license && right.lastPlaybackTimestamp) {
					rightLastPlaybackDate = moment(right.lastPlaybackTimestamp, "YYYY-MM-DD HH:mm:ss");
				}

				return this.orderOf(leftLastPlaybackDate, rightLastPlaybackDate);
			}
		},

		// Stores-devices-table specific
		// #############################

		currentSearchTerm: null,

		actions: {

			toggleSection: function toggleSection(targetId) {
				var targetSelector = '#' + targetId;
				$(targetSelector).slideToggle('fast', function () {
					$('.' + targetId + '-btn').text($(targetSelector).is(':visible') ? '-' : '+');
				});
			},

			onSearchInputChanged: function onSearchInputChanged(event) {
				var searchTerm = event.target.value;
				this.set('currentSearchTerm', searchTerm);

				// Debounce the filtering to improve perceived performance
				Ember.run.debounce(this, this.filterRows, 150);
			},

			downloadAllRowsAsCsv: function downloadAllRowsAsCsv(event) {
				var _this2 = this;

				var isSuperAdminView = this.get('isSuperAdminView');
				var headers = "Store, Device, Address, State, Country, Latitude, Longitude, Last Online, Last Login, Device ID, Platform, App Version, Store Web DJ URL, Web DJ URL, Last Ping, Internal IP, External IP, MAC Address\n";
				if (isSuperAdminView) {
					headers = "Client ID, Client Name, Store, Device, Address, State, Country, Latitude, Longitude, Last Online, Last Login, Device ID, Platform, App Version, Store Web DJ URL, Web DJ URL, Time Zone, Store Active Schedule, Support Email, Last Ping, Internal IP, External IP, MAC Address\n";
				}
				var data = "";
				var dataRows = this.get("allRows");
				dataRows.forEach(function (element) {

					if (isSuperAdminView) {
						// client name
						if (element.store && element.store.client) {
							data += _this2.createCsvTextField(element.store.client.id);
							data += _this2.createCsvTextField(element.store.client.internalName);
						} else if (element.license && element.license.client) {
							data += _this2.createCsvTextField(element.license.client.id);
							data += _this2.createCsvTextField(element.license.client.internalName);
						} else {
							data += _this2.createCsvField("N/A");
							data += _this2.createCsvField("N/A");
						}
					}

					// store name
					if (element.store) {
						if (element.store.internalName) data += _this2.createCsvTextField(element.store.internalName);else data += _this2.createCsvTextField(element.store.publicName);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// device name
					if (element.license) {
						if (element.license.device && element.license.device.name) {
							data += _this2.createCsvTextField(element.license.device.name);
						} else if (element.license.id) {
							data += _this2.createCsvField("Device " + element.license.id);
						}
					} else {
						data += _this2.createCsvField("N/A");
					}

					// address
					if (element.store && element.store.streetAddress1) {
						var address = element.store.streetAddress1;
						address += element.store.streetAddress2 != null ? ", " + element.store.streetAddress2 : "";
						data += _this2.createCsvTextField(address);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// state
					if (element.store && element.store.state) {
						data += _this2.createCsvField(element.store.state);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// country
					if (element.store && element.store.iso2CountryCode) {
						data += _this2.createCsvField(element.store.iso2CountryCode);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// lat
					if (element.store && element.store.latitude) {
						data += _this2.createCsvField(element.store.latitude);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// long
					if (element.store && element.store.longitude) {
						data += _this2.createCsvField(element.store.longitude);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// last online
					if (element.lastPlaybackTimestamp) {
						data += _this2.createCsvField(moment(element.lastPlaybackTimestamp).format("YYYY-MM-DD HH:mm:ss"));
					} else {
						data += _this2.createCsvField("N/A");
					}

					// last login
					if (element.license && element.license.lastLogin && element.license.lastLogin.iso8601) {
						data += _this2.createCsvField(moment(element.license.lastLogin.iso8601).format("YYYY-MM-DD HH:mm:ss"));
					} else {
						data += _this2.createCsvField("N/A");
					}

					// device id
					if (element.license && element.license.device.udid) {
						data += _this2.createCsvField(element.license.device.udid);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// platform
					if (element.license && element.license.device.platform) {
						data += _this2.createCsvTextField(element.license.device.platform + " " + element.license.device.model + " " + element.license.device.operatingSystem + " " + element.license.device.operatingSystemVersion);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// app version
					if (element.license && element.license.device.appVersion) {
						data += _this2.createCsvField(element.license.device.appVersion);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// store DJ Web URL
					if (element.store && element.store.djWebsiteUrl) {
						data += _this2.createCsvField(element.store.djWebsiteUrl);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// Absolute Store DJ Web URL
					if (element.store && element.store.absoluteDjWebsiteUrl) {
						data += _this2.createCsvField(element.store.absoluteDjWebsiteUrl);
					} else {
						data += _this2.createCsvField("N/A");
					}

					if (isSuperAdminView) {
						//Time Zone
						if (element.store && element.store.timeZone) {
							data += _this2.createCsvTextField(element.store.timeZone);
						} else {
							data += _this2.createCsvField("N/A");
						}

						if (element.store && element.store.playbackSchedule) {
							var playbackScheduleString = "";
							var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
							element.store.playbackSchedule.items.forEach(function (item) {
								playbackScheduleString = playbackScheduleString + weekday[item.dayOfWeek] + " - " + item.from + " to " + item.to + " // ";
							});
							data += _this2.createCsvTextField(playbackScheduleString);
						} else {
							data += _this2.createCsvField("N/A");
						}

						//Support Email
						if (element.store && element.store.client && element.store.client.supportEmail) {
							data += _this2.createCsvTextField(element.store.client.supportEmail);
						} else {
							data += _this2.createCsvField("N/A");
						}
					}

					// last ping
					if (element.license && element.license.device && element.license.device.extra && element.license.device.extra.lastPingTimestamp && element.license.device.extra.lastPingTimestamp.iso8601) {
						data += _this2.createCsvField(moment(element.license.device.extra.lastPingTimestamp.iso8601).format("YYYY-MM-DD HH:mm:ss"));
					} else {
						data += _this2.createCsvField("N/A");
					}

					// internal IP
					if (element.license && element.license.device && element.license.device.extra && element.license.device.extra.lastPingInternalIP) {
						data += _this2.createCsvField(element.license.device.extra.lastPingInternalIP);
					} else {
						data += _this2.createCsvField("N/A");
					}

					// external IP
					if (element.license && element.license.device && element.license.device.extra && element.license.device.extra.lastPingExternalIP) {
						data += _this2.createCsvField(element.license.device.extra.lastPingExternalIP.replace(',', ' '));
					} else {
						data += _this2.createCsvField("N/A");
					}

					// mac address
					if (element.license && element.license.device && element.license.device.extra && element.license.device.extra.lastPingMACAddress) {
						data += _this2.createCsvField(element.license.device.extra.lastPingMACAddress, true);
					} else {
						data += _this2.createCsvField("N/A", true);
					}
				});
				var blob = new Blob([headers + data]);
				var downloadDate = moment().format('YYYY-MM-DD');;
				if (window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveBlob(blob, "store-device-summary_" + downloadDate + ".csv");
				} else {
					var anchor = window.document.createElement("a");
					var url = window.URL.createObjectURL(blob, {
						type: "text/csv"
					});
					anchor.href = url;
					anchor.download = "summary_" + downloadDate + ".csv";
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					window.URL.revokeObjectURL(url);
				}
				blob = null;
			},

			toggleRebootRequests: function toggleRebootRequests() {
				this.set('showRebootRequests', !this.get('showRebootRequests'));
				this.initDateTimePickerFormat();
			},

			rebootRequestChanged: function rebootRequestChanged(udid) {
				if (this.$('#reboot-' + udid).is(':checked')) {
					// Include this from the reboot set.
					var rebootRequestDeviceUdids = this.get('rebootRequestDeviceUdids');
					rebootRequestDeviceUdids.push(udid);
					this.set('rebootRequestDeviceUdids', rebootRequestDeviceUdids);
				} else {
					// Exclude this from the reboot set.
					var _rebootRequestDeviceUdids = this.get('rebootRequestDeviceUdids');
					_rebootRequestDeviceUdids.splice(_rebootRequestDeviceUdids.indexOf(udid), 1);
					this.set('rebootRequestDeviceUdids', _rebootRequestDeviceUdids);
				}
			},

			saveRebootRequests: function saveRebootRequests() {
				var _this3 = this;

				var _this = this;
				var user = this.get('currentUser');

				// Get array of unique udids.
				var rebootRequestDeviceUdids = this.get('rebootRequestDeviceUdids').filter(function (x, i, a) {
					return a.indexOf(x) == i;
				});

				_this.get('applicationController').displayModal({
					title: "Confirm Reboot",
					message: "Are you sure you want to reboot " + rebootRequestDeviceUdids.length + " device(s)?",
					primaryButtonFunction: function primaryButtonFunction() {

						// Process the time and collect the reboot request UDID.
						var rebootDateTime = _this.get('rebootDateTime');
						rebootDateTime = moment.tz(rebootDateTime, 'DD/MM/YYYY h:mm A', "Australia/Melbourne").toISOString(true);

						_this3.get('apiClient').rpc('addRebootRequests', {
							deviceUDIDs: rebootRequestDeviceUdids,
							notBeforeTimestamp: rebootDateTime
						}, user.adminToken).then(function (response) {
							if (response && response.error) {
								console.log('API error: %o', response.error);
								_this.get('applicationController').displayModal({
									title: 'Sorry, we could not save the reboot request.',
									message: response.error && response.error.message ? response.error.message : "An unexpected error occurred."
								});
								return;
							}

							_this.get('applicationController').displayModal({ title: "Success!", message: "Reboot requests saved successfully!" });
						}).catch(function (errorObject) {
							console.log('errorObject : %o', errorObject);
							_this.get('applicationController').displayModal({
								title: 'Sorry, we could not save the reboot requests.',
								message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
							});
						});
					},
					secondaryButtonLabel: 'Cancel',
					secondaryButtonFunction: function secondaryButtonFunction() {
						// Do nothing, will close.
					}
				});
			}

		},

		initRebootRequestUdids: function initRebootRequestUdids() {
			// Set/Reset the rebootRequestDeviceUdids
			var rows = this.get('rows');
			var rebootRequestDeviceUdids = rows.filter(function (row) {
				return row.license != null && row.store != null && row.license.device.platform == "Headless";
			}).map(function (i) {
				return i.license.device.udid;
			});
			this.set('rebootRequestDeviceUdids', rebootRequestDeviceUdids);
			console.log(rebootRequestDeviceUdids);
		},

		initDateTimePickerFormat: function initDateTimePickerFormat() {
			$('#rebootDateTimePicker').datetimepicker({
				format: 'DD/MM/YYYY h:mm A'
			});
		},

		createCsvField: function createCsvField(data, isLast) {
			if (data != null) return data.toString() + (isLast ? '\n' : ',');else return '' + (isLast ? '\n' : ',');
		},

		createCsvTextField: function createCsvTextField(text, isLast) {
			if (text != null) {
				var value = text.toString().trim();

				// escape any double quotes in value.
				value = value.replace('"', '"""');

				// surround value with double quotes if there is a comma
				if (value.indexOf(',') > -1) {
					value = '"' + value + '"';
				}

				// add new line if this is the last valuefor the row, or a comma if not.
				return value + (isLast ? '\n' : ',');
			} else {
				return 'N/A' + (isLast ? '\n' : ',');
			}
		},

		filterRows: function filterRows() {
			// If we are not a super admin, we do a local search
			return this.get('isSuperAdminView') ? this.remoteFilter() : this.localFilter();
		},

		// The row item here is a StoreLicenseLink model.
		// The matches() function performs a lowercased search
		// against the computed searchString property, which itself
		// calls an equivalent property on the Store model.
		// The computed properties are only ever updated once
		// a dependent property changes, which reduces the amount
		// of work done.
		//
		// To modify what properties to match on, edit those functions.
		localFilter: function localFilter() {
			var searchTerm = (this.currentSearchTerm || '').toLowerCase();

			// If we have a search input, filter the rows.
			if (searchTerm.length >= this.get('minSearchTermLength')) {
				var filteredRows = this.get('allRows').filter(function (row) {
					return row.matches(searchTerm);
				});

				this.set("rows", filteredRows);
			} else if (this.get('isSuperAdminView')) {
				this.set('rows', []);
			} else {
				this.set('rows', this.get("allRows"));
			}
		},

		// This triggers a callback in the stores-devices/index route
		// which replaces all the data with fresh results from the API
		remoteFilter: function remoteFilter() {
			var searchTerm = (this.currentSearchTerm || '').toLowerCase();
			this.get('searchCallback')(searchTerm);
		}
	});
});