define('storeplay-admin/components/forms/system-settings/playlist-suffix-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),

		settingsKey: '',
		settingsValue: '',

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		init: function init(args) {
			this._super(args);
			// The component has been created in memory
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			// Component was added to the DOM
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			// Component is about to be removed from the DOM
		},

		actions: {
			save: function save() {
				var _this = this;

				var errors = this.performValidation();
				var key = this.get('settingsKey');
				var value = this.get('settingsValue');

				if (errors.length != 0) {
					var errorMessage = errors.join("<br />");
					this.get('applicationController').displayModal({ title: "Validation Error", message: errorMessage });
				} else {
					// There were no errors. We can save all the information.
					var apiClient = this.get('apiClient');

					value = value.trim().replace(/\r\n|\r|\n/g, "|");

					var returnValue = apiClient.rpc('setSystemSetting', {
						key: key,
						value: value
					}).then(function (result) {

						_this.get('applicationController').displayModal({ title: "Success!", message: "Playlist suffixes updated successfully!" });
						_this.set('settingsValue', result != null ? result.value.replaceAll('|', "\r\n") : 'null');
					}).catch(function (errorObject) {
						console.log('errorObject : %o', errorObject);
						_this.get('applicationController').displayModal({
							title: 'Sorry, we could not save the playlist suffix settings.',
							message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
						});
					});
				}
			}
		},

		performValidation: function performValidation() {

			// Make sure the fields aren't empty.
			var isEmptyChecks = {
				"playListSuffix": { value: this.get('settingsValue'), message: "Playlist suffixes cannot be empty." }
			};

			var errors = [];

			for (var check in isEmptyChecks) {
				if (this.isEmpty(isEmptyChecks[check].value)) {
					errors.push(isEmptyChecks[check].message);
				}
			}

			return errors;
		},

		isEmpty: function isEmpty(value) {
			return value == null || value == "";
		}

	});
});