define('storeplay-admin/controllers/authenticated', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		currentUser: Ember.inject.service(),
		session: Ember.inject.service('session')
	});
});