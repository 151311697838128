define('storeplay-admin/components/timezone-selector', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		emptyValue: 'Not set (guess from store/device location)',
		didChange: function didChange(event) {
			var callback = this.get('callback');
			Ember.assert("The `callback` property needs to be set when using `option-select`!", callback != null && callback instanceof Function);

			var value = event.target.value == this.get('emptyValue') ? null : event.target.value;

			callback(value);
		}
	});
});