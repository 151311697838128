define('storeplay-admin/models/client-playlist-subscription-event', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ClientPlaylistSubscriptionEvent = Ember.Object.extend({

		clientID: null,
		clientName: null,
		playlistID: null,
		playlistName: null,
		playlistCategoryID: null,
		playlistCategoryName: null,
		added: null,
		removed: null,
		userID: null,
		userName: null,
		addedFormatted: Ember.computed('added', function () {
			return this.get('added') ? this.get('added').format('DD/MM/YYYY h:mm A') : "";
		}),
		removedFormatted: Ember.computed('removed', function () {
			return this.get('removed') ? this.get('removed').format('DD/MM/YYYY h:mm A') : "";
		}),
		toJSON: function toJSON() {
			var json = {};

			json["clientID"] = this.get("clientID");
			json["clientName"] = this.get("clientName");
			json["playlistID"] = this.get("playlistID");
			json["playlistName"] = this.get("playlistName");
			json["playlistCategoryID"] = this.get("playlistCategoryID");
			json["playlistCategoryName"] = this.get("playlistCategoryName");

			var added = this.get('added');
			if (added) {
				json["added"] = this.get('added').format();
			}

			var removed = this.get('removed');
			if (removed) {
				json["removed"] = this.get('removed').format();
			}

			json["userID"] = this.get("userID");
			json["userName"] = this.get("userName");

			return json;
		}
	});

	ClientPlaylistSubscriptionEvent.reopenClass({
		fromJSON: function fromJSON(cpseObject) {
			var cpse = this.create({
				clientID: cpseObject["clientID"] || null,
				clientName: cpseObject["clientName"] || null,
				playlistID: cpseObject["playlistID"] || null,
				playlistName: cpseObject["playlistName"] || null,
				playlistCategoryID: cpseObject["playlistCategoryID"] || null,
				playlistCategoryName: cpseObject["playlistCategoryName"] || null,
				added: cpseObject["addedUtc"] ? (0, _moment.default)(cpseObject["addedUtc"]["iso8601"]) : null,
				removed: cpseObject["removedUtc"] ? (0, _moment.default)(cpseObject["removedUtc"]["iso8601"]) : null,
				userID: cpseObject["userID"] || null,
				userName: cpseObject["userName"] || null
			});
			return cpse;
		}
	});

	exports.default = ClientPlaylistSubscriptionEvent;
});