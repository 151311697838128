define('storeplay-admin/services/audio-player', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		// !!!!
		// NOTE: This is an old version of the audio player. This will be removed once storeplay-audio-player
		// song-player-view component have been completed and integrated completely.

		// A /super simple/ audio player which allows for playing a single `preview-playlist-song` at a time.
		// It takes in an array of `preview-playlist-song` and will play them one by one until the end.

		// Attempting to play another song or list of songs will stop any current playlist. 
		// It will fire the events: songStarted, songDidTick, songSeeked, songFinished appropriately.

		// It utilises an HTMLAudio object, which is a wrapper around the HTML5AudioElement WebAPI (defined at the bottom of this file).
		// It provides an easier way to interact with the audio file and its state/information.

		apiClient: Ember.inject.service(),
		currentUser: Ember.inject.service(),

		// #region - Current song and audio state.

		songsToPlay: null,

		currentSong: null,
		currentSongAudio: null,

		// #region - Event callbacks that can be set when using the audio player.

		songBuffered: function songBuffered(songThatBuffered, bufferedRange) {}, // Called periodically when the song is buffering
		songStarted: function songStarted(songThatStarted) {}, // Called when a song starts playing.
		songDidTick: function songDidTick(songThatTicked, currentTime) {}, // Called a specified number of times per seconds.
		songSeeked: function songSeeked(songThatSeeked, seekedTime) {}, // Called when the song has seeked to a new position.
		songFinished: function songFinished(songThatFinished) {}, // Called when a song has finished, either by reaching the end of the song, or by user interaction.

		// #region - Public methods

		playSongs: function playSongs(songs) {
			if (songs && songs.length > 0) {

				if (this.currentSongAudio) {
					this.stop();
				}

				this.songsToPlay = songs;
				this.play(this.songsToPlay[0]);
			}
		},
		play: function play(song) {
			if (this.currentSongAudio) {
				this.stop();
			}

			var newAudio = HTMLAudio(song.streamURL, { ticksPerSecond: 3, autoplay: true });
			newAudio.setOnBuffered(this.onSongBuffered.bind(this));
			newAudio.setOnTick(this.onSongTicked.bind(this));
			newAudio.setOnEnd(this.onSongEnded.bind(this));

			this.currentSong = song;
			this.currentSongAudio = newAudio;
			if (this.songStarted) {
				this.songStarted(song);
			}
		},


		stop: function stop() {
			if (this.currentSongAudio) {
				var songThatFinished = this.currentSong;

				this.currentSongAudio.pause();
				this.currentSong = null;
				this.currentSongAudio = null;

				if (this.songFinished) {
					this.songFinished(songThatFinished);
				}
			}
		},

		seek: function seek(position) {
			if (this.currentSongAudio) {
				this.currentSongAudio.seek(position);
				if (this.songSeeked) {
					this.songSeeked(this.currentSong, position);
				}
			}
		},

		// #region - Private handlers for the HTMLAudio

		onSongBuffered: function onSongBuffered() {
			if (this.currentSongAudio) {
				var bufferedRange = this.currentSongAudio.getBufferedRange();
				if (this.songBuffered) {
					this.songBuffered(this.currentSong, this.currentSongAudio.getBufferedRange());
				}
			}
		},

		onSongTicked: function onSongTicked() {
			if (this.currentSong) {
				if (this.songDidTick) {
					this.songDidTick(this.currentSong, this.currentSongAudio.getCurrentTime());
				}
			}
		},

		onSongEnded: function onSongEnded() {

			if (this.currentSongAudio) {

				var songThatFinished = this.currentSong;

				// Need to make sure all song stop events have been fired.
				this.stop();

				// Check if there are any other songs we need to play.
				if (this.songsToPlay && this.songsToPlay.length > 1) {
					var indexOfSongThatFinished = this.songsToPlay.findIndex(function (element) {
						return element.id == songThatFinished.id;
					});

					var nextSongToPlayIndex = indexOfSongThatFinished == this.songsToPlay.length - 1 ? null : indexOfSongThatFinished + 1;
					if (nextSongToPlayIndex) {
						this.play(this.songsToPlay[nextSongToPlayIndex]);
					} else {
						// We have finished the list of songs that are ready to play.
						this.songsToPlay = null;
					}
				}
			}
		}
	});


	// HTML5 Audio Element Wrapper to play audio.
	// TODO: Refactor this whole file into a better AudioPlayer in general.
	// Currently very specific to the way the preview-playlist functions.
	// Can change this to be much more generic, allowing the playback of songs anywhere 
	// in the admin.
	// TODO-UPDATE: 4/10/18 : Refactoring in progress.

	var AudioState = Object.freeze({
		PREINIT: "preinit",
		READY: "ready",
		PLAYING: "playing",
		PAUSED: "paused",
		BUFFERING: "buffering",
		ENDED: "ended"
	});

	var AudioBase = function () {

		var me = {};
		var sound;

		// Must implement these in "subclasses".
		// Callbacks
		me.setOnReady = function (callback) {};
		me.setOnPlay = function (callback) {};
		me.setOnPause = function (callback) {};
		me.setOnEnd = function (callback) {};
		me.setOnTick = function (callback) {};
		me.setOnSeeked = function (callback) {};

		// Controls for the audio.
		me.play = function () {};
		me.pause = function () {};
		me.seek = function (time) {};
		me.setSpeed = function (speed) {};

		// Info
		me.isReady = function () {};
		me.isPlaying = function () {};
		me.getCurrentTime = function () {};
		me.getDuration = function () {};
		me.getBufferedRange = function () {};

		return me;
	}();

	var HTMLAudio = function HTMLAudio(url, config) {

		var me = Object.create(AudioBase);
		var sound = {}; // Holds our html audio element.

		var currentStatus = AudioState.PREINIT;

		var intervalTime = 1000; // The time between ticks. Defaults to 1 update per second.
		var intervalID = 0; // Store the current interval loop ID so we can cancel it.
		var bufferIntervalID = 0; // The currently buffered loop so we can see how much has buffered and display it.

		var hasBeenCancelled = false;

		// Audio Status

		// For tracking the status of an audio source.
		var currentStatus = AudioState.PREINIT;

		var onStatusChange = function onStatusChange() {
			console.log("Audio status changed to: " + currentStatus);
		};

		me.getStatus = function () {
			return currentStatus;
		};

		me.setStatus = function (newStatus) {
			if (newStatus) {
				currentStatus = newStatus;
				onStatusChange();
			}
		};

		me.setOnStatusChange = function (callback) {
			onStatusChange = callback;
		};

		// Callbacks and Public Properties

		me.autoplay = false;
		me.startAt = 0.0;
		me.loop = false;
		me.buffered = { start: 0.0, end: 0.0 };

		// I want the update time to be consistent and variable, so I will deal with the 
		// ticks myself instead of relying on the HTML <audio> ontimeupdate which seems to
		// fire randomly.
		var onTick = [];
		me.setOnTick = function (callback) {
			onTick.push(callback);
		};

		// We want to be notified periodically that the buffered state has been updated.
		var onBuffered = [];
		me.setOnBuffered = function (callback) {
			onBuffered.push(callback);
		};

		me.setOnReady = function (callback) {
			sound.addEventListener("loadeddata", callback);
		};
		me.setOnPlay = function (callback) {
			sound.addEventListener("playing", callback);
		};
		me.setOnPause = function (callback) {
			sound.addEventListener("pause", callback);
		};
		me.setOnEnd = function (callback) {
			sound.addEventListener("ended", callback);
		};
		me.setOnSeeked = function (callback) {
			sound.addEventListener("seeked", callback);
		};

		// Controls for the audio.
		me.play = function () {
			sound.play();
		};
		me.pause = function () {
			// If we stop the song before it has started, we should make sure we don't actually play it anyway.
			var status = this.getStatus();
			if (status === AudioState.PREINIT || status === AudioState.READY) {
				hasBeenCancelled = true;
			}
			sound.pause();
		};
		me.seek = function (time) {
			sound.currentTime = time;
		};
		me.setSpeed = function (speed) {
			if (speed > 0.0) {
				sound.playbackRate = Math.min(speed, 2.0);
			}
		};

		// Info
		me.isReady = function () {
			return this.getStatus() === AudioState.READY;
		};
		me.isPlaying = function () {
			return this.getStatus() === AudioState.PLAYING;
		};
		me.getCurrentTime = function () {
			return sound.currentTime;
		};
		me.getDuration = function () {
			return sound.duration;
		};
		me.getBufferedRange = function () {
			return me.buffered;
		};

		// Initialisation
		var init = function init(url, config) {

			sound = new Audio(url);

			if (sound) {

				// Keep the current status updated.
				this.setOnReady(function () {
					me.setStatus(AudioState.READY);
				});
				this.setOnPlay(function () {
					me.setStatus(AudioState.PLAYING);
				});
				this.setOnPause(function () {
					me.setStatus(AudioState.PAUSED);
				});
				this.setOnEnd(function () {
					me.setStatus(AudioState.ENDED);
				});

				// CONFIGURATION

				// Config object can contain:
				//
				//	startAt: float,
				//	autoplay: bool,
				//	speed:  float,
				//	loop: bool,
				//	ticksPerSecond: int	
				if (config) {
					// Allow starting times to be specified.
					if (config.startAt) {
						this.startAt = config.startAt;
					}

					// Allow autoplay.
					if (config.autoplay) {
						this.setOnReady(function () {
							if (!hasBeenCancelled) {
								me.seek(me.startAt);
								me.play();
							}
						});
					} else {
						this.setOnReady(function () {
							if (me.startAt > 0) {
								me.seek(me.startAt);
							}
						});
					}

					// Allow looping.
					if (config.loop) {
						sound.loop = true;
					}

					// Configure the speed.
					if (config.speed) {
						this.setSpeed(config.speed);
					}

					// Updates per second.
					if (config.ticksPerSecond) {
						intervalTime = 1000 / config.ticksPerSecond;
					}
				}

				// Update LOOPS
				// Start our playing update loop, but only when the audio is playing.
				this.setOnPlay(function () {
					intervalID = setInterval(function () {
						for (var i = 0; i < onTick.length; i++) {
							onTick[i]();
						}
					}, intervalTime);
				});

				this.setOnPause(function () {
					clearInterval(intervalID);
				});

				this.setOnEnd(function () {
					clearInterval(intervalID);
				});

				// Buffering update loop
				var updateBuffered = function updateBuffered() {
					var length = sound.buffered.length;
					if (length) {
						me.buffered.start = sound.buffered.start(length - 1);
						me.buffered.end = sound.buffered.end(length - 1);

						// Let everyone know that the buffered range was updated.
						for (var i = 0; i < onTick.length; i++) {
							onBuffered[i]();
						}

						// Stop updating the amount we have buffered if it has fully loaded.
						if (me.buffered.start === 0.0 && me.buffered.end >= me.getDuration()) {
							clearInterval(bufferIntervalID);
						}
					}
				};

				this.setOnReady(function () {
					bufferIntervalID = setInterval(updateBuffered, 333); // Thrice a second
				});

				// If the sound is cached by the browser, then the onready doesn't get fired. (?)
				// Check the ready state here and then update the status.
				if (sound.readyState > 0) {
					// Fire the event manually.
					var event = new CustomEvent("loadeddata", {});
					sound.dispatchEvent(event);
				}
			}
			// Couldn't create an audio object.
			else {
					throw {
						name: "NoAudioError",
						message: "Unable to create the HTML audio object."
					};
				}
		};

		init.apply(me, [url, config]);

		return me;
	};
});