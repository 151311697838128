define('storeplay-admin/routes/authenticated', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		currentUser: Ember.inject.service(),
		session: Ember.inject.service(),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		beforeModel: function beforeModel() {
			var _this = this;

			Ember.Logger.log('route/authenticated-route.beforeModel - start, about load current user');
			return this.get('currentUser').load().then(function () {
				Ember.Logger.log('route/authenticated-route.beforeModel - in then block of current user load, getting local handle on user');
				var user = _this.get('currentUser');
				Ember.Logger.log('route/authenticated-route.beforeModel - super admin: ' + user.get('isAdminSuperUser') + ', client ID: ' + user.get('clientID') + ', continue as admin: ' + user.get('continueAsAdmin') + ', route name: ' + _this.routeName);

				// If the user signed in as a super admin user, but didn't choose either 'continueAsAdmin' or a client (and we're not on the admin-user-choosing screen), then we bump them out
				if (user.get('isAdminSuperUser') && user.get('clientID') == 0 && user.get('continueAsAdmin') == false && _this.routeName != 'admin') {
					Ember.Logger.log('route/authenticated-route.beforeModel - believe we have a super admin who hasn\'t indicated client or super role, transitioning back to /admin route');
					_this.get('session').invalidate();
				}

				// TODO : Check authentication for the type of user against the route name
			}).catch(function () {
				Ember.Logger.log('route/authenticated-route.beforeModel - in catch block of current user load');
				if (_this.get('session.isAuthenticated')) {
					Ember.Logger.log('route/applauthenticated-routeication.beforeModel - session is authenticated, but busted, so invalidating and starting again.');
					_this.get('session').invalidate();
				} else {
					_this.transitionTo('login');
				}
			});
		},
		afterModel: function afterModel() {
			Ember.Logger.log('authenticated.js - after model');
		}
	});
});