define('storeplay-admin/routes/pass-through', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
		session: Ember.inject.service(),

		beforeModel: function beforeModel(transition) {
			console.log('transition: %o', transition);
			var token = transition.queryParams.token;
			console.log('token: %o', token);
			if (token) {
				// If they're not already authenticated (as we'd expect), then pass them through
				if (!this.get('session.isAuthenticated')) {
					this.authenticateWithToken(token);
					return;
				} else {
					// Otherwise... I don't know, just send them to the home page
					this.transitionTo('index');
				}
			}
			// No token, just go to the default route, they'll work it out
			else {
					this.transitionTo('index');
				}
		},
		authenticateWithToken: function authenticateWithToken(token) {
			this.get('session').authenticate('authenticator:pass-through', token).then(function () {
				// Do nothing, once the authentication happens this will be picked up by the appliation route event handlers.		
			}).catch(function () {
				//this.set('errorMessage', reason.error || reason);
				toastr.info("Login Failed: Incorrect username or password.", "", { positionClass: 'toast-top-full-width' });
			});
		}
	});
});