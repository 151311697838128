define('storeplay-admin/controllers/authenticated/stores-devices/edit', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		actions: {

			// New actions for refactored edit form.

			onDidCreateStore: function onDidCreateStore(newStore) {
				this.transitionToRoute('authenticated.stores-devices.edit', { queryParams: { storeID: newStore.id, licenseID: null } });
			},
			onDidLinkStore: function onDidLinkStore(storeID, licenseID) {
				this.transitionToRoute('authenticated.stores-devices.edit', { queryParams: { storeID: storeID, licenseID: licenseID } });
			},
			onDidLinkDevice: function onDidLinkDevice(storeID, licenseID) {
				this.transitionToRoute('authenticated.stores-devices.edit', { queryParams: { storeID: storeID, licenseID: licenseID } });
			},
			onDidUnlink: function onDidUnlink(storeID) {
				this.transitionToRoute('authenticated.stores-devices.edit', { queryParams: { storeID: storeID, licenseID: null } });
			},
			onDidDeleteStore: function onDidDeleteStore(storeAndLicense) {
				if (storeAndLicense.license) {
					this.transitionToRoute('authenticated.stores-devices.edit', { queryParams: { storeID: null, licenseID: storeAndLicense.license.id } });
				} else {
					this.transitionToRoute('authenticated.stores-devices.index');
				}
			},
			onDidDeleteDevice: function onDidDeleteDevice(storeAndLicense) {
				if (storeAndLicense.store) {
					this.transitionToRoute('authenticated.stores-devices.edit', { queryParams: { storeID: storeAndLicense.store.id, licenseID: null } });
				} else {
					this.transitionToRoute('authenticated.stores-devices.index');
				}
			}
		},

		// TODO: Validation.
		// TODO: Move to component.
		validateModel: function validateModel(model) {

			var validationErrors = [];
			if (model.storeAndLicense.store) {
				var store = model.storeAndLicense.store;

				if (store.get('publicName') == null || store.get('publicName') == '') {
					validationErrors.push("Please enter a 'Display name' for the store");
				}

				if (store.get('latitude') == null || store.get('latitude') == 0 || store.get('longitude') == null || store.get('longitude') == 0) {
					validationErrors.push("Please set a map location for the store");
				}

				if (store.get('iso2CountryCode') == null || store.get('iso2CountryCode') == '') {
					validationErrors.push("Please select a country for the store");
				}

				if (store.get('headlessAccessCode') != null && store.get('headlessAccessCode').length > 8) {
					validationErrors.push("Headless PIN must be 8 characters or less");
				}
			}

			if (validationErrors.length > 0) {
				var errors = '';
				errors += '<ul>';
				validationErrors.forEach(function (error) {
					errors += '<li>' + error + '</li>';
				});
				errors += '</ul>';

				this.get('applicationController').displayModal({
					title: 'Please check the information of the store',
					message: Ember.String.htmlSafe(errors)
				});
				return false;
			}

			return true;
		}
	});
});