define('storeplay-admin/components/dashboard/newest-clients-panel', ['exports', 'storeplay-admin/models/client', 'storeplay-admin/config/environment'], function (exports, _client, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _Ember$Component$exte;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = Ember.Component.extend((_Ember$Component$exte = {

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),
		apiBaseURL: _environment.default.apiBaseURL,

		numberOfColumns: 6,
		tagName: "", // This ensures the component is not wrapped in an ember-view div, which is not needed for this component.

		isFetchingData: true,
		errorFetchingData: false

	}, _defineProperty(_Ember$Component$exte, 'apiBaseURL', _environment.default.apiBaseURL), _defineProperty(_Ember$Component$exte, 'init', function init(args) {
		// The component has been created in memory
		this._super(args);

		this.set("newClients", []);
	}), _defineProperty(_Ember$Component$exte, 'didInsertElement', function didInsertElement() {
		// Component was added to the DOM
		this._super.apply(this, arguments);

		this.set("randomNumber", Math.random());

		this.fetchNewClientData();
	}), _defineProperty(_Ember$Component$exte, 'willDestroyElement', function willDestroyElement() {
		// Component is about to be removed from the DOM
		this._super.apply(this, arguments);
	}), _defineProperty(_Ember$Component$exte, 'actions', {}), _defineProperty(_Ember$Component$exte, 'fetchNewClientData', function fetchNewClientData() {
		var _this = this;
		var apiClient = this.get('apiClient');

		var getNewIndependentsCall = apiClient.rpc('getNewIndependentClients', { numberOfClients: 7, includeRelationships: null });
		var getSubscriptionPlansCall = apiClient.rpc('getAllSubscriptionPlans');

		Promise.all([getNewIndependentsCall, getSubscriptionPlansCall]).then(function (results) {
			var clientJSONObjects = results[0];
			var subscriptionPlans = results[1];

			var clients = _this.attachSubscriptionDetailsToClients(clientJSONObjects, subscriptionPlans);
			_this.set("newClients", clients);
			_this.set("isFetchingData", false);
		}).catch(function (error) {
			_this.set("isFetchingData", false);
			_this.set("errorFetchingData", true);
			console.log(error);
		});
	}), _defineProperty(_Ember$Component$exte, 'attachSubscriptionDetailsToClients', function attachSubscriptionDetailsToClients(clientJSONObjects, subscriptionPlans) {

		// Create the client models from the JSON
		var newIndependentClients = clientJSONObjects.map(function (json) {
			var client = _client.default.fromJSON(json);

			if (json.extra) {
				client.extra = {
					subscriptionStartedDate: moment(json.extra.subscriptionStartedDate),
					subscriptionPlanID: json.extra.subscriptionPlanID
				};
			}

			return client;
		});

		// Sort the clients.
		var sortedClients = newIndependentClients.sort(function (left, right) {
			return left.extra.subscriptionStartedDate.isBefore(right.extra.subscriptionStartedDate) ? 1 : -1;
		});

		// Given a plan ID, update the name accordingly.
		sortedClients.forEach(function (client) {
			client.extra.subscriptionPlanName = subscriptionPlans.filter(function (plan) {
				return plan.id === client.extra.subscriptionPlanID;
			})[0].name;
		});

		return sortedClients;
	}), _Ember$Component$exte));
});