define('storeplay-admin/controllers/forgotten-password', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		apiClient: Ember.inject.service('api-client'),
		isSubmitted: false,
		actions: {
			forgottenPassword: function forgottenPassword() {
				var _this = this;
				// validate required field.
				var username = this.get('username');
				if (username == null) {
					this.set('errorMessage', 'Username is a required field.');
					return;
				}

				var apiClient = this.get('apiClient');

				apiClient.rpc('addAdminPasswordReset', {
					username: username
				}, null, true).then(function (result) {
					_this.set('errorMessage', null);
				}).catch(function (errorObject) {});
				// Always show successful submission.
				// For security reasons we avoid and feedback.
				this.set('isSubmitted', true);
			}
		}

	});
});