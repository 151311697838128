define('storeplay-admin/components/controls/binary-switch', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		utility: Ember.inject.service("utility"),

		id: "value",
		enabledValue: "YES",
		disabledValue: "NO",

		enabled: true,

		options: ["Active", "Old", "New"],

		onEnable: function onEnable(id) {},
		onDisable: function onDisable(id) {},

		init: function init(args) {
			this._super(args);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component has now been added to the dom and ready to be manipulated.
			// Anything to do here?
		},

		willDestroyElement: function willDestroyElement() {},

		actions: {
			enable: function enable() {
				this.set("enabled", true);
				this.onEnable(this.get("id"));
			},

			disable: function disable() {
				this.set("enabled", false);
				this.onDisable(this.get("id"));
			},

			selectOption: function selectOption(option) {
				console.log(option);
			}
		}

	});
});