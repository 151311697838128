define('storeplay-admin/services/geolocation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	Number.prototype.toRadians = function () {
		return this * Math.PI / 180;
	};
	exports.default = Ember.Service.extend({
		getDistance: function getDistance(latA, lonA, latB, lonB) {
			if (latA == null && lonA == null) {
				return null;
			} else {
				var R = 6371; // km 
				var x1 = latB - latA;
				var x2 = lonB - lonA;
				var dLat = x1.toRadians();
				var dLon = x2.toRadians();
				var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(latA.toRadians()) * Math.cos(latB.toRadians()) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
				var d = R * c;
				return d * 1000;
			}
		},
		convertDistance: function convertDistance(d) {
			if (d == null) {
				return;
			} else {
				var distance = d.toFixed(2);
				if (distance < 1000) {
					return '(' + d.toFixed(0).toString() + 'm from store)';
				} else if (distance > 1000 && distance < 10000) {
					return '(' + (d / 1000).toFixed(1).toString() + 'km from store)';
				} else {
					return '(' + (distance / 1000).toFixed(1).toString() + 'km from store)';
				}
			}
		}
	});
});