define("storeplay-admin/components/tables/churn-summary-table", ["exports", "storeplay-admin/components/sortable-table/sortable-table-base"], function (exports, _sortableTableBase) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		columns: [{ title: "", sorter: null, width: "200px" }, // Using the first column for row titles.
		{ title: "Value", sorter: null, width: "200px" }, { title: "Notes", sorter: null, width: "400px" }]

	});
});