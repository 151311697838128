define('storeplay-admin/components/forms/amcos-bms-stores-report-form', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service("current-user"),
		ajax: Ember.inject.service(),

		// this report component is emberfied compared to the other reports that use jQuery mainly.
		fromDate: moment().format('YYYY-MM') + "-1",
		fromDateOptions: Ember.computed('', function () {
			// Add the options to the date selector
			var now = moment();
			var fromDates = [];
			for (var i = 0; i < 24; i++) {
				fromDates.pushObject({
					value: now.format('YYYY-MM') + "-1",
					text: now.format('MMM YYYY')
				});
				now.subtract(1, 'months');
			}
			return fromDates;
		}),
		numberOfMonths: 1,
		numberOfMonthsOptions: ["1", "2", "3", "4", "5", "6"],
		isoCountryCode: "AU",
		isoCountryCodeOptions: ["AU", "NZ"],

		init: function init(args) {
			this._super(args);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		actions: {

			generate: function generate() {

				var currentUser = this.get("currentUser");
				var reportURL = _environment.default.apiBaseURL + "/admin-reports/amcos-bms-stores-report" + "?authToken=" + currentUser.adminToken + "&fromDate=" + this.fromDate + "&numberOfMonths=" + this.numberOfMonths + "&isoCountryCode=" + this.isoCountryCode;
				window.location.href = reportURL;
			}
		}
	});
});