define('storeplay-admin/components/forms/subscription/exit-survey-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		// The reasons for the survey and whether or not they have been selected.
		// Corresponds to CancellationReason on the server.

		reasonIDs: ["reasonExpensive", "reasonConfusing", "reasonCrashed", "reasonNoChannel", "reasonBadMusic", "reasonOtherService", "reasonOtherPlatform", "reasonOther", "reasonStoreClosed", "reasonSoldBusiness"],

		reasonStrings: {
			reasonExpensive: "It was too expensive",
			reasonConfusing: "The app was too confusing to use",
			reasonCrashed: "It crashed too often",
			reasonNoChannel: "There wasn't a suitable channel",
			reasonBadMusic: "I thought I'd found a channel, but didn't like the music",
			reasonOtherService: "I have decided to use another service",
			reasonOtherPlatform: "There was no suitable platform available",
			reasonOther: "Other",
			reasonStoreClosed: "Store has been closed",
			reasonSoldBusiness: "I sold my business"
		},

		reasonValues: {
			reasonExpensive: 7,
			reasonConfusing: 6,
			reasonCrashed: 1,
			reasonNoChannel: 5,
			reasonBadMusic: 4,
			reasonOtherService: 3,
			reasonOtherPlatform: 2,
			reasonOther: 8,
			reasonStoreClosed: 9,
			reasonSoldBusiness: 10
		},

		selectedReason: null,
		alternativeService: null,
		otherComments: null,
		clientEmail: Ember.computed('currentUser', function () {
			var currentUser = this.get('currentUser');
			console.log(currentUser.clientUser.emailAddress);
			return currentUser.clientUser.emailAddress;
		}),
		clientCompanyName: Ember.computed('currentUser', function () {
			var currentUser = this.get('currentUser');
			console.log(currentUser.client.internalName);
			return currentUser.client.internalName;
		}),

		// Events - These should be passed in to the component from the template.

		onSurveyContentSubmitted: null,

		// Initialisation

		init: function init(args) {
			this._super(args);

			// The component has been created in memory
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			// Component was added to the DOM
			this.sendFreshworksCancellationEvent();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
			// Can do any cleanup here.
		},

		actions: {
			selectReason: function selectReason(reasonID) {
				// We should reset the selected reason back to null if we are deselecting it.
				var newValue = this.get("selectedReason") == reasonID ? null : reasonID;
				this.set("selectedReason", newValue);
			},

			sendFeedback: function sendFeedback() {
				var _this = this;

				if (_this.validate()) {
					// Can send the results back to the server.
					// Send the results of the survey back to the server.
					var apiClient = this.get('apiClient');
					var clientID = this.get('currentUser.clientID');
					apiClient.rpc('submitExitSurvey', {
						clientID: clientID,
						cancellationReasonValue: this.reasonValues[this.selectedReason],
						alternativeService: this.reasonValues[this.selectedReason] == 3 ? this.alternativeService : null,
						otherComments: this.get("otherComments") }).then(function (response) {
						// Once the feedback has been sent, want to redirect back to the subscription page.
						_this.get("applicationController").displayModal({
							title: 'Feedback Sent Successfully.',
							message: "We appreciate you taking the time to provide feedback. It directly helps in creating a better StorePlay for everyone. Thanks for being a storePlay subscriber, even for only a while. If you'd ever like to come back, we'll be here.",
							primaryButtonLabel: "OK",
							primaryButtonFunction: function primaryButtonFunction() {
								if (_this.onSurveyContentSubmitted != null) {
									_this.onSurveyContentSubmitted();
								}
							}
						});
					}).catch(function (errorObject) {
						_this.get("applicationController").displayModal({
							title: 'Sorry, the attempt to send feedback has failed.',
							message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
						});
					});
				}
			}
		},

		sendFreshworksCancellationEvent: function sendFreshworksCancellationEvent() {
			var email = this.get('clientEmail');
			var companyName = this.get('clientCompanyName');
			FM.associateVisitor(email);
			FM.trackCustomEvent("Cancellation", {
				"email": email
			});
		},

		validate: function validate() {

			if (this.selectedReason == null) {
				this.get("applicationController").displayModal({
					title: 'Unable to Submit Feedback',
					message: "Please ensure that you have selected a reason."
				});
				return false;
			}

			if (this.selectedReason == "reasonOther" && this.otherComments == null) {
				this.get("applicationController").displayModal({
					title: 'Unable to Submit Feedback',
					message: "As you have selected 'other', could you please add some further detail in the 'additional comments' box."
				});
				return false;
			}

			if (this.selectedReason == "reasonOtherService" && (this.alternativeService == null || this.alternativeService == "")) {
				this.get("applicationController").displayModal({
					title: 'Unable to Submit Feedback',
					message: "Please enter the service you are moving to."
				});
				return false;
			}

			return true;
		}

	});
});