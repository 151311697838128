define('storeplay-admin/models/subscription-plan', ['exports', 'storeplay-admin/models/money'], function (exports, _money) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var subscriptionPlan = Ember.Object.extend({
		id: null,
		name: null,
		description: null,
		monthlyPrice: null,
		maxChannels: null
	});

	subscriptionPlan.reopenClass({
		fromJSON: function fromJSON(subscriptionPlanObject) {
			var subscriptionPlan = this.create({
				id: subscriptionPlanObject["id"] || null,
				name: subscriptionPlanObject["name"] || null,
				description: subscriptionPlanObject["description"] || null,
				monthlyPrice: subscriptionPlanObject["monthlyPrice"] != null ? _money.default.fromJSON(subscriptionPlanObject["monthlyPrice"]) : null,
				maxChannels: subscriptionPlanObject["maxChannels"] || null
			});
			return subscriptionPlan;
		}
	});

	exports.default = subscriptionPlan;
});