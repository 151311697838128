define('storeplay-admin/services/utility', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Utility = {

		// String Utilities
		// ################

		strings: {
			isEmpty: function isEmpty(text) {
				return text === "" || text == null;
			},

			leftPad: function leftPad(textToPad, symbol, maxChars) {
				var textLength = textToPad.length;
				var requiredPaddingLength = maxChars - textLength;

				var paddedText = textToPad;

				if (requiredPaddingLength > 0) {
					var padding = symbol.repeat(requiredPaddingLength);
					paddedText = padding + paddedText;
				}

				return paddedText;
			}
		},

		// File Utilities
		// ################

		files: {
			formatBytes: function formatBytes(bytes) {
				var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

				if (bytes === 0) return '0 Bytes';

				var k = 1024;
				var dm = decimals < 0 ? 0 : decimals;
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

				var i = Math.floor(Math.log(bytes) / Math.log(k));

				return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
			}
		},

		// Audio Utilities
		// ###############
		audio: {
			generateCurrentPlaytime: function generateCurrentPlaytime(totalDuration, currentPosition) {
				// Current Position
				var currentHours = Math.floor(currentPosition / 3600);
				var currentMinutes = Math.floor(currentPosition / 60 % 60);
				var currentSeconds = currentPosition % 60;

				var currentHoursString = Utility.strings.leftPad(currentHours.toString(), "0", 2);
				var currentMinutesString = Utility.strings.leftPad(currentMinutes.toString(), "0", 2);
				var currentSecondsString = Utility.strings.leftPad(currentSeconds.toString(), "0", 2);

				// Total Duration
				var hours = Math.floor(totalDuration / 3600);
				var minutes = Math.floor(totalDuration / 60 % 60);
				var seconds = totalDuration % 60;

				var hoursString = Utility.strings.leftPad(hours.toString(), "0", 2);
				var minutesString = Utility.strings.leftPad(minutes.toString(), "0", 2);
				var secondsString = Utility.strings.leftPad(seconds.toString(), "0", 2);

				var currentPositionText = currentHours == 0 ? currentMinutesString + ':' + currentSecondsString : currentHoursString + ':' + currentMinutesString + ':' + currentSecondsString;
				var totalDurationText = hours == 0 ? minutesString + ':' + secondsString : hoursString + ':' + minutesString + ':' + secondsString;

				var playtimeText = currentPositionText + ' / ' + totalDurationText;
				return playtimeText;
			},

			convertSecondsToHoursMinutesString: function convertSecondsToHoursMinutesString(seconds) {
				var hours = Math.floor(seconds / 3600);
				var minutes = Math.floor(seconds / 60 % 60);

				var hoursString = Utility.strings.leftPad(hours.toString(), "0", 1);
				var minutesString = Utility.strings.leftPad(minutes.toString(), "0", 2);
				var hoursMinutesString = hoursString + 'h ' + minutesString + 'm';

				return hoursMinutesString;
			},
			convertSecondsToHoursMinutesSecondsString: function convertSecondsToHoursMinutesSecondsString(seconds) {
				seconds = Number(seconds);
				var h = Math.floor(seconds / 3600);
				var m = Math.floor(seconds % 3600 / 60);
				var s = Math.floor(seconds % 3600 % 60);

				var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
				var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
				var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
				return hDisplay + mDisplay + sDisplay;
			},
			convertMinutesToHoursMinutesString: function convertMinutesToHoursMinutesString(mins) {
				var hours = Math.floor(mins / 60);
				var minutes = Math.floor(mins % 60);

				var hoursString = Utility.strings.leftPad(hours.toString(), "0", 1);
				var minutesString = Utility.strings.leftPad(minutes.toString(), "0", 2);
				var hoursMinutesString = hoursString + 'h ' + minutesString + 'm';

				return hoursMinutesString;
			}
		}
	};

	exports.default = Ember.Service.extend(Utility);
});