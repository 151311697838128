define('storeplay-admin/models/affiliate', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var RewardType = Object.freeze({
		"NoReward": 1,
		"PriorCategoryPlaylists": 2,
		"PlaylistShopCredits": 3,
		"FreeExtraSubscriptionMonths": 4,
		"FreeFirstSubscriptionMonths": 5,
		"FreeSubscriptionMonths": 6,
		"OngoingDiscount": 7,
		"FirstMonthFreePlusOngoingDiscount": 8
	});

	var RewardTypeStrings = Object.freeze({
		1: "No reward",
		2: "Prior playlists from subscribed genre",
		3: "Free playlists from playlist shop",
		4: "Free extra months of first category subscription",
		5: "Free months of first category subscription",
		6: "First month free, regardless of plan or licenses",
		7: "Ongoing, per-license discount",
		8: "First month free plus ongoing, per-license discount"
	});

	var Affiliate = Ember.Object.extend({

		id: null,
		dateCreated: null,

		firstName: null,
		lastName: null,
		emailAddress: null,
		organisation: null,
		phone: null,
		commissionRate: null,
		referralCode: null,

		swiftCode: null,
		bsb: null,
		accountNumber: null,
		accountName: null,
		paypalUsername: null,
		rewardType: null,
		rewardQuantity: null,

		toJSON: function toJSON() {
			var json = {};

			json["id"] = this.get("id");
			json["dateCreated"] = this.get("dateCreated") == null ? null : this.get("dateCreated").toISOString(), json["firstName"] = this.get("firstName");
			json["lastName"] = this.get("lastName");
			json["emailAddress"] = this.get("emailAddress");
			json["organisation"] = this.get("organisation");
			json["phone"] = this.get("phone");

			// Commission rate is store in the database as an integer. We want a float.
			json["commissionRate"] = (this.get("commissionRate") * 100).toFixed(0);
			json["referralCode"] = this.get("referralCode");

			json["swiftCode"] = this.get("swiftCode");
			json["bsb"] = this.get("bsb");
			json["accountNumber"] = this.get("accountNumber");
			json["accountName"] = this.get("accountName");
			json["payPalUsername"] = this.get("payPalUsername");
			json["rewardType"] = this.get("rewardType");

			if (this.get("rewardType") == "7" || this.get("rewardType") == "8") {
				json["rewardQuantity"] = (this.get("rewardQuantityCurrency") * 100).toFixed(0);
			} else {
				json["rewardQuantity"] = this.get("rewardQuantity");
			}

			return json;
		}
	});

	Affiliate.reopenClass({
		fromJSON: function fromJSON(affiliateJSONObject) {
			var affiliate = this.create({

				id: affiliateJSONObject["id"],
				dateCreated: moment.unix(affiliateJSONObject["dateCreated"].unixTime),

				firstName: affiliateJSONObject["firstName"],
				lastName: affiliateJSONObject["lastName"],
				emailAddress: affiliateJSONObject["emailAddress"],
				organisation: affiliateJSONObject["organisation"],
				phone: affiliateJSONObject["phone"],

				commissionRate: (affiliateJSONObject["commissionRate"] / 100).toFixed(2), // Store in the database as an integer
				referralCode: affiliateJSONObject["referralCode"],

				swiftCode: affiliateJSONObject["swiftCode"],
				bsb: affiliateJSONObject["bsb"],
				accountNumber: affiliateJSONObject["accountNumber"],
				accountName: affiliateJSONObject["accountName"],
				payPalUsername: affiliateJSONObject["payPalUsername"],

				rewardType: affiliateJSONObject["rewardType"],
				rewardTypeString: RewardTypeStrings[affiliateJSONObject["rewardType"]],
				rewardQuantity: affiliateJSONObject["rewardQuantity"],
				rewardQuantityCurrency: (affiliateJSONObject["rewardQuantity"] / 100).toFixed(2)
			});
			return affiliate;
		}
	});

	exports.default = Affiliate;
	exports.RewardType = RewardType;
});