define("storeplay-admin/components/tables/stores-devices-offline-table", ["exports", "storeplay-admin/components/sortable-table/sortable-table-base"], function (exports, _sortableTableBase) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _sortableTableBase.default.extend({

		columns: [{ title: "Store Name", sorter: "storeSorter", width: "200px" }, // Using the first column for row titles.
		{ title: "Store Email", sorter: "emailSorter", width: "200px" }, { title: "Store Phone", sorter: "phoneSorter", width: "200px" }, { title: "Last Online", sorter: "lastPlaybackSorter", width: "200px" }, { title: "Time Offline", sorter: null, width: "200px" }],
		sorters: {
			storeSorter: function storeSorter(left, right) {
				var leftName = null;
				var rightName = null;

				var nameExtractor = function nameExtractor(item) {
					if (item.store == null) {
						return null;
					}

					name = item.store.internalName ? item.store.internalName : item.store.publicName;
					if (name) {
						name = name.toLowerCase();
					}
					return name;
				};

				leftName = nameExtractor(left);
				rightName = nameExtractor(right);

				return this.orderOf(leftName, rightName);
			},
			emailSorter: function emailSorter(left, right) {

				var emailExtractor = function emailExtractor(item) {
					if (item.store == null || item.store.email == null) {
						return "";
					}
					return item.store.email;
				};
				return this.orderOf(emailExtractor(left), emailExtractor(right));
			},
			phoneSorter: function phoneSorter(left, right) {
				var phoneExtractor = function phoneExtractor(item) {
					if (item.store == null || item.store.phone == null) {
						return "";
					}
					return item.store.phone;
				};
				return this.orderOf(phoneExtractor(left), phoneExtractor(right));
			},
			lastPlaybackSorter: function lastPlaybackSorter(left, right) {

				var leftLastPlaybackDate = null;
				var rightLastPlaybackDate = null;

				if (left.license && left.lastPlaybackTimestamp) {
					leftLastPlaybackDate = moment(left.lastPlaybackTimestamp, "YYYY-MM-DD HH:mm:ss");
				}

				if (right.license && right.lastPlaybackTimestamp) {
					rightLastPlaybackDate = moment(right.lastPlaybackTimestamp, "YYYY-MM-DD HH:mm:ss");
				}

				return this.orderOf(leftLastPlaybackDate, rightLastPlaybackDate);
			}
		},
		actions: {

			toggleSection: function toggleSection(targetId) {
				console.log(targetId);
				var targetSelector = '#' + targetId;
				$(targetSelector).slideToggle('fast', function () {
					$('.' + targetId + '-btn').text($(targetSelector).is(':visible') ? '-' : '+');
				});
			}
		}
	});
});