define('storeplay-admin/controllers/authenticated/preview-playlist/approve', ['exports', 'storeplay-admin/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		// services

		apiClient: Ember.inject.service(),
		audioPlayer: Ember.inject.service('audio-player'),
		utility: Ember.inject.service('utility'),

		// TODO: Replace audio-player with storeplay-audio-player
		// after refactoring is complete.
		// storeplayAudioPlayer: service('storeplay-audio-player'),

		// UI State

		isAnimatingAvailableTracks: false,
		isSwapping: false,
		songToSwapOut: null,

		currentlyPlayingSong: null,

		// Approving

		amendmentMessage: null,
		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		// Computed property which returns the number of remaining
		// songs that the user can switch in.
		numberOfRemainingSongs: function () {
			var availableSongs = this.get("model").availableSongs;
			var numberOfSongs = availableSongs.length;

			var numberOfUnusedSongs = 0;
			for (var i = 0; i < numberOfSongs; i++) {
				if (!availableSongs[i].swappedIn) {
					numberOfUnusedSongs++;
				}
			}

			return numberOfUnusedSongs;
		}.property("model.availableSongs.@each.swappedIn"),

		sumApprovedDuration: function sumApprovedDuration() {
			var playlistSongs = this.get("model").playlistSongs;
			var availableSongs = this.get("model").availableSongs;

			var approvedDurationSeconds = 0;

			for (var i = 0; i < playlistSongs.length; i++) {
				if (playlistSongs[i].get("isApproved")) {
					approvedDurationSeconds += playlistSongs[i].get("duration");
				}
			}

			for (var i = 0; i < availableSongs.length; i++) {
				if (availableSongs[i].get("isApproved")) {
					approvedDurationSeconds += availableSongs[i].get("duration");
				}
			}

			return approvedDurationSeconds;
		},
		getIndexOfCurrentlyPlayingSong: function getIndexOfCurrentlyPlayingSong(currentSong) {
			if (currentSong == null) {
				return;
			} // Nothing is playing, so do nothing.

			var _this = this;
			var playlistSongs = this.get("model").playlistSongs;
			var indexOfCurrentlyPlayingSong = playlistSongs.findIndex(function (song) {
				return song.get("id") == currentSong.get("id");
			});

			return indexOfCurrentlyPlayingSong;
		},


		// Actions called from the template.

		actions: {

			// Music Player Actions
			// ####################

			// TODO: Refactor previous and next to share code.

			previous: function previous(currentSong) {
				currentSong = currentSong || this.currentlyPlayingSong;
				if (currentSong == null) {
					return;
				} // We tried pressing next when no song was playing.

				// Need to check if the current song has been swapped, so we use the proper index.
				currentSong = currentSong.swappedIn ? currentSong.swappedSong : currentSong;

				var indexOfCurrentlyPlayingSong = this.getIndexOfCurrentlyPlayingSong(currentSong);
				if (indexOfCurrentlyPlayingSong == -1) {
					return;
				} // We were unable to find the song, something went wrong.

				var playlistSongs = this.get("model").playlistSongs;
				if (indexOfCurrentlyPlayingSong - 1 >= 0) {
					var prevSong = playlistSongs[indexOfCurrentlyPlayingSong - 1];
					if (prevSong.swappedOut) {
						prevSong = prevSong.swappedSong;
					}
					this.get('audioPlayer').play(prevSong);
				} else {
					// We pressed next when we were on the first song.
					this.get('audioPlayer').stop();
				}
			},

			// Pass in the current song, and it will play the next song.
			// If you don't pass in a value, it will fall back to the currentlyPlayingSong property.
			// This is done this way because it is possible to go to the "next" song, once it has stopped.
			// For example, in the "play all" function. Once the song has finished, we want to move to the next.
			next: function next(currentSong) {
				currentSong = currentSong || this.currentlyPlayingSong;
				if (currentSong == null) {
					return;
				} // We tried pressing next when no song was playing.

				// Need to check if the current song has been swapped, so we use the proper index.
				currentSong = currentSong.swappedIn ? currentSong.swappedSong : currentSong;

				var indexOfCurrentlyPlayingSong = this.getIndexOfCurrentlyPlayingSong(currentSong);
				if (indexOfCurrentlyPlayingSong == -1) {
					return;
				} // We were unable to find the song, something went wrong.

				var playlistSongs = this.get("model").playlistSongs;
				if (indexOfCurrentlyPlayingSong + 1 < playlistSongs.length) {
					var nextSong = playlistSongs[indexOfCurrentlyPlayingSong + 1];
					if (nextSong.swappedOut) {
						nextSong = nextSong.swappedSong;
					}
					this.get('audioPlayer').playSongs([nextSong]);
				} else {
					// We pressed next when we were on the last song.
					this.get('audioPlayer').stop();
				}
			},

			stop: function stop() {
				// If the user presses stop manually, then we shouldn't keep playing consecutive songs next time.
				this.get('audioPlayer').stop();
			},

			// Seeks to a certain point in the song. Can be used by clicking on the track bar for the song.
			// param: 'position' is a value between 0 and 1, representing how far through the song.
			seek: function seek(position) {},

			// param: 'song' is the song which you want to start playing.
			play: function play(song) {
				console.log("Attempting to play song " + song + "...");

				this.get('audioPlayer').songBuffered = this.songBuffered.bind(this);
				this.get('audioPlayer').songStarted = this.songStarted.bind(this);
				this.get('audioPlayer').songDidTick = this.songDidTick.bind(this);
				this.get('audioPlayer').songSeeked = this.songSeeked.bind(this);
				this.get('audioPlayer').songFinished = this.songFinished.bind(this);

				this.get('audioPlayer').playSongs([song]);
			},

			playAll: function playAll() {

				console.log("Attempting to play all songs...");

				this.get('audioPlayer').songBuffered = this.songBuffered.bind(this);
				this.get('audioPlayer').songStarted = this.songStarted.bind(this);
				this.get('audioPlayer').songDidTick = this.songDidTick.bind(this);
				this.get('audioPlayer').songSeeked = this.songSeeked.bind(this);
				this.get('audioPlayer').songFinished = this.songFinished.bind(this);

				var playlistSongs = this.get("model").playlistSongs;

				var songsToPlay = playlistSongs.map(function (element) {
					return element.swappedOut ? element.swappedSong : element;
				});

				this.get('audioPlayer').playSongs(songsToPlay);
			},

			// Approving Songs
			// ###############

			toggleApproved: function toggleApproved(song) {
				// We can only toggle the approved value if the song has not been switched in. 
				// By switching in a song, it also means you have approved it.
				if (!song.get('swappedIn')) {

					// Update View
					song.toggleProperty('isApproved');

					// Tell server:
					var apiMethod = song.get('isApproved') ? "approveSongInPreviewPlaylist" : "unapproveSongInPreviewPlaylist";
					var apiClient = this.get('apiClient');

					apiClient.rpc(apiMethod, {
						playlistID: this.get('model').playlistID,
						songID: song.get('id') + "." + song.get('extension')
					});
					// .then((response) => {
					// 	if(response) {
					// 		if (response.error != null) {
					// 			toastr.error("DEBUG - (" + response.error.code + ") Unable to update approved status for song: " + response.error.message);
					// 		}
					// 	}
					// 	else {
					// 		if(config.environment == "development") {
					// 			toastr.success("DEBUG - Updated approved status for song successfully.");
					// 		}
					// 	}
					// });
				}
			},

			approveAll: function approveAll() {
				var model = this.get('model');
				var playlistSongs = model.playlistSongs;
				var availableSongs = model.availableSongs;

				// Update view:

				for (var i = 0; i < playlistSongs.length; i++) {
					if (!playlistSongs[i].get('swappedOut')) {
						playlistSongs[i].set('isApproved', true);
					}
				}

				for (var i = 0; i < availableSongs.length; i++) {
					if (availableSongs[i].get('swappedIn')) {
						availableSongs[i].set('isApproved', true);
					}
				}

				// Tell server:
				var apiMethod = "approveAllSongsInPreviewPlaylist";
				var apiClient = this.get('apiClient');

				apiClient.rpc(apiMethod, {
					playlistID: this.get('model').playlistID
				});
				// .then((response) => {
				// 	if(response) {
				// 		if (response.error != null) {
				// 			toastr.error("DEBUG - (" + response.error.code + ") Unable to update approved status for all songs: " + response.error.message);
				// 		}
				// 	}
				// 	else {
				// 		toastr.success("DEBUG - Approved all songs successfully.");
				// 	}
				// });
			},

			// Switching Songs In and Out
			// ##########################

			// Called when choosing an initial song to switch.
			startSwitch: function startSwitch(song) {

				if (this.isAnimatingAvailableTracks) {
					return;
				}

				var alreadySwitching = this.get('isSwapping');

				// If we are already switching, just cancel.
				if (alreadySwitching) {
					this.hideAvailableTracks();
					this.set('isSwapping', false);
					this.set('songToSwapOut', null);
					return;
				}

				// Otherwise, set the song we are currently switching.
				this.set('isSwapping', true);
				this.set('songToSwapOut', song);

				this.showAvailableTracksUnderSongWithID(song.id);
			},


			// Called when choosing the replacement song to switch.
			finishSwitch: function finishSwitch(songToSwapIn) {

				if (this.isAnimatingAvailableTracks) {
					return;
				}

				var songToSwapOut = this.get('songToSwapOut');

				// Keep track of which song has been replaced, and what
				// song replaced it.
				songToSwapOut.set('swappedOut', true);
				songToSwapOut.set('swappedSong', songToSwapIn);
				songToSwapOut.set('isApproved', false);

				// Keep track that the replacement song has been used.
				songToSwapIn.set('swappedIn', true);
				songToSwapIn.set('swappedSong', songToSwapOut);
				songToSwapIn.set('isApproved', true);

				// Hide the switching UI.
				this.hideAvailableTracks(true);

				// Clean up.
				this.set('isSwapping', false);
				this.set('songToSwapOut', null);

				// Tell server:
				var apiMethod = "swapSongInPreviewPlaylist";
				var apiClient = this.get('apiClient');

				apiClient.rpc(apiMethod, {
					playlistID: this.get('model').playlistID,
					swapOutSongID: songToSwapOut.get("id") + "." + songToSwapOut.get("extension"),
					swapInSongID: songToSwapIn.get("id") + "." + songToSwapIn.get("extension")
				});
				// .then((response) => {
				// 	if(response) {
				// 		if (response.error != null) {
				// 			toastr.error("DEBUG - (" + response.error.code + ") Unable to swap song in." + response.error.message);
				// 		}
				// 	}
				// 	else {
				// 		toastr.success("DEBUG - Swapped in song successfully.");
				// 	}
				// });
			},
			revertSwitch: function revertSwitch(song) {
				var songToRevert = song;
				var originalSong = song.get('swappedSong');

				songToRevert.set('swappedIn', false);
				songToRevert.set('swappedSong', null);
				songToRevert.set('isApproved', false);

				originalSong.set('swappedOut', false);
				originalSong.set('swappedSong', null);

				// Tell server:
				var apiMethod = "unswapSongInPreviewPlaylist";
				var apiClient = this.get('apiClient');

				apiClient.rpc(apiMethod, {
					playlistID: this.get('model').playlistID,
					swappedInSongID: song.get("id") + "." + song.get("extension")
				});
				// .then((response) => {
				// 	if(response) {
				// 		if (response.error != null) {
				// 			toastr.error("DEBUG - (" + response.error.code + ") Unable to swap song out." + response.error.message);
				// 		}
				// 	}
				// 	else {
				// 		toastr.success("DEBUG - Swapped song out successfully.");
				// 	}
				// });
			},


			// Saving
			// ######

			approvePlaylist: function approvePlaylist() {
				var _this = this;

				// Generate some of the information we need for the success message.
				var intendedDurationMinutes = this.get('model').playlistDetails.intendedDuration;

				var tickedCount = Ember.$('.fa-check-square').length;
				var untickedCount = Ember.$('.fa-square-o').length;

				// We have a couple of checks we have to do before we approve the playlist.
				if (tickedCount == 0) {
					_this.get('applicationController').displayModal({
						title: 'No songs approved',
						message: 'You have not approved any songs in this playlist. Please tick the boxes next to the songs you\'d like in this playlist or tick the box at the top to approve all.',
						primaryButtonLabel: 'OK'
					});
				} else if (untickedCount > 0) {
					_this.get('applicationController').displayModal({
						title: 'Not all songs approved',
						message: 'You have not approved (or swapped) ' + untickedCount + ' songs in this playlist. These songs will not be included and your playlist will be shorter than ' + intendedDurationMinutes + ' minutes. Are you sure you want to continue?',
						primaryButtonLabel: 'Yes',
						primaryButtonFunction: function primaryButtonFunction() {
							_this.doApproval();
						},
						secondaryButtonLabel: 'No'
					});
				} else {
					_this.doApproval();
				}
			}
		},

		// UI Methods for inserting elements into the DOM.
		// TODO: See if it is possible to ember-fy this stuff.
		// ###################################################

		// UI Methods for showing dropdown with available songs for exchange.
		// ##################################################################

		showAvailableTracksUnderSongWithID: function showAvailableTracksUnderSongWithID(id) {
			// Get the current available-tracks, ember will update everything
			// as needed, as per the template.
			var availableTracks = Ember.$("#available-tracks-dropdown");
			var selectedRowID = "#song-" + id;

			// Move the elements to the correct location.
			Ember.$(availableTracks).insertAfter(selectedRowID);

			// Animate them in nicely.
			var _this = this;
			_this.isAnimatingAvailableTracks = true;
			Ember.$("#available-tracks-dropdown").slideDown(300, function () {
				_this.isAnimatingAvailableTracks = false;
			});
		},

		hideAvailableTracks: function hideAvailableTracks(withoutAnimation) {

			if (withoutAnimation) {
				Ember.$("#available-tracks-dropdown").css("display", "none");
				Ember.$("#available-tracks-dropdown").appendTo("#playlist-row");
			}

			var _this = this;
			_this.isAnimatingAvailableTracks = true;
			Ember.$("#available-tracks-dropdown").slideUp(300, function () {
				Ember.$("#available-tracks-dropdown").css("display", "none");
				Ember.$("#available-tracks-dropdown").appendTo("#playlist-row");
				_this.isAnimatingAvailableTracks = false;
			});
		},

		// UI Methods for track bar/playing music.
		// #######################################

		showTrackBarForSong: function showTrackBarForSong(song) {
			var _this = this;
			var selectedRowID = "#song-" + song.id;

			Ember.$(selectedRowID).after("<div id=\"trackbar\"><div id=\"trackinglayer\"></div><div id=\"bufferedlayer\"></div><p>0:00 / 0:00</p></div>");
			this.updateTrackbar(song.duration, 0);

			Ember.$("#trackbar").click(function (e) {
				// Find the location of the trackbar on the screen.
				var xPositionOfTrackbar = Ember.$(this).offset().left;
				var yPositionOfTrackbar = Ember.$(this).offset().top;

				// Find the location of the click relative to the trackbar.
				var xClickLocationInTrackbar = e.pageX - xPositionOfTrackbar;
				var yClickLocationInTrackbar = e.pageY - yPositionOfTrackbar;

				// Find how far through the trackbar we clicked, in the range of 0,1
				var widthOfTrackbar = Ember.$(this).width();
				var normalisedSeekPosition = xClickLocationInTrackbar / widthOfTrackbar;

				// Seek to that position.
				var seekPosition = Math.floor(song.duration * normalisedSeekPosition);
				_this.get('audioPlayer').seek(seekPosition);
			});

			Ember.$("#trackbar").slideDown(300, function () {});
		},

		hideTrackBarForSong: function hideTrackBarForSong(song) {
			Ember.$("#trackbar").remove();
		},

		updateTrackbar: function updateTrackbar(totalDuration, currentPosition) {
			var fractionThroughSong = currentPosition / totalDuration;
			var widthPercentage = fractionThroughSong * 100;
			Ember.$("#trackinglayer").css("width", Math.min(widthPercentage, 100) + "%");

			// Will give us a nicely formatted string like "0:37 / 3:21"
			// Use Math.floor to ensure the time string is only updated when the second changes. We don't care about sub-second accuracy.
			var trackbarText = this.get("utility").audio.generateCurrentPlaytime(totalDuration, Math.floor(currentPosition));
			Ember.$("#trackbar p").text(trackbarText);
		},
		updateBufferedBar: function updateBufferedBar(totalDuration, bufferedRange) {
			var fractionOfEndThroughSong = bufferedRange.end / totalDuration;
			var widthPercentage = fractionOfEndThroughSong * 100;
			Ember.$("#bufferedlayer").css("width", Math.min(widthPercentage, 100) + "%");
		},


		// Music Player Events
		// ###################

		songBuffered: function songBuffered(song, bufferedRange) {
			this.updateBufferedBar(song.duration, bufferedRange);
		},

		songStarted: function songStarted(song) {
			this.showTrackBarForSong(song);
			this.set('currentlyPlayingSong', song);
		},

		songDidTick: function songDidTick(song, position) {
			this.updateTrackbar(song.duration, position);
		},

		songSeeked: function songSeeked(song, position) {
			this.updateTrackbar(song.duration, position);
		},

		songFinished: function songFinished(song) {
			this.hideTrackBarForSong(song);
			this.set('currentlyPlayingSong', null);
		},

		// Approving the playlist
		// ######################

		doApproval: function doApproval() {
			var _this2 = this;

			var _this = this;

			var apiMethod = "approvePreviewPlaylist";
			var apiClient = this.get('apiClient');

			// Generate some of the information we need for the success message.
			var intendedDurationMinutes = this.get('model').playlistDetails.intendedDuration;
			var availableFrom = this.get('model').playlistDetails.availableFrom;
			var availableFromDateString = availableFrom.isBefore() ? "immediately" : "from " + availableFrom.format("DD MMM YYYY");
			var shortPlaylistNotificationString = this.sumApprovedDuration() / 60 < intendedDurationMinutes - 10 ? "Note: Your approved playlist is a bit shorter than intended. StorePlay will contact you soon to discuss adding some extra tracks to this playlist." : "";

			_this.get('applicationController').displayModal({
				title: 'Saving...',
				message: 'We are saving the playlist now, this may take a few moments...',
				primaryButtonFunction: function primaryButtonFunction() {
					_this.transitionToRoute('authenticated.preview-playlist');
				}
			});

			apiClient.rpc(apiMethod, {
				playlistID: this.get('model').playlistID,
				amendmentMessage: this.get('amendmentMessage')
			}).then(function (response) {
				_this.get('applicationController').displayModal({
					title: 'Success!',
					message: '\n\t\t\t\tThanks, you\'ve just approved the playlist. It will be available for download to your devices ' + availableFromDateString + '.\n\t\t\t\t' + shortPlaylistNotificationString + '\n\t\t\t\tIf you have any questions, feel free to call us on +61 3 9016 0235.\n\t\t\t\t',
					primaryButtonLabel: 'OK',
					primaryButtonFunction: function primaryButtonFunction() {
						_this.transitionToRoute('authenticated.preview-playlist');
					}
				});
			}).catch(function (errorObject) {
				console.log('errorObject : %o', errorObject);
				_this2.get('applicationController').displayModal({
					title: 'Sorry, we could not save the playlist.',
					message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
				});
			});
		}
	});
});