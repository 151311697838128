define('storeplay-admin/components/forms/chain-channels-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service('currentUser'),
		apiClient: Ember.inject.service('apiClient'),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		addChannelID: 0,
		addChannelMode: false,
		editChannelID: 0,
		editChannelMode: false,
		addChannelAvailableFrom: moment().format('DD/MM/YYYY'),
		addChannelAvailableTo: null,

		init: function init(args) {
			this._super(args);

			// The component has been created in memory
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component was added to the DOM
			this.initialiseUI();
		},

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
		},

		didRender: function didRender() {
			this._super.apply(this, arguments);
			this.initialiseUI();
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		// Updating the UI
		// ###############
		initialiseUI: function initialiseUI() {

			$('.fromDateTimePicker').datetimepicker({ format: 'DD/MM/YYYY h:mm A' });
			$('.toDateTimePicker').datetimepicker({ format: 'DD/MM/YYYY h:mm A' });
		},

		actions: {

			// Remove channel
			removeChannelClicked: function removeChannelClicked(channel) {
				var _this = this;

				console.log(channel);
				console.log('remove channel clicked');
				var currentUser = this.get('currentUser');
				var apiClient = this.get('apiClient');
				this.get('applicationController').displayModal({
					title: 'Are you sure you want to remove this channel?',
					primaryButtonLabel: 'Yes, I\'m sure',
					primaryButtonFunction: function primaryButtonFunction() {
						apiClient.rpc('deleteChannelForChainClient', { clientID: channel.clientID, playlistID: channel.playlistID }, currentUser.adminToken).then(function (response) {
							_this.get('refreshRoute')();
						}).catch(function (errorObject) {
							console.log('errorObject : %o', errorObject);
							_this.get('applicationController').displayModal({
								title: 'Sorry, we could not remove this channel',
								message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
							});
						});
					},
					secondaryButtonLabel: 'Cancel'
				});
			},
			updateChannelClicked: function updateChannelClicked(channel) {
				var _this2 = this;

				var currentUser = this.get('currentUser');
				console.log('update channel clicked');

				var localFrom = moment.tz(channel.availableFrom, 'DD/MM/YYYY h:mm A', "Australia/Melbourne");
				var localTo = null;
				if (channel.availableTo != "") {
					localTo = moment.tz(channel.availableTo, 'DD/MM/YYYY h:mm A', "Australia/Melbourne");
					// Validate dates input
					var valid = this.validateChannelDates(localFrom, localTo);
					if (!valid) {
						return;
					}
				}

				this.get('apiClient').rpc('updateChannelForChainClient', {
					chainClientChannel: {
						clientID: channel.clientID,
						playlistID: channel.playlistID,
						availableFrom: channel.availableFrom != "" ? localFrom.toISOString(true) : null,
						availableTo: channel.availableTo != "" ? localTo.toISOString(true) : null
					}
				}, currentUser.adminToken).then(function () {
					_this2.get('refreshRoute')();

					_this2.get('applicationController').displayModal({
						title: 'Success!',
						message: "Channel updated successfully."
					});
				}).catch(function (errorObject) {
					console.log('errorObject : %o', errorObject);
					_this2.get('applicationController').displayModal({
						title: 'Sorry, updating this chain client channel failed.',
						message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
					});
				});
			},


			// Availabiity clicked
			showChannelAvailablityClicked: function showChannelAvailablityClicked(channelID) {
				var editChannelID = this.get('editChannelID');
				var editChannelMode = this.get('editChannelMode');
				if (editChannelID == channelID && editChannelMode) {
					this.set('editChannelMode', false);
				} else {
					this.set('editChannelMode', true);
				}
				this.set('editChannelID', channelID);
			},


			// Add channel
			addChannelClicked: function addChannelClicked() {
				console.log('add channel clicked');
				this.set('addChannelMode', true);
			},
			addChannelSelected: function addChannelSelected(channelID) {
				console.log('show the ');
				this.set('addChannelID', channelID);
			},
			saveAddChannelClicked: function saveAddChannelClicked() {
				var _this3 = this;

				var currentUser = this.get('currentUser');
				// Validate
				if (this.addChannelID == 0) {
					this.get('applicationController').displayModal({ title: 'Please choose the channel to add to the chain client.' });
					return;
				}

				if (this.model.currentChannels.filter(function (c) {
					return c.channel.id == _this3.addChannelID;
				}).length > 0) {
					this.get('applicationController').displayModal({ title: 'The client is already subscribed to this channel' });
					return;
				}

				console.log('saveAddChannelClicked, about to RPC with client ID:' + this.model.clientID + ', newChannelID:' + this.addChannelID);

				var localFrom = moment.tz(this.addChannelAvailableFrom, 'DD/MM/YYYY h:mm A', "Australia/Melbourne");

				var localTo = null;
				if (this.addChannelAvailableTo != "") {
					localTo = moment.tz(this.addChannelAvailableTo, 'DD/MM/YYYY h:mm A', "Australia/Melbourne");
					// Validate dates input
					var valid = this.validateChannelDates(localFrom, localTo);
					if (!valid) {
						return;
					}
				}

				this.get('apiClient').rpc('addChannelForChainClient', {
					chainClientChannel: {
						clientID: this.model.clientID,
						channelID: this.addChannelID,
						availableFrom: this.addChannelAvailableFrom != "" ? localFrom.toISOString(true) : moment().tz('Australia/Melbourne').toISOString(true), // toISOString(true) makes the time local time not utc.
						availableTo: this.addChannelAvailableTo != "" ? localTo.toISOString(true) : null
					}
				}, currentUser.adminToken).then(function () {
					_this3.set('addChannelMode', false);
					_this3.set('addChannelID', 0);
					_this3.get('refreshRoute')();
				}).catch(function (errorObject) {
					console.log('errorObject : %o', errorObject);
					_this3.get('applicationController').displayModal({
						title: 'Sorry, we could not add the new channel',
						message: errorObject.payload.error && errorObject.payload.error.message ? errorObject.payload.error.message : "An unexpected error occurred."
					});
				});
			},
			cancelAddChannelClicked: function cancelAddChannelClicked() {
				this.set('addChannelMode', false);
				this.set('addChannelID', 0);
			}
		},
		// Validations
		// ###########

		validateChannelDates: function validateChannelDates(fromDate, toDate) {

			if (toDate <= fromDate) {
				this.displayValidationError("\'Available To\' must be a later date than \'Available From\'.");
				return false;
			}
			return true;
		},

		displayValidationError: function displayValidationError(message) {
			if (message != null) {
				this.get('applicationController').displayModal({
					title: "Error Validating Input",
					message: message,
					primaryButtonLabel: 'OK'
				});
			}
		}
	});
});