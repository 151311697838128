define('storeplay-admin/models/schedule', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Schedule = Ember.Object.extend({
		id: null,
		name: null,
		clientId: null,
		settings: null,
		startDateTime: null,
		endDateTime: null,
		scheduleType: 1,
		timeblocks: [],
		isEnabled: false,
		stores: [],
		storeGroups: [],
		isActive: false,
		toJSON: function toJSON() {
			var json = {};
			json["id"] = this.get("id");
			json["clientID"] = this.get("clientId");
			json["name"] = this.get("name");
			json["scheduleType"] = this.get("scheduleType");
			json["startDateTime"] = this.get("startDateTime");
			json["endDateTime"] = this.get("endDateTime");
			json["isEnabled"] = this.get("isEnabled");
			json["stores"] = this.get("stores");
			json["storeGroups"] = this.get("storeGroups");
			return json;
		}
	});

	Schedule.reopenClass({
		// Class properties/methods
		fromJSON: function fromJSON(jsonObj) {
			var schedule = this.create({
				id: jsonObj["id"] || 0,
				name: jsonObj["name"] || null,
				clientId: jsonObj["clientID"] || null,
				scheduleType: jsonObj["scheduleType"] || 1,
				startDateTime: jsonObj["startDateTime"] != null ? moment(jsonObj["startDateTime"]["iso8601"]).format('DD/MM/YYYY') : null,
				endDateTime: jsonObj["endDateTime"] != null ? moment(jsonObj["endDateTime"]["iso8601"]).format('DD/MM/YYYY') : null,
				isEnabled: jsonObj["isEnabled"],
				stores: jsonObj["stores"] || null,
				storeGroups: jsonObj["storeGroups"] || null,
				isActive: jsonObj["isActive"]
			});
			return schedule;
		}
	});

	exports.default = Schedule;
});