define('storeplay-admin/components/forms/scheduling/timeblock-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		currentUser: Ember.inject.service("current-user"),
		apiClient: Ember.inject.service("api-client"),

		applicationController: Ember.computed('', function () {
			return Ember.getOwner(this).lookup('controller:application');
		}),

		timeblock: null,
		scheduleSettings: null,
		formTitle: null,

		init: function init(args) {
			this._super(args);

			// The component has been created in memory
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			// Component was added to the DOM
			// Work on the zindex fix.
			console.log(this.timeblock);
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			// Component is about to be removed from the DOM
		},

		actions: {

			// Buttons

			delete: function _delete() {
				console.log('delete');
			},

			save: function save() {
				console.log('save');
				var _this = this;
			}
		}

	});
});