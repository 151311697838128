define('storeplay-admin/initializers/register-models', ['exports', 'storeplay-admin/models/store-license-link'], function (exports, _storeLicenseLink) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.initialize = initialize;
	function initialize(application) {
		application.register('object:store-license-link', _storeLicenseLink.default, { singleton: false });
	}

	exports.default = {
		name: 'register-models',
		initialize: initialize
	};
});