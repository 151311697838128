define('storeplay-admin/routes/authenticated/index', ['exports', 'storeplay-admin/routes/authenticated'], function (exports, _authenticated) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _authenticated.default.extend({
        afterModel: function afterModel() {
            // If we're here after the model, we must be successfully authed; so we can now redirect to the dashboard
            this.transitionTo('authenticated.dashboard');
        }
    });
});