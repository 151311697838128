define('storeplay-admin/validations/offer', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var offerValidation = Ember.Object.extend({
        title: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 5 }), (0, _validators.validateLength)({ max: 100 })],
        description: [(0, _validators.validateLength)({ min: 5 }), (0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ allowBlank: false })],
        termsConditionsURL: [(0, _validators.validateFormat)({ allowBlank: true }), (0, _validators.validateLength)({ max: 500 })],
        redemptionCode: [(0, _validators.validateFormat)({ allowBlank: true }), (0, _validators.validateLength)({ max: 50 })],
        redemptionInstructions: [(0, _validators.validateFormat)({ allowBlank: true })]
    });

    exports.default = offerValidation;
});