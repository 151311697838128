define('storeplay-admin/routes/authenticated/channels/index', ['exports', 'storeplay-admin/models/subscription', 'storeplay-admin/models/subscription-plan', 'storeplay-admin/models/channel'], function (exports, _subscription, _subscriptionPlan, _channel) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		currentUser: Ember.inject.service('currentUser'),
		apiClient: Ember.inject.service('apiClient'),

		beforeModel: function beforeModel() {
			// Have to make sure the current user is loaded before we start
			return this.get('currentUser').load();
		},
		model: function model() {
			var apiClient = this.get('apiClient');
			var user = this.get('currentUser').get('clientUser');
			return Promise.all([apiClient.rpc('getSubscriptionForClient', { clientID: user.clientID, includeRelationships: ['subscriptionPlan'] }), apiClient.rpc('getChannelsForClient', { clientID: user.clientID, includeRelationships: null }), apiClient.rpc('getAvailableChannelsForRegion', { subscriptionRegionID: this.get('currentUser.clientUser.subscriptionRegionID') })]).then(function (jsons) {
				var subscriptionJson = jsons[0];
				var currentChannelsJson = jsons[1];
				var availableChannelsJson = jsons[2];
				return {
					subscription: _subscription.default.fromJSON(subscriptionJson),
					subscriptionPlan: subscriptionJson.subscriptionPlan ? _subscriptionPlan.default.fromJSON(subscriptionJson.subscriptionPlan) : null,
					currentChannels: currentChannelsJson.map(function (j) {
						return _channel.default.fromJSON(j);
					}),
					availableChannels: availableChannelsJson.map(function (j) {
						return _channel.default.fromJSON(j);
					})
				};
			});
		},


		actions: {
			refreshRoute: function refreshRoute() {
				console.log('refreshCurrentRoute action');
				this.refresh();
			}
		},

		setupController: function setupController(controller, model) {
			this._super(controller, model);
			var _this = this;
			controller.set('refreshRoute', function () {
				console.log('refreshRoute in setupController...');
				_this.refresh();
			});
		}
	});
});