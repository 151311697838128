define('storeplay-admin/models/playlist', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Playlist = Ember.Object.extend({
		id: null,
		name: null,
		channelID: null,
		categorisedName: null,
		clientID: null,
		hasBeenProcessed: null,
		intendedDuration: null,
		availableFrom: null,
		availableTo: null,
		isApproved: null,
		isChannel: null,
		spotifyPlaylistUrl: null,
		isRecentSongsEnabled: false,
		recentSongCount: null,
		recentSongFrequency: null,
		toJSON: function toJSON() {
			var json = {};
			json["id"] = this.get("id");
			json["name"] = this.get("name");
			json["availableFrom"] = this.get("availableFrom");
			json["availableTo"] = this.get("availableTo");
			json["spotifyPlaylistUrl"] = this.get("spotifyPlaylistUrl");
			json["isRecentSongsEnabled"] = this.get("isRecentSongsEnabled");
			json["recentSongCount"] = this.get("recentSongCount");
			json["recentSongFrequency"] = this.get("recentSongFrequency");
			return json;
		}
	});

	Playlist.reopenClass({
		fromJSON: function fromJSON(playlistObject) {
			var playlist = this.create({
				id: playlistObject["id"] || 0,
				name: playlistObject["name"] || null,
				availableFrom: playlistObject["availableFrom"] ? (0, _moment.default)(playlistObject["availableFrom"]["iso8601"]) : null,
				availableTo: playlistObject["availableTo"] ? (0, _moment.default)(playlistObject["availableTo"]["iso8601"]) : null,
				channelID: playlistObject["channelID"] || null,
				categorisedName: playlistObject["categorisedName"] || null,
				clientID: playlistObject["clientID"] || null,
				hasBeenProcessed: playlistObject["hasBeenProcessed"] || null,
				isApproved: playlistObject["isApproved"] || null,
				isChannel: playlistObject["isChannel"] || null,
				intendedDuration: playlistObject["intendedDuration"] || null,
				spotifyPlaylistUrl: playlistObject["spotifyPlaylistUrl"] || null,
				isRecentSongsEnabled: playlistObject["isRecentSongsEnabled"] || false,
				recentSongCount: playlistObject["recentSongCount"] || null,
				recentSongFrequency: playlistObject["recentSongFrequency"] || null

			});
			return playlist;
		}
	});

	exports.default = Playlist;
});