define('storeplay-admin/models/chain-channel', ['exports', 'moment', 'storeplay-admin/models/playlist', 'storeplay-admin/models/channel'], function (exports, _moment, _playlist, _channel) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ChainChannel = Ember.Object.extend({
		clientID: 0,
		playlistID: 0,
		playlist: null,
		availableFrom: null,
		availableTo: null,
		channel: null,
		channelID: 0,
		dateFormat: 'DD MMM YYYY HH:mm',
		channelAvailabilityDescription: Ember.computed('availableFrom', 'availableTo', 'dateFormat', function () {
			var availableFrom = this.get('availableFrom');
			var availableTo = this.get('availableTo');
			var format = this.get('dateFormat');
			var from = availableFrom ? (0, _moment.default)(availableFrom, 'DD/MM/YYYY h:mm A') : (0, _moment.default)().tz('Australia/Melbourne');
			var to = availableTo ? (0, _moment.default)(availableTo, 'DD/MM/YYYY h:mm A') : null;
			var desc = 'Available for this client from ' + from.format(format);
			desc += to ? ' to ' + to.format(format) + '.' : '.';
			return desc;
		}),
		playlistAvailabilityDescription: Ember.computed('availableFrom', 'availableTo', 'playlist', 'dateFormat', function () {
			var availableFrom = this.get('availableFrom');
			var availableTo = this.get('availableTo');
			var format = this.get('dateFormat');
			var from = availableFrom ? (0, _moment.default)(availableFrom, 'DD/MM/YYYY h:mm A') : null;
			var to = availableTo ? (0, _moment.default)(availableTo, 'DD/MM/YYYY h:mm A') : null;
			var playlistFrom = this.get('playlist.availableFrom');
			var playlistTo = this.get('playlist.availableTo');
			var desc = 'Note that this channel is available to any clients from ';
			desc += playlistFrom ? '<strong>' + playlistFrom.format(format) + '</strong>' : '<strong>Now</strong>';
			desc += playlistTo ? ' to <strong>' + playlistTo.format(format) + '</strong>' : '';
			desc += ', client specific availability has been set from <strong>' + from.format(format) + '</strong>';
			desc += to != null ? ' to <strong>' + to.format(format) + '.</strong>' : '.';
			return desc;
		}),
		playlistAvailabilityShortDescription: Ember.computed('playlist', 'dateFormat', function () {
			var format = this.get('dateFormat');
			var playlistFrom = this.get('playlist.availableFrom');
			var playlistTo = this.get('playlist.availableTo');
			var desc = 'Playlist available from ';
			desc += playlistFrom ? '<strong>' + playlistFrom.format(format) + '</strong>' : '<strong>Now</strong>';
			desc += playlistTo ? ' to <strong>' + playlistTo.format(format) + '</strong>' : '';
			return desc;
		})
	});

	ChainChannel.reopenClass({
		fromJSON: function fromJSON(chainChannelObject) {
			var chainChannel = this.create({
				clientID: chainChannelObject["clientID"],
				playlistID: chainChannelObject["playlistID"],
				playlist: chainChannelObject["playlist"] != null ? _playlist.default.fromJSON(chainChannelObject["playlist"]) : null,
				availableFrom: chainChannelObject["availableFrom"] != null ? (0, _moment.default)(chainChannelObject["availableFrom"]["iso8601"]).tz('Australia/Melbourne').format('DD/MM/YYYY h:mm A') : null,
				availableTo: chainChannelObject["availableTo"] != null ? (0, _moment.default)(chainChannelObject["availableTo"]["iso8601"]).tz('Australia/Melbourne').format('DD/MM/YYYY h:mm A') : null,
				channel: chainChannelObject["channel"] != null ? _channel.default.fromJSON(chainChannelObject["channel"]) : null,
				channelID: chainChannelObject["channelID"]
			});
			return chainChannel;
		}
	});

	exports.default = ChainChannel;
});