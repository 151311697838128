define('storeplay-admin/components/dashboard/welcome-panel', ['exports', 'storeplay-admin/models/client', 'storeplay-admin/config/environment'], function (exports, _client, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		apiClient: Ember.inject.service('api-client'),
		currentUser: Ember.inject.service('current-user'),
		utility: Ember.inject.service("utility"),

		numberOfColumns: 6,
		tagName: "", // This ensures the component is not wrapped in an ember-view div, which is not needed for this component.

		isFetching: true,
		fetchingError: null,

		init: function init(args) {
			// The component has been created in memory
			this._super(args);

			this.set("clientChannels", []);
			this.set("clientSubscriptionPlan", {});
		},

		didInsertElement: function didInsertElement() {
			// Component was added to the DOM
			this._super.apply(this, arguments);

			this.generateURLForClientLogo();

			// We need to fetch information about the client.
			// e.g., their plan, their current channels, etc.
			this.fetchClientInformation();
		},

		willDestroyElement: function willDestroyElement() {
			// Component is about to be removed from the DOM
			this._super.apply(this, arguments);
		},

		actions: {},

		generateURLForClientLogo: function generateURLForClientLogo() {
			// Generate the URL for the user's logo.
			var clientID = this.get("currentUser").clientID;
			var random = Math.random(); // We need a random value on the URL to get around caching.
			var logoLightSrc = _environment.default.apiBaseURL + '/clients/' + clientID + '/logo-light?width=200&height=200&' + random;
			this.set("logoLightSrc", logoLightSrc);
		},

		fetchClientInformation: function fetchClientInformation() {

			var _this = this;
			var apiClient = this.get("apiClient");

			// TODO: For some reason, the 'client' object does not get updated properly
			// when switching the client that you are impersonating as an administrator.
			// Therefore the client.id property will be the ID of the previous client.
			// However, clientID on the currentUser service seems to be updated accordingly and will
			// be the correct ID.
			// let client = this.get("currentUser.client");
			var clientID = this.get("currentUser.clientID");

			apiClient.rpc("getClient", { clientID: clientID, includeRelationships: ["brandCategory"] }).then(function (result) {

				var client = _client.default.fromJSON(result);
				_this.set("client", client);

				if (client.type == 2) {
					// If client is independent
					_this.fetchIndependentClientInformation(client);
				} else {
					// If client is chain
					_this.fetchChainClientInformation(client);
				}
			}).catch(function (error) {
				// Something happened when trying to fetch the client.
				_this.set("isFetching", false);
				_this.set("fetchingError", "Unable to fetch client details");
				console.log(error.message);
			});
		},

		fetchChainClientInformation: function fetchChainClientInformation(client) {

			var _this = this;
			var apiClient = this.get("apiClient");

			var getPlaylistsForChainClientCall = apiClient.rpc("getPlaylistsForChainClient", { clientID: client.id });
			var getBrandCategoriesCall = apiClient.rpc("getAllBrandCategories");

			Promise.all([getPlaylistsForChainClientCall, getBrandCategoriesCall]).then(function (results) {

				// If the user is a chain client, we need to get the playlists.
				// We limit to the 4 latest playlists for the welcome panel.
				var playlists = results[0];
				playlists.sort(function (left, right) {
					return left.availableFrom.unixTime > right.availableFrom.unixTime ? -1 : 1;
				});
				playlists.splice(4);

				_this.set("chainPlaylists", playlists);

				// We also need the brand category for the chain client as well.
				var brandCategories = results[1];

				// We need to find the name of the brand category for the current user as this isn't populated
				// on the client object currently.
				var brandCategory = brandCategories.find(function (category) {
					return category.id === client.brandCategoryID;
				});

				if (brandCategory == null) {
					brandCategory = { id: -1, name: "No Brand Category" };
				}

				_this.set("brandCategory", brandCategory);

				// We are done loading, we can show all the information now.
				_this.set("isFetching", false);
			}).catch(function (error) {
				// Something happened when trying to fetch the information.
				_this.set("isFetching", false);
				_this.set("fetchingError", "Unable to fetch client details");
				console.log(error.message);
			});
		},

		fetchIndependentClientInformation: function fetchIndependentClientInformation(client) {

			var _this = this;
			var apiClient = this.get("apiClient");

			var getChannelsForClientCall = apiClient.rpc("getChannelsForClient", { clientID: client.id, includeRelationships: null });
			var getSubscriptionPlanForClientCall = apiClient.rpc("getSubscriptionForClient", { clientID: client.id, includeRelationships: ["subscriptionPlan"] });
			var getBrandCategoriesCall = apiClient.rpc("getAllBrandCategories");

			Promise.all([getChannelsForClientCall, getSubscriptionPlanForClientCall, getBrandCategoriesCall]).then(function (results) {
				var clientChannels = results[0];
				var clientSubscriptionPlan = results[1];
				var brandCategories = results[2];

				// We want to only show a maximum of 3 channels in the welcome panel.
				// After that we will show a link to see the rest.
				// Most clients only have 1-3 channels. Those with more can see and manage
				// them in the channels route.
				var remainingChannels = [];
				if (clientChannels.length > 3) {
					remainingChannels = clientChannels.splice(3, clientChannels.length);
				}

				// We need to find the name of the brand category for the current user as this isn't populated
				// on the client object currently.
				var brandCategory = brandCategories.find(function (category) {
					return category.id === client.brandCategoryID;
				});

				if (brandCategory == null) {
					brandCategory = { id: -1, name: "No Brand Category" };
				}

				_this.set("clientChannels", clientChannels);
				_this.set("remainingChannels", remainingChannels);
				_this.set("clientSubscriptionPlan", clientSubscriptionPlan);
				_this.set("brandCategory", brandCategory);

				// We are done loading, we can show all the information now.
				_this.set("isFetching", false);
			}).catch(function (error) {
				// Something happened when trying to fetch the information.
				_this.set("isFetching", false);
				_this.set("fetchingError", "Unable to fetch client details");
				console.log(error.message);
			});
		}

	});
});